import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { IconButtonComponent } from '../../buttons/icon-button/icon-button.component';
import { CustomModalController } from '../controllers/custom-modal.controller';

@Component({
  selector: 'mea-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  standalone: true,
  imports: [IonicModule, IconButtonComponent, NgClass],
})
export class ModalHeaderComponent {
  @Input() title?: string;
  @Input() color = 'default';
  @Input() noBorder = false;
  @Input() size: 'large' | 'small' | undefined;
  @Input() showBackButton = false;

  @Output() backButtonClicked = new EventEmitter<void>();

  constructor(private modalController: CustomModalController) {}

  async close() {
    await this.modalController.dismiss();
  }
}
