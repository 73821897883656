import { Injectable } from '@angular/core';
import { ChatTextblocksComponent } from '../../../../../libs/pharmacy-only/src/modules/textblocks/chat-textblocks/chat-textblocks/chat-textblocks.component';
import { TextblockServiceInterface } from '../../../../../libs/essentials/types/src/service-interfaces/textblock.service.interface';
import { Textblock } from '../../../../../libs/essentials/types/src/textblock';
import { CustomModalController } from '../../../../../libs/common/ui-components/src/ionic/controllers/custom-modal.controller';

@Injectable()
export class TextblockService implements TextblockServiceInterface {
  constructor(private modalController: CustomModalController) {}

  public async selectTextblock(): Promise<Textblock> {
    const modal = await this.modalController.create({
      component: ChatTextblocksComponent,
      cssClass: 'textblock-modal',
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    return data;
  }
}
