import { browserTracingIntegration, init } from '@sentry/angular-ivy';
import { CognitoErrorCode } from '../../../essentials/types/authError';

const ignoreErrors = [
  'Connection closed',
  'Timeout disconnect',
  'Request aborted',
  'Request failed with status code 400',
  'Request failed with status code 401',
  'Connection failed',
  'Network Error',
  'Network error',
  'NetworkError',
  'SecurityError: The operation is insecure.',
  CognitoErrorCode.InvalidParameterException,
  CognitoErrorCode.NotAuthorizedException,
  CognitoErrorCode.UserNotFoundException,
];

const disabledIntegrations = ['TryCatch', 'GlobalHandlers'];

export const initSentry = ({ dsn, release, environment }: { dsn?: string; release?: string; environment?: string }) =>
  init({
    dsn,
    release,
    environment,
    integrations: (integrations) =>
      integrations.filter((i) => !disabledIntegrations.includes(i.name)).concat(browserTracingIntegration()),
    ignoreErrors,
    tracesSampleRate: 0.2,
    maxValueLength: 2000,
  });
