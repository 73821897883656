import { Component } from '@angular/core';
import { Amplify } from '@aws-amplify/core';
import { IonicModule, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { register } from 'swiper/element/bundle';
import { StatePersistenceService } from '../../../../libs/common/resources/src/common-store/persistence/state-persistence.service';
import { CustomAmplifyService } from '../../../../libs/common/resources/src/services/amplify.service';
import { ImageLowMemoryErrorService } from '../../../../libs/common/resources/src/services/image-low-memory-error.service';
import { NetworkStateService } from '../../../../libs/common/resources/src/services/network-state.service';
import { ServiceWorkerService } from '../../../../libs/common/resources/src/services/service-worker.service';
import { TabTitleService } from '../../../../libs/common/resources/src/services/tab-title.service';
import { VisibilityService } from '../../../../libs/common/resources/src/services/visibility.service';
import { concatRoutes } from '../../../../libs/essentials/util/src/concat-routes.util';
import { initUser } from '../../../../libs/store/src/common-store/user-store/actions/user.actions';
import { environment } from '../environments/environment';
import { PharmacyRoute } from './pharmacy-routes';
import { resetHomeState } from './store/actions/app.actions';
import { MeaState } from './store/state/mea.state';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonicModule],
})
export class AppComponent {
  // noinspection JSUnusedLocalSymbols
  constructor(
    platform: Platform,
    translate: TranslateService,
    private amplifyService: CustomAmplifyService,
    private imageLowMemoryErrorService: ImageLowMemoryErrorService,
    private serviceWorkerService: ServiceWorkerService,
    private statePersistenceService: StatePersistenceService,
    private store: Store<MeaState>,
    // these services need to be included somewhere to be constructed
    private tabTitleService: TabTitleService,
    private visibilityService: VisibilityService,
    private networkStateService: NetworkStateService
  ) {
    this.statePersistenceService.keepConversationsAndMessagesPersistent();

    this.imageLowMemoryErrorService.displayToastIfBrowserWasReloadedAfterUserOpenedImagePicker(
      concatRoutes([PharmacyRoute.Home, PharmacyRoute.Chat])
    );

    Amplify.configure({ ...environment.aws_config, Analytics: { disabled: true } });

    platform.ready().then(() => {
      this.serviceWorkerService.registerServiceWorkerAndAddEventListener(environment);

      const conversationId = this._getParam('conv');
      if (conversationId) {
        localStorage.setItem('convDeepLink', conversationId);
      }
    });

    this.store.dispatch(initUser());

    translate.setDefaultLang('de');
    this.amplifyService.logoutEvent$.subscribe(() => {
      this.store.dispatch(resetHomeState());
    });

    register();
  }

  _getParam(name: string) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regexS = '[\\?&]' + name + '=([^&#]*)';
    const regex = new RegExp(regexS);
    const results = regex.exec(window.location.href);
    if (results == null) {
      return '';
    } else {
      return results[1];
    }
  }
}
