import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCognitoId } from '../../../../../../../../libs/store/src/common-store/user-store/selectors/user.selectors';
import { MeaState } from '../../../../store/state/mea.state';
import { SoundRepetition } from '../../state/home.state';

@Injectable({
  providedIn: 'root',
})
export class SoundNotificationService {
  private readonly soundNotificationPrefix = 'sound.notification.';
  private readonly soundRepetitionPrefix = 'sound.repetition.';
  private readonly defaultSoundNotification = true;
  private readonly defaultSoundRepetition = 60;

  private cognitoId: string | null | undefined;

  constructor(private store: Store<MeaState>) {
    this.store.select(selectCognitoId).subscribe((cognitoId) => (this.cognitoId = cognitoId));
  }

  setSoundNotificationInLocalStorage(soundNotification: boolean) {
    localStorage.setItem(this.soundNotificationPrefix + this.cognitoId, soundNotification.toString());
  }

  getSoundNotificationFromLocalStorage() {
    const soundNotification = localStorage.getItem(this.soundNotificationPrefix + this.cognitoId);
    return soundNotification ? soundNotification === 'true' : this.defaultSoundNotification;
  }

  setSoundRepetitionInLocalStorage(soundRepetition: number) {
    localStorage.setItem(this.soundRepetitionPrefix + this.cognitoId, soundRepetition.toString());
  }

  getSoundRepetitionFromLocalStorage() {
    const soundRepetition = localStorage.getItem(this.soundRepetitionPrefix + this.cognitoId);
    return soundRepetition ? (Number(soundRepetition) as SoundRepetition) : this.defaultSoundRepetition;
  }
}
