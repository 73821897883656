import { Action, createReducer, on } from '@ngrx/store';
import update from 'immutability-helper';
import { RequestState } from '../../../../essentials/types/src/requestState';
import {
  clearPharmacySearchResults,
  openOrStartConversationWithPharmacy,
  openOrStartConversationWithPharmacyFailure,
  openOrStartConversationWithPharmacySuccess,
  searchForPharmacies,
  searchForPharmaciesFailure,
  searchForPharmaciesSuccess,
  setPharmacySearchTerm,
  setTotalResultsForPharmacySearchQuery,
} from './pharmacy-search.actions';
import { PharmacySearchState } from './pharmacy-search.state';

export const initialPharmacySearchState: PharmacySearchState = {
  searchTerm: '',
  totalResultsForQuery: 0,
  searchResults: [],
  requestStates: {
    search: RequestState.Init,
    openOrStartConversation: RequestState.Init,
  },
};

const _searchReducer = createReducer(
  initialPharmacySearchState,

  on(setPharmacySearchTerm, (state, { searchTerm }) =>
    update(state, {
      searchTerm: { $set: searchTerm },
      requestStates: { $set: initialPharmacySearchState.requestStates },
    })
  ),

  on(clearPharmacySearchResults, (state) =>
    update(state, {
      totalResultsForQuery: { $set: 0 },
      searchResults: { $set: [] },
    })
  ),

  on(setTotalResultsForPharmacySearchQuery, (state: PharmacySearchState, { totalResultsForQuery }) =>
    update(state, { totalResultsForQuery: { $set: totalResultsForQuery } })
  ),

  on(searchForPharmacies, (state: PharmacySearchState) =>
    update(state, {
      requestStates: { search: { $set: RequestState.InProgress } },
    })
  ),

  on(searchForPharmaciesSuccess, (state: PharmacySearchState, { searchResults, total }) =>
    update(state, {
      searchResults: { $push: searchResults },
      totalResultsForQuery: { $set: total },
      requestStates: { search: { $set: RequestState.Success } },
    })
  ),

  on(searchForPharmaciesFailure, (state: PharmacySearchState) =>
    update(state, { requestStates: { search: { $set: RequestState.Error } } })
  ),

  on(openOrStartConversationWithPharmacy, (state: PharmacySearchState) =>
    update(state, { requestStates: { openOrStartConversation: { $set: RequestState.InProgress } } })
  ),

  on(openOrStartConversationWithPharmacySuccess, (state: PharmacySearchState) =>
    update(state, { requestStates: { openOrStartConversation: { $set: RequestState.Success } } })
  ),

  on(openOrStartConversationWithPharmacyFailure, (state: PharmacySearchState) =>
    update(state, { requestStates: { openOrStartConversation: { $set: RequestState.Error } } })
  )
);

export function pharmacySearchReducer(state: PharmacySearchState | undefined, action: Action): PharmacySearchState {
  return _searchReducer(state, action);
}
