import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { Logger } from '../../../../essentials/util/src/logger';

const logger = new Logger('ExceptionCatcher');

export function exceptionCatcher<T>(reducer: ActionReducer<T>) {
  return (state: T, action: Action) => {
    try {
      state = reducer(state, action);
    } catch (error) {
      logger.error('Reducer failed:', error);
    }
    return state;
  };
}

export const metaReducers: MetaReducer[] = [exceptionCatcher];
