import { Inject, Injectable, Optional } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { LoadStatus } from '../../../../../../essentials/types/src/loadStatus';
import { LogoutType } from '../../../../../../essentials/types/src/logoutType';
import {
  SINGLE_SIGN_ON_SERVICE,
  SingleSignOnServiceInterface,
} from '../../../../../../essentials/types/src/service-interfaces/single-sign-on.service.interface';
import { Logger } from '../../../../../../essentials/util/src/logger';
import { CommonState } from '../../../../../../store/src/common-store/common.state';
import {
  checkSsoSessionStatus,
  initUser,
  loadInitialUser,
  logoutUser,
  setUserLoadStatus,
  setUserOnLogin,
} from '../../../../../../store/src/common-store/user-store/actions/user.actions';
import { selectUserLoadStatus } from '../../../../../../store/src/common-store/user-store/selectors/user.selectors';
import { InitUserService } from '../../../services/init-user.service';
import { LogoutService } from '../../../services/logout.service';

const logger = new Logger('UserEffects');

@Injectable()
export class UserEffects {
  initUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initUser),
        withLatestFrom(this.store.select(selectUserLoadStatus)),
        map(([_, userLoadStatus]) => {
          if (userLoadStatus === LoadStatus.Init || userLoadStatus === LoadStatus.Error) {
            this.store.dispatch(loadInitialUser());
          } else {
            logger.warn('Tried to init user while user was already initialized');
          }
        })
      ),
    { dispatch: false }
  );

  loadInitialUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInitialUser),
      mergeMap(async () => {
        try {
          const session = await this.initUserService.getUserOfCurrentSession();

          if (session.userType === 'known') {
            return setUserOnLogin({ user: session.user });
          } else {
            if (session.logoutType) {
              logger.info(`Received logoutType '${session.logoutType}', calling logoutService`);
              await this.logoutService.logout(session.logoutType);
            }
            return setUserLoadStatus({ userLoadStatus: LoadStatus.UpToDate });
          }
        } catch (err) {
          logger.error('Failed to initialize user', err);
          await this.logoutService.logout(LogoutType.FailedInitialLoad);
          return setUserLoadStatus({ userLoadStatus: LoadStatus.Error });
        }
      })
    )
  );

  checkSsoSessionStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(checkSsoSessionStatus),
        mergeMap(async ({ user }) => {
          try {
            if (!this.singleSignOnService) {
              return;
            }
            const isPharmacyLoggedInWithSso = await this.singleSignOnService.isPharmacyLoggedInWithSso(user);
            if (isPharmacyLoggedInWithSso) {
              await this.singleSignOnService.initKeycloakOnLogin();
              const isSsoSessionExpired = await this.singleSignOnService.isSsoSessionExpired();
              if (isSsoSessionExpired) {
                this.singleSignOnService.resetKeycloakService();
                this.store.dispatch(logoutUser({ logoutType: LogoutType.SsoSessionExpired }));
              }
            }
          } catch (err) {
            logger.error('Failed to check sso session status', err);
          }
        })
      ),
    { dispatch: false }
  );

  logoutUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutUser),
        mergeMap(async ({ logoutType }) => {
          await this.logoutService.logout(logoutType);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private initUserService: InitUserService,
    private logoutService: LogoutService,
    private store: Store<CommonState>,
    @Optional() @Inject(SINGLE_SIGN_ON_SERVICE) private singleSignOnService?: SingleSignOnServiceInterface
  ) {}
}
