import gql from 'graphql-tag';
import { appointmentAttributes } from '../../../../../common/resources/src/graphql/attributes';

export default gql`
  mutation setNoteForAppointment($dateTime: AWSDateTime!, $encryptedNote: String) {
    setNoteForAppointment(dateTime: $dateTime, encryptedNote: $encryptedNote) {
      ${appointmentAttributes}
    }
  }
`;
