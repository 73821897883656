import { Component, Inject } from '@angular/core';
import { CONFIG, MeaConfig } from '../../../../../essentials/types/src/mea-config';

@Component({
  selector: 'common-imprint',
  templateUrl: './imprint.component.html',
  standalone: true,
})
export class ImprintComponent {
  isNativeApp: boolean;

  constructor(@Inject(CONFIG) private config: MeaConfig) {
    this.isNativeApp = this.config.featureFlags.isNativeApp;
  }
}
