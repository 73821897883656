import { createSelector } from '@ngrx/store';
import { Dictionary } from 'ts-essentials';
import { ConsentType } from '../../../../essentials/types/src/consent';
import { UserConsentState, UserConsentStatus } from './user-consent.state';

const selectUserConsentState = (state: { userConsent: UserConsentState }) => state.userConsent;

const selectUserConsentDictionary = createSelector(selectUserConsentState, (state: UserConsentState) => state.consents);

const selectUserConsent = (userCognitoId: string) =>
  createSelector(selectUserConsentDictionary, (consents: Dictionary<UserConsentStatus>) => consents[userCognitoId]);

export const selectConsentsLoadStatus = createSelector(
  selectUserConsentState,
  (state: UserConsentState) => state.consentsLoadStatus
);

export const selectIsConsentGiven = (userCognitoId: string) =>
  createSelector(
    selectUserConsent(userCognitoId),
    (userConsentStatus?: UserConsentStatus) => userConsentStatus?.[ConsentType.PHARMACY_DATA_CONSENT]
  );
