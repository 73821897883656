import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { firstValueFrom, from, Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AppsyncService, AppsyncServiceClient } from '../../../common/resources/src/services/appsync/appsync.service';
import { Appointment, AppointmentCreateInput, BackendAppointment } from '../../../essentials/types/src/appointment';
import { mapBackendAppointmentToAppointment } from '../../../essentials/util/src/appointment.util';
import { AppsyncErrorUtil } from '../../../essentials/util/src/appsync-error.util';
import { isNotNullOrUndefined } from '../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { CommonState } from '../../../store/src/common-store/common.state';
import { selectCognitoId } from '../../../store/src/common-store/user-store/selectors/user.selectors';
import { selectAppointments } from '../../../store/src/pharmacy/appointments/appointment.selectors';
import { AppointmentState } from '../../../store/src/pharmacy/appointments/appointment.state';
import cancelAppointment from '../graphql/mutations/appointments/cancelAppointment';
import createAppointment from '../graphql/mutations/appointments/createAppointment';
import deleteAppointment from '../graphql/mutations/appointments/deleteAppointment';
import hideDefaultAppointmentTypes from '../graphql/mutations/appointments/hideDefaultAppointmentTypes';
import setNoteForAppointment from '../graphql/mutations/appointments/setNoteForAppointment';
import updateCustomAppointmentTypes from '../graphql/mutations/appointments/updateCustomAppointmentTypes';
import getMyAppointments from '../graphql/queries/getMyAppointments';
import createdOrUpdatedAppointment from '../graphql/subscriptions/createdOrUpdatedAppointment';
import deletedAppointment from '../graphql/subscriptions/deletedAppointment';

dayjs.extend(utc);

@Injectable({ providedIn: 'root' })
export class AppsyncPharmacyAppointmentService {
  constructor(
    private appsyncService: AppsyncService,
    private store: Store<CommonState & { appointment: AppointmentState }>
  ) {}

  // ************* Queries *************

  async getMyAppointments(): Promise<Appointment[]> {
    const client = await this.appsyncService.getClient();

    let next: string | undefined;
    const backendAppointments: BackendAppointment[] = [];
    do {
      const { appointments, nextToken } = await this.getPaginatedAppointments(client, next);
      backendAppointments.push(...appointments);
      next = nextToken;
    } while (next);
    return backendAppointments.map(mapBackendAppointmentToAppointment);
  }

  // ************* Mutations *************

  async updateAppointment(
    oldDateTime: Dayjs,
    newDateTime: Dayjs,
    newDurationMinutes: number,
    newAvailableAppointmentTypes: string[]
  ) {
    await this.deleteAppointment(oldDateTime.toISOString());
    await firstValueFrom(
      this.store
        .select(selectAppointments)
        .pipe(
          filter(
            (appointments) => !appointments.find((appointment) => appointment.dateTime === oldDateTime.toISOString())
          )
        )
    );
    await this.createAppointment(newDateTime, newDurationMinutes, newAvailableAppointmentTypes);
  }

  async createAppointment(dateTime: Dayjs, durationMinutes: number, availableAppointmentTypes: string[]) {
    const client = await this.appsyncService.getClient();

    const variables: AppointmentCreateInput = {
      availableAppointmentTypes,
      dateTime: dateTime.utc().toISOString(),
      durationMinutes,
    };

    await client.mutate({
      mutation: createAppointment,
      variables,
    });
  }

  async cancelAppointment(dateTime: string) {
    const client = await this.appsyncService.getClient();
    await client.mutate({
      mutation: cancelAppointment,
      variables: {
        dateTime,
      },
    });
  }

  async deleteAppointment(dateTime: string) {
    const client = await this.appsyncService.getClient();
    await client.mutate({
      mutation: deleteAppointment,
      variables: {
        dateTime,
      },
    });
  }

  async setNoteForAppointment(dateTime: string, encryptedNote: string | undefined) {
    const client = await this.appsyncService.getClient();
    await client.mutate({
      mutation: setNoteForAppointment,
      variables: {
        dateTime,
        encryptedNote,
      },
    });
  }

  async updateCustomAppointmentTypes(customAppointmentTypes: string[]) {
    const client = await this.appsyncService.getClient();
    const cognitoId = await firstValueFrom(this.store.select(selectCognitoId).pipe(isNotNullOrUndefined()));
    await client.mutate({
      mutation: updateCustomAppointmentTypes,
      variables: {
        cognitoId,
        customAppointmentTypes,
      },
    });
  }

  async hideDefaultAppointmentTypes(hiddenDefaultAppointmentTypes: string[]) {
    const client = await this.appsyncService.getClient();
    const cognitoId = await firstValueFrom(this.store.select(selectCognitoId).pipe(isNotNullOrUndefined()));
    await client.mutate({
      mutation: hideDefaultAppointmentTypes,
      variables: {
        cognitoId,
        hiddenDefaultAppointmentTypes,
      },
    });
  }

  // ************* Subscriptions *************

  createdOrUpdatedAppointment(pharmacyCognitoId: string): Observable<Appointment> {
    return from(this.appsyncService.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: createdOrUpdatedAppointment,
            variables: { pharmacyCognitoId },
          })
          .pipe(map((response) => mapBackendAppointmentToAppointment(response.data.createdOrUpdatedAppointment)))
      )
    );
  }

  deletedAppointment(pharmacyCognitoId: string): Observable<Appointment> {
    return from(this.appsyncService.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: deletedAppointment,
            variables: { pharmacyCognitoId },
          })
          .pipe(map((response) => mapBackendAppointmentToAppointment(response.data.deletedAppointment)))
      )
    );
  }

  // ************* Helpers *************

  private getPaginatedAppointments = async (
    client: AppsyncServiceClient,
    nextToken: string | undefined
  ): Promise<{ appointments: BackendAppointment[]; nextToken: string | undefined }> => {
    let data;
    try {
      data = (
        await client.query({
          query: getMyAppointments,
          variables: { nextToken },
        })
      ).data;
      return data.getMyAppointments;
    } catch (err) {
      if (
        AppsyncErrorUtil.isAppsyncError(err) &&
        err.errors.every((error) => AppsyncErrorUtil.isElasticSearchNotFoundError(error)) &&
        err.data
      ) {
        return (err.data as any).getMyAppointments;
      } else {
        throw err;
      }
    }
  };
}
