import gql from 'graphql-tag';

export default gql`
  mutation updateTextblock(
    $id: String!
    $template: String
    $title: String
    $category: String
    $isDeactivated: Boolean
  ) {
    updateTextblock(
      id: $id
      textblock: { template: $template, title: $title, category: $category, isDeactivated: $isDeactivated }
    ) {
      __typename
      id
      template
      title
      category
      cognitoId
      idOfDefaultTextblock
      isDeactivated
    }
  }
`;
