<div>
  <h2 id="datenschutzerklaerung">MEADIREKT DATENSCHUTZERKLÄRUNG</h2>
  <h6>Stand: 08.02.2022</h6>
  <br />
  <h4>V O R B E M E R K U N G</h4>
  <ol>
    <li>
      Wir nehmen den Schutz Ihrer Daten sehr ernst. Bei der Verarbeitung Ihrer personenbezogenen Daten, die wir über Sie
      im Zusammenhang mit der Nutzung von MEADIREKT erhalten, richten wir uns streng nach den datenschutzrechtlichen
      Anforderungen der Europäischen Datenschutzgrundverordnung (DSGVO) und dem deutschen Datenschutzrecht (BDSG).
    </li>
    <li>
      Nachfolgend stellen wir Ihnen die gesetzlich nach Artikel 13 Datenschutzgrundverordnung (DSGVO) vorgeschriebenen
      Informationen über die Verarbeitung Ihrer personenbezogenen Daten bei uns zur Verfügung:
    </li>
  </ol>
  <h4>§ 1 Information über die Erhebung personenbezogener Daten</h4>
  <ol>
    <li>
      <p class="only-margin-bot">
        Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei Nutzung unserer Web-Applikation
        MEADIREKT:
      </p>
      <ol type="a">
        <li>
          „<b>PERSONENBEZOGENE DATEN</b>“ sind gemäß Art. 4 Nr. 1 DSGVO alle Daten, die auf Sie persönlich beziehbar
          sind, z. B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.
        </li>
        <li>
          „<b>GESUNDHEITSDATEN</b>“ sind gemäß Art. 4 Nr. 15 DSGVO alle Daten, die sich auf die körperliche oder
          geistige Gesundheit einer natürlichen Person, einschließlich der Erbringung von Gesundheitsdienstleistungen,
          beziehen und aus denen Informationen über deren Gesundheitszustand hervorgehen.
        </li>
      </ol>
    </li>
    <li>
      Diese Datenschutzerklärung gilt für die Datenverarbeitung durch die SANACORP Pharmahandel GmbH im Rahmen der
      Nutzung von Produkten, die auf diese Datenschutzerklärung verweisen (aktuell: Chatapplikation auf
      <a href="https://www.meineapotheke.de/meadirekt-chat">www.meineapotheke.de/meadirekt-chat</a>) (nachfolgend „<b
        >MEADIREKT PRODUKTE</b
      >“). Verantwortlich im Sinne von Art. 4 Abs. 7 DSGVO ist:
      <b>SANACORP Pharmahandel GmbH, Semmelweisstraße 4. 82152 Planegg</b>, Telefax 089/8581-260.
    </li>
    <li>
      Im Rahmen der Nutzung der MEADIREKT PRODUKTE werden auch Daten direkt durch Kooperationsapotheken unter Nutzung
      der durch SANACORP bereitgestellten technischen Infrastruktur und Software verarbeitet (beispielsweise:
      Vorbestellungen oder Chatkonversationen). In diesem Fall ist die jeweilige Apotheke für die Datenverarbeitung
      Verantwortlicher im Sinne von Art. 4 Abs. 7 DSGVO. Für die jeweilige Datenverarbeitung durch die Apotheke gilt die
      Datenschutzerklärung der Apotheke. SANACORP ist in diesen Fällen Auftragsverarbeiter im Sinne des Art. 28 DSGVO
      der Apotheke. Dies bedeutet, dass SANACORP als technischer Dienstleister im nach Weisung der Apotheke agiert.
    </li>
    <li>
      Unsere Datenschutzbeauftragte ist Herr Sami Abbas,
      <b
        >TASCO Revision und Beratung GmbH, Hagenauer Str. 42, 65203 Wiesbaden, E-Mail:
        <a href="mailto:datenschutz@sanacorp.de">datenschutz&#64;sanacorp.de</a></b
      >.
    </li>
    <li>
      Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein Kontaktformular werden die von Ihnen mitgeteilten Daten
      (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihre Fragen zu beantworten.
      Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist,
      oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen. Für eine Kontaktaufnahme
      mit unserer Datenschutzbeauftragten gelten identische Grundsätze.
    </li>
  </ol>
  <h4>
    § 2 Verarbeitung personenbezogener Daten bei der Nutzung von MEADIREKT, Zweck und Rechtsgrundlage der
    Datenverarbeitung
  </h4>
  <ol>
    <li>
      <p class="only-margin-bot">
        Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht registrieren und nicht als
        Gast-Nutzer chatten oder uns anderweitig Informationen übermitteln, erheben wir nur die personenbezogenen Daten,
        die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir die
        folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität
        und Sicherheit zu gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO):
      </p>
      <ul style="list-style: disc">
        <li>IP-Adresse</li>
        <li>Datum und Uhrzeit der Anfrage</li>
        <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
        <li>Inhalt der Anforderung (konkrete Seite)</li>
        <li>Zugriffsstatus/HTTP-Statuscode</li>
        <li>jeweils übertragene Datenmenge</li>
        <li>Website, von der die Anforderung kommt</li>
        <li>Browser</li>
        <li>Betriebssystem und dessen Oberfläche</li>
        <li>Sprache und Version der Browsersoftware.</li>
      </ul>
    </li>
    <li>
      Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer Website Cookies auf Ihrem Rechner
      gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten
      Browser zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie setzt (soweit nicht anders in
      dieser Datenschutzerklärung erwähnt: SANACORP), bestimmte Informationen zufließen. Cookies können keine Programme
      ausführen oder Viren auf Ihren Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt
      nutzerfreundlicher und effektiver zu machen.
    </li>
    <li>
      <p class="only-margin-bot">Besondere Hinweise zu Google Analytics</p>
      <p class="only-margin-bot">
        Unsere Websites benutzen Google Analytics, einen Webanalysedienst der Google Inc. (im Folgenden "Google").
        Google Analytics verwendet Cookies. Die durch diese Cookies erzeugten Informationen über die Benutzung der
        Websites (einschließlich der IP-Adresse des Nutzers, die jedoch mit der Methode anonymizeIp() anonymisiert wird,
        so dass Sie nicht mehr einem Anschluss zugeordnet werden kann) werden an einen Server von Google in den USA
        übertragen und dort gespeichert.
      </p>
      <p class="only-margin-bot">
        Google wird diese Informationen benutzen, um die Nutzung der Websites durch den jeweiligen Nutzer auszuwerten,
        um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der
        Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese
        Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte
        diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall die IP-Adresse des Nutzers mit anderen
        Daten von Google in Verbindung bringen. Der Nutzer kann die Installation der Cookies durch eine entsprechende
        Einstellung seiner Browser-Software verhindern; SANACORP weist den Nutzer jedoch darauf hin, dass er in diesem
        Fall gegebenenfalls nicht sämtliche Funktionen dieser Websites vollumfänglich nutzen kann. Durch die Nutzung
        dieser Websites erklärt der Nutzer sich mit der Bearbeitung der über ihn erhobenen Daten durch Google in der
        zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.
      </p>
      <p class="only-margin-bot">
        Diese Website verwendet Google Analytics zudem für eine geräteübergreifende Analyse von Besucherströmen, die
        über eine User-ID durchgeführt wird. Sie können im Google-Kundenkonto unter „Meine Daten“, „persönliche Daten“
        die geräteübergreifende Analyse Ihrer Nutzung deaktivieren.
      </p>
      <p class="only-margin-bot">
        Der Nutzer kann der Erhebung der Daten durch Google-Analytics mit Wirkung für die Zukunft widersprechen, indem
        er ein
        <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer"
          >Deaktivierungs-Add-on</a
        >
        für seinen Browser installiert.
      </p>
      <p class="only-margin-bot">
        Alternativ zum Browser-Add-On oder innerhalb von Browsern auf mobilen Geräten,
        <a (click)="denyGoogleAnalytics()">klickt der Nutzer bitte diesen Link</a>, um die Erfassung durch Google
        Analytics innerhalb der o.g. Websites zukünftig zu verhindern (das Opt Out funktioniert nur in diesem Browser
        und nur für diese Domain). Dabei wird ein Opt-Out-Cookie auf dem Gerät des Nutzers abgelegt. Löscht der Nutzer
        seine Cookies in diesem Browser, müsste er diesen Link erneut klicken.
      </p>
    </li>
    <li>
      <p class="only-margin-bot">Standortsuchdienste / Google Maps</p>
      <p class="only-margin-bot">
        Unsere Website bietet die Funktion an, eine nach bestimmten Kriterien ausgewählte Apotheke in der Nähe Ihres
        Standortes zu ermitteln. Wenn Sie diese Funktion nutzen, können zu Ihrer Lokalisierung der von Ihnen eingegebene
        Ort, die von Ihnen eingegebene Postleitzahl oder die von Ihrem Browser übermittelte IP-Adresse oder eine
        sogenannte Geolokalisierungs-API Ihres Webbrowsers (nachfolgend „<b>STANDORTDATEN</b>“) verwendet werden. Die
        STANDORTDATEN werden durch SANACORP ausschließlich zur Bereitstellung der Suchfunktion verwendet und im
        Anschluss, soweit nicht abweichend in dieser Datenschutzerklärung geregelt, gelöscht.
      </p>
      <p class="only-margin-bot">
        Die gefundenen Treffer können auf einer Google Maps Karte dargestellt werden. Google Maps ist ein Service der
        Google Inc., 1600 Amphitheater Parkway, Mountainview, Kalifornien 94043, USA (nachfolgend
        „<b>KARTENDIENSTLEISTER</b>“).
      </p>
      <p class="only-margin-bot">
        Ihre Standortdaten können - basierend auf den Einstellungen Ihres Endgerätes – als Geokoordinaten dem
        Kartendienstleister übertragen und dort als Startkoordinaten eingetragen werden. Bitte beachten Sie, dass bei
        der Nutzung der Dienste des Kartendienstleisters zusätzliche Daten durch diesen erhoben oder ausgewertet werden
        können. Hierauf haben wir keinen Einfluss. Weitere Informationen über Zweck und Umfang der Datenerhebung und
        -verarbeitung durch den KARTENDIENSTLEISTER finden Sie unter
        <a href="https://www.google.com/policies/privacy/partners/" target="_blank" rel="noopener noreferrer"
          >www.google.com/policies/privacy/partners/</a
        >. Dort finden Sie auch weitere Informationen zu Ihren Rechten und Einstellungsmöglichkeiten zum Schutz Ihrer
        Privatsphäre: Um die Suche über die Postleitzahl bzw. den Ort zu ermöglichen, nutzen wir den Service „Nominatim“
        der Openstreetmap Foundation, St John’s Innovation Centre, Cowley Road, Cambridge, CB4 0WS, Vereinigtes
        Königreich. Hierzu übermitteln wir die von Ihnen getätigte Eingabe sowie Ihre IP-Adresse an die Openstreetmap
        Foundation. Weitere Informationen zum Datenschutz bei der Openstreetmap Foundation finden Sie unter
        <a href="https://wiki.osmfoundation.org/wiki/Privacy_Policy" target="_blank" rel="noopener noreferrer"
          >https://wiki.osmfoundation.org/wiki/Privacy_Policy</a
        >. Eingaben im Suchfeld und Standortdaten werden durch SANACORP, nachdem sie in einer Weise anonymisiert worden
        sind, dass die betroffene Person nicht oder nicht mehr identifiziert werden kann, zur Fehleranalyse sowie zur
        Verbesserung des Produktes ausgewertet. Wir verknüpfen diese Daten nicht mit anderen Daten.
      </p>
    </li>
    <li>
      Sie können Ihre Browsereinstellung entsprechend Ihren Wünschen konfigurieren und z.B. die Annahme von
      Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie zur Klarstellungdarauf hin, dass Sie eventuell in
      diesem Fall nicht alle Funktionen dieser Website nutzen können.
    </li>
    <li>
      <p class="only-margin-bot">Chatfunktion – Erhebung von Daten durch die Apotheke</p>
      <ol type="a">
        <li>
          Über das MEADIREKT Angebot werden sowohl durch SANACORP als Verantwortlicher, als auch durch Apotheken, welche
          sich an MEADIREKT beteiligen als Verantwortlicher, unter Nutzung der durch SANACORP zur Verfügung gestellten
          technischen Infrastruktur und Software, personenbezogen Daten, einschließlich Gesundheitsdaten, verarbeitet.
        </li>
        <li>
          Die Nutzung von MEADIREKT eröffnet Ihnen u. a. die Möglichkeit, einen direkten Chat mit einer Apotheke zu
          führen. Diese Funktion steht sowohl registrierten Nutzern als auch Gastnutzern zur Verfügung. Im Rahmen eines
          Chats können Sie neben Textnachrichten (z. B. Vorbestellungen und Abfrage von Verfügbarkeit von Arzneimitteln)
          auch Bilder von Rezepten und sonstige Bilder übermitteln. Sie haben außerdem die Möglichkeit, den Chatverlauf
          zu einem späteren Zeitpunkt wieder zu öffnen und weiterzuführen.
        </li>
        <li>
          Im Zusammenhang mit Ihrer Nutzung von MEADIREKT werden Ihre personenbezogenen Daten (Art. 4 Nr. 1 DSGVO) und
          ggf. gesundheitsbezogene Daten (Art. 4 Nr. 15 DSGVO) durch die Apotheke als Verantwortlicher über die von
          SANACORP als Auftragsverarbeiter zur Verfügung gestellte technische Infrastruktur und Software verarbeitet,
          übermittelt, verwendet und gespeichert. Die im Rahmen des MEADIREKT Chats hochgeladene bzw. ausgetauschten
          Kommunikationsinhalte werden ausschließlich zwischen Ihnen und der Apotheke, die Sie mittels des Chats
          kontaktieren, verschlüsselt ausgetauscht. Diese Inhalte kann SANACORP nicht einsehen. Die kontaktierte
          Apotheke verwendet Ihre Daten zur Abwicklung von Vorbestellungen, Reservierungen und – je nach Einzelfall –
          zur fachgerechten Durchführung pharmazeutischer Beratung. Die Inhalte der Kommunikation kann weder ein Dritter
          noch SANACORP aufgrund einer Ende-zu-Ende-Verschlüsselung einsehen bzw. auslesen.
        </li>
      </ol>
    </li>
    <li>
      SANACORP erhebt vorübergehend anonymisierte Daten über die Nutzung von MEADIREKT durch Sie (Information über
      Nutzerstatus, Daten über die Verweildauer in der Anwendung, Abbruchverhalten, Anzahl Chatnachrichten, verwendete
      Suchbegriffe, Anzahl der Suchen, Anzahl Lieblingsapotheken, Anzahl Kontakte mit Apotheken). Die erhobenen Daten
      werden fortlaufend in statistische Datensammlungen überführt, die dann keine Zuordnung mehr zu Ihnen ermöglichen.
      Die erhobenen statistischen Daten werden ausschließlich dazu genutzt, MEADIREKT noch besser auf die Bedürfnisse
      der Endkunden anzupassen. Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 lit f) DSGVO. Es liegt im legitimen
      Interesse von SANACORP die Nutzung der Seite auszuwerten, um diese anzupassen und zu verbessern.
    </li>
    <li>
      Gesundheitsdaten verarbeiten wir nur mit Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a), Art. 9 Abs. 2 lit. a)
      DSGVO. Angaben zu Ihrer Identität darf SANACORP im Rahmen Ihrer Registrierung als Nutzer gem. Art. 6 Abs. 1 lit.
      b) DSGVO erheben, um Ihnen gegenüber die Nutzung von MEADIREKT zu gewährleisten. Weiterhin gibt SANACORP Ihre
      Daten als registrierter Nutzer an die Apotheken weiter, um diesen zu ermöglichen ihre Dienstleistung
      (Kommunikation über den Chat) zu erbringen.
    </li>
    <li>
      <p class="only-margin-bot">Terminbuchungsfunktion</p>
      <ol type="a">
        <li>
          Innerhalb der meineapotheke.de App, auf www.meineapotheke.de und dem meadirekt Chat steht eine
          Terminbuchungsfunktion für registrierte Nutzer als auch Gastnutzer zur Verfügung.
        </li>
        <li>
          Die im Rahmen der Nutzung der Terminbuchungsfunktion erhobenen Daten (E-Mail Adresse, Name, Datum des Termins,
          ggf. Zweck des Termins) (nachfolgend „<b>Termindaten</b>“) werden durch die Kooperationsapotheke Ihrer Wahl
          als Verantwortlicher im Sinne von Art. 4 Nr. 7 DSGVO verarbeitet. Sanacorp verarbeitet Termindaten im Rahmen
          der technischen Umsetzung der Terminbuchungsfunktion nach Weisung der jeweiligen Kooperationsapotheke als
          Auftragsverarbeiter. Eine Verarbeitung von Termindaten durch Sanacorp zu eigenen Zwecken erfolgt ausdrücklich
          nicht.
        </li>
        <li>
          Termindaten werden ausschließlich zwischen Ihnen und der Kooperationsapotheke verschlüsselt ausgetauscht. Ein
          Zugriff durch Dritten erfolgt ausschließlich auf Basis eines Auftragsverarbeitungsvertrages unter Vereinbarung
          strikter Vertraulichkeit.
        </li>
      </ol>
    </li>
  </ol>
  <h4>§ 3 Empfänger von Daten</h4>
  <p>
    Ihre Daten, die für die Nutzung von MEADIREKT zu dem oben beschriebenen Zweck erforderlich sind, werden von SANACORP
    auf Servern eines externen Storage-Providers in Deutschland gespeichert. Außerdem werden Daten in oben beschriebenem
    Umfang und Zweck den Apotheken zur Verfügung gestellt, die Sie in MEADIREKT im Rahmen der Chatfunktion auswählen.
  </p>
  <p class="only-margin-bot">
    Darüber hinaus kann die ausgewählte Apotheke zu Dokumentationszwecken und zur Gewährleistung pharmazeutischer
    Sorgfalt geführte Chats exportieren und bei sich speichern. Für die Sicherheit Ihrer Daten dort wird in höchstem
    Maße Sorge getragen. Der jeweilige Apotheker handelt insoweit in eigenständiger datenschutzrechtlicher
    Verantwortlichkeit. Bitte beachten Sie deshalb die Datenschutzerklärung des jeweiligen Apothekers.
  </p>
  <h4>§ 4 Dauer der Speicherung Ihrer Daten</h4>
  <p>
    Die Daten Ihrer Nutzerregistrierung (Chatname, Mobilnummer oder Mailadresse) bleiben für die Dauer Ihrer
    Registrierung für MEADIREKT bei SANACORP gespeichert. Wenn Sie die Löschung Ihres Accounts veranlassen, werden diese
    Daten umgehend gelöscht. Kommunikationsinhalte registrierter Nutzer werden bis zur Löschung Ihres Accounts für ihren
    Kommunikationspartner (Apotheker) sichtbar gespeichert zum Zwecke der Dokumentation und für eventuelle Rückfragen.
    Nach Löschung Ihres Accounts werden die Kommunikationsdaten vier Wochen nach Löschung des Accounts endgültig
    gelöscht. Kommunikationsinhalte nicht registrierter Gastnutzer werden maximal 72 Stunden nach dem letzten
    Chatkontakt gespeichert. §7 der Nutzungsbedingungen bleibt unberührt.
  </p>
  <h4>§ 5 Ihre Rechte</h4>
  <ol>
    <li>
      <p class="only-margin-bot">
        Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:
      </p>
      <ul>
        <li>Recht auf Auskunft (Art. 15 DSGVO),</li>
        <li>Recht auf Berichtigung (Art. 16 DSGVO),</li>
        <li>unter den Voraussetzungen des Art. 18 DSGVO das Recht auf Einschränkung der Verarbeitung,</li>
        <li>
          sofern die gesetzlichen Voraussetzungen dafür vorliegen, das Recht, die Löschung Ihrer Daten zu verlangen
          sowie das Recht auf Widerspruch gegen deren Verarbeitung (Art. 17, 21 DSGVO),
        </li>
        <li>
          das Recht, eine erteilte Einwilligung jederzeit zu widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der
          Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird;
        </li>
        <li>Recht auf Datenübertragbarkeit (Art. 20 DSGVO).</li>
      </ul>
    </li>
    <li>
      Wenn Sie Veranlassung hierzu sehen, können Sie sich außerdem bei der für uns zuständigen
      Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch uns beschweren:
      <p class="flex-center">
        <b>Landesamt für Datenschutzaufsicht <br />Promenade 27 (Schloss) 91522 Ansbach</b>
      </p>
    </li>
  </ol>
</div>
