import gql from 'graphql-tag';
import { consentAttributes } from '../../../../common/resources/src/graphql/attributes';

export default gql`
    subscription updatedConsentForPharmacy($pharmacyId: ID) {
        updatedConsentForPharmacy(pharmacyId: $pharmacyId) {
            ${consentAttributes}
        }
    }
`;
