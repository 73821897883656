import { Injectable } from '@angular/core';
import getPharmaciesByText from '../../../../common/resources/src/graphql/queries/getPharmaciesByText';
import { AppsyncService } from '../../../../common/resources/src/services/appsync/appsync.service';
import { PharmacyWithChatUser } from '../../../../essentials/types/src/pharmacy';

export const DEFAULT_LIMIT = 10;

@Injectable({
  providedIn: 'root',
})
export class PharmacySearchService {
  constructor(private appsync: AppsyncService) {}

  async searchPharmacies(
    query: string,
    offset: number
  ): Promise<{ pharmacies: PharmacyWithChatUser[]; total: number }> {
    const client = await this.appsync.getClient();
    const variables = { query, offset, limit: DEFAULT_LIMIT };
    const queryOptions = {
      query: getPharmaciesByText,
      variables,
    };
    const { data } = await client.query(queryOptions);
    return data.getPharmaciesByText;
  }
}
