import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

export type ColorScheme = 'dark' | 'light';

@Component({
  selector: 'common-legal-text-container',
  templateUrl: './legal-text-container.component.html',
  styleUrls: ['./legal-text-container.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class LegalTextContainerComponent {
  @Input() colorScheme: ColorScheme | undefined;
}
