import { Appointment, AppointmentTypeCategory, BackendAppointment } from '../../types/src/appointment';

export function mapBackendAppointmentToAppointment(backendAppointment: BackendAppointment): Appointment {
  const availableAppointmentTypes =
    backendAppointment.availableAppointmentTypes && new Set(backendAppointment.availableAppointmentTypes);
  return {
    ...backendAppointment,
    availableAppointmentTypes,
  };
}

export function mergeAvailableAppointmentTypes(appointments: Appointment[]): Set<string> {
  const mergedAppointmentTypes: Set<string> = new Set([]);
  for (const appointment of appointments) {
    appointment.availableAppointmentTypes.forEach((appointmentType) => mergedAppointmentTypes.add(appointmentType));
  }
  return mergedAppointmentTypes;
}

export function getAppointmentTypeCategory(appointmentType: string): AppointmentTypeCategory {
  const categoryString = appointmentType.split('_')[0];
  return categoryString === 'DEFAULT' ? AppointmentTypeCategory.DEFAULT : AppointmentTypeCategory.CUSTOM;
}

export function sortAppointments(a: string, b: string) {
  const typeA = a.toLowerCase().substring(a.indexOf('_') + 1);
  const typeB = b.toLowerCase().substring(b.indexOf('_') + 1);
  return typeA === typeB ? 0 : typeA < typeB ? -1 : 1;
}
