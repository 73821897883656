import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import isNil from 'lodash-es/isNil';
import { BehaviorSubject, combineLatest, firstValueFrom, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import { CONFIG } from '../../../../../essentials/types/src/mea-config';
import { Logger } from '../../../../../essentials/util/src/logger';
import { CommonState } from '../../../../../store/src/common-store/common.state';
import {
  selectIsOnline,
  selectIsVisible,
} from '../../../../../store/src/common-store/device-store/selectors/device.selectors';
import {
  hideErrorHeader,
  showErrorHeader,
} from '../../../../../store/src/common-store/error-header-store/actions/error-header.actions';
import {
  checkUserAndRestartSubscriptions,
  markDataWithSubscriptionsAsStale,
  stopAppsyncSubscriptions,
} from '../../../../../store/src/common-store/other/actions/subscription.actions';
import { selectUser } from '../../../../../store/src/common-store/user-store/selectors/user.selectors';

const logger = new Logger('SubscriptionErrorHandlerService');

export const BACKGROUND_RETRY_THRESHOLD_WEB = 5;
// Subscriptions do not work anymore when app is in background
export const BACKGROUND_RETRY_THRESHOLD_APP = -1;
export const ERROR_HEADER_THRESHOLD = 7;

@Injectable({ providedIn: 'root' })
export class SubscriptionErrorHandlerService {
  private config = inject(CONFIG);
  private store: Store<CommonState> = inject(Store);

  // We have to use a subject instead of NgRx here, otherwise we run into strange issues on macOS chrome standby
  public subscriptionError$ = new Subject<string>();
  public numberOfSubscriptionErrors$ = new BehaviorSubject<number>(0);

  public deviceStateAllowsSubscriptions$ = combineLatest([
    this.store.select(selectIsOnline),
    this.store.select(selectIsVisible),
    this.numberOfSubscriptionErrors$,
  ]).pipe(
    map(([isOnline, isVisible, numberOfSubscriptionErrors]) => {
      return isOnline && (isVisible || numberOfSubscriptionErrors <= this.backgroundRetryThreshold);
    }),
    distinctUntilChanged()
  );

  private readonly backgroundRetryThreshold: number;

  constructor() {
    this.backgroundRetryThreshold = this.config.featureFlags.isNativeApp
      ? BACKGROUND_RETRY_THRESHOLD_APP
      : BACKGROUND_RETRY_THRESHOLD_WEB;
    this.watchAppsyncSubscriptionsEnabled();
    this.watchSubscriptionErrors();
  }

  resetSubscriptionErrors() {
    this.numberOfSubscriptionErrors$.next(0);
    this.store.dispatch(hideErrorHeader());
  }

  private watchAppsyncSubscriptionsEnabled() {
    this.deviceStateAllowsSubscriptions$
      .pipe(withLatestFrom(this.store.select(selectUser)))
      .subscribe(([subscriptionsEnabled, currentUser]) => {
        if (isNil(currentUser)) {
          return;
        }
        if (subscriptionsEnabled) {
          this.resetSubscriptionErrors();
          this.store.dispatch(checkUserAndRestartSubscriptions());
        } else {
          this.store.dispatch(stopAppsyncSubscriptions());
          this.store.dispatch(markDataWithSubscriptionsAsStale());
        }
      });
  }

  private watchSubscriptionErrors() {
    this.subscriptionError$
      .pipe(debounceTime(50), withLatestFrom(this.numberOfSubscriptionErrors$))
      .subscribe(async ([error, numberOfSubscriptionErrors]) => {
        this.numberOfSubscriptionErrors$.next(numberOfSubscriptionErrors + 1);

        // Linear backoff on retries
        await new Promise((resolve) => setTimeout(resolve, 200 * numberOfSubscriptionErrors));

        const subscriptionsEnabled = await firstValueFrom(this.deviceStateAllowsSubscriptions$);
        if (subscriptionsEnabled) {
          this.showErrorHeaderOnRepeatedSubscriptionError(error, numberOfSubscriptionErrors);
          this.store.dispatch(checkUserAndRestartSubscriptions());
        }
      });
  }

  private showErrorHeaderOnRepeatedSubscriptionError(error: any, numberOfSubscriptionErrors: number) {
    if (numberOfSubscriptionErrors > ERROR_HEADER_THRESHOLD) {
      logger.error('RepeatedSubscriptionError', error);
      this.store.dispatch(showErrorHeader({ errorType: 'SUBSCRIPTION_ERROR' }));
    }
  }
}
