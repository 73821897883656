import { Injectable } from '@angular/core';
import { Appointment } from '../../../../../essentials/types/src/appointment';
import { EncryptionService } from './encryption.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentEncryptionService {
  constructor(private encryptionService: EncryptionService) {}

  encryptString(string: string, appointment: Appointment, publicKey: string): string | false {
    const saltedName = this.saltString(string, appointment);
    return this.encryptionService.encryptUsingPublicKey(saltedName, publicKey);
  }

  decryptString(encryptedString: string, appointment: Appointment, privateKey: string): string | false {
    const saltedName = this.encryptionService.decryptUsingPrivateKey(encryptedString, privateKey);
    return saltedName && this.extractString(saltedName, appointment);
  }

  private saltString(name: string, appointment: Appointment): string {
    return `${appointment.pharmacyCognitoId}_${appointment.dateTime}_${name}`;
  }

  private extractString(saltedString: string, appointment: Appointment): string {
    return saltedString.replace(`${appointment.pharmacyCognitoId}_${appointment.dateTime}_`, '');
  }
}
