import gql from 'graphql-tag';
import UserKey from '../../../../../essentials/types/src/userKey';
import { CreateConversationInput } from '../../services/encryption/reset-key-pair.service';

export interface ResetKeyPairEndUserInput {
  conversationsToCreate: CreateConversationInput[];
  publicKey: string;
  userKey: UserKey;
  encryptionSalt: string;
}

export default gql`
  mutation resetKeyPairForEndUser(
    $conversationsToCreate: [ConversationsToCreateInput!]!
    $publicKey: String!
    $userKey: createUserKeyInput!
    $encryptionSalt: String!
  ) {
    resetKeyPairForEndUser(
      resetKeyPairInput: {
        conversationsToCreate: $conversationsToCreate
        publicKey: $publicKey
        userKey: $userKey
        encryptionSalt: $encryptionSalt
      }
    )
  }
`;
