import { inject, Inject, Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import FeatureFlags from '../../../../essentials/types/src/feature-flags';
import { CONFIG, MeaConfig } from '../../../../essentials/types/src/mea-config';
import { isNotNullOrUndefined } from '../../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { UnreadMessagesUtil } from '../../../../essentials/util/src/unread-messages.util';
import { newBackendMessage } from '../../../../store/src/common-store/chat-store/actions/chat-message.actions';
import { CommonState } from '../../../../store/src/common-store/common.state';
import { selectIsVisible } from '../../../../store/src/common-store/device-store/selectors/device.selectors';
import { selectCognitoId } from '../../../../store/src/common-store/user-store/selectors/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class TabTitleService implements OnDestroy {
  private actions$: Actions = inject(Actions);
  private store: Store<CommonState> = inject(Store);
  private titleService = inject(Title);

  private readonly unreadMessagesIcon = 'assets/icon/favicon_new_message.ico';
  private readonly defaultIcon = 'assets/icon/favicon.ico';

  private unreadMessages = 0;
  private cognitoId = '';
  private title: string;
  private featureFlags: FeatureFlags;
  private isVisible: boolean | undefined;

  private unsubscribe$ = new Subject<void>();

  constructor(@Inject(CONFIG) private config: MeaConfig) {
    this.featureFlags = config.featureFlags;
    this.title = this.titleService.getTitle();

    this.store
      .select(selectCognitoId)
      .pipe(takeUntil(this.unsubscribe$), isNotNullOrUndefined())
      .subscribe((cognitoId) => (this.cognitoId = cognitoId));

    this.store
      .select(selectIsVisible)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((visible) => {
        this.isVisible = visible;
        if (visible) {
          this.resetTabTitle();
        }
      });

    this.actions$
      .pipe(
        takeUntil(this.unsubscribe$),
        ofType(newBackendMessage),
        filter(({ message }) => UnreadMessagesUtil.countMessageAsUnread(this.cognitoId, message) && !this.isVisible)
      )
      .subscribe(() => {
        this.recognizeNewMessage();
      });
  }

  changeFavicon(favicon: string) {
    const link = document.createElement('link');
    const oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-favicon';
    link.rel = 'icon';
    link.type = 'image/x-icon';
    link.href = favicon;
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private recognizeNewMessage() {
    if (this.unreadMessages === 0) {
      this.title = this.titleService.getTitle();
    }
    this.unreadMessages++;
    this.titleService.setTitle(`(${this.unreadMessages}) ${this.title}`);
    if (this.featureFlags.changeIconWhenChangingTabTitle) {
      this.changeFavicon(this.unreadMessagesIcon);
    }
  }

  private resetTabTitle() {
    if (this.unreadMessages > 0) {
      this.unreadMessages = 0;
      this.titleService.setTitle(this.title);
      if (this.featureFlags.changeIconWhenChangingTabTitle) {
        this.changeFavicon(this.defaultIcon);
      }
    }
  }
}
