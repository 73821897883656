import { Action, createReducer, on } from '@ngrx/store';
import update from 'immutability-helper';
import cloneDeep from 'lodash-es/cloneDeep';
import groupBy from 'lodash-es/groupBy';
import mapValues from 'lodash-es/mapValues';
import { Dictionary } from 'ts-essentials';
import { Consent, ConsentIntent, ConsentType } from '../../../../essentials/types/src/consent';
import { LoadStatus } from '../../../../essentials/types/src/loadStatus';
import { markDataWithSubscriptionsAsStale } from '../../common-store/other/actions/subscription.actions';
import { clearUserOnLogout, setUserOnLogin } from '../../common-store/user-store/actions/user.actions';
import {
  loadUserConsents,
  loadUserConsentsFailure,
  loadUserConsentsSuccess,
  setUserConsent,
} from './user-consent.actions';
import { UserConsentState, UserConsentStatus } from './user-consent.state';

const initialUserConsentState: UserConsentState = {
  consents: {},
  consentsLoadStatus: LoadStatus.Init,
};

const _userConsentReducer = createReducer(
  initialUserConsentState,

  on(loadUserConsents, (state) =>
    update(state, {
      consentsLoadStatus: {
        $set: state.consentsLoadStatus === LoadStatus.Init ? LoadStatus.LoadingInitial : LoadStatus.Revalidating,
      },
    })
  ),

  on(loadUserConsentsSuccess, (state, { consents }) => {
    const groupedConsents: { [key: string]: Consent[] } = groupBy(consents, 'userCognitoId');
    const userConsents: Dictionary<UserConsentStatus> = mapValues(groupedConsents, (consentHistory) => {
      const mostRecentEntryWithDataConsent = consentHistory.find(
        (entry) => entry.consentType === ConsentType.PHARMACY_DATA_CONSENT
      );
      return { [ConsentType.PHARMACY_DATA_CONSENT]: mostRecentEntryWithDataConsent?.intent === ConsentIntent.CONSENT };
    });
    return update(state, { consents: { $set: userConsents }, consentsLoadStatus: { $set: LoadStatus.UpToDate } });
  }),

  on(setUserConsent, (state, { userConsent }) =>
    update(state, {
      consents: {
        $merge: {
          [userConsent.userCognitoId]: {
            [ConsentType.PHARMACY_DATA_CONSENT]: userConsent.intent === ConsentIntent.CONSENT,
          },
        },
      },
    })
  ),

  on(loadUserConsentsFailure, (state) => update(state, { consentsLoadStatus: { $set: LoadStatus.Error } })),

  on(markDataWithSubscriptionsAsStale, (state) => {
    if (state.consentsLoadStatus === LoadStatus.UpToDate) {
      return update(state, { consentsLoadStatus: { $set: LoadStatus.Stale } });
    } else {
      return state;
    }
  }),

  on(clearUserOnLogout, setUserOnLogin, () => cloneDeep(initialUserConsentState))
);

export function userConsentReducer(state: UserConsentState | undefined, action: Action): UserConsentState {
  return _userConsentReducer(state, action);
}
