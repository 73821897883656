import FeatureFlags from '../../../../libs/essentials/types/src/feature-flags';

export const featureFlags: { featureFlags: FeatureFlags } = {
  featureFlags: {
    showCommonChatMessagePharmacyInfo: false,
    showToastOnEndUserLogout: false,
    showWelcomeMessageForIntent: false,
    setsAppContext: false,
    showCustomAlertText: true,
    showEmailNotificationInput: false,
    showOpenTicketReminder: true,
    enableBrowserBackOnCustomModalClosing: true,
    showLegalInfoOnImageUploadModal: true,
    changeIconWhenChangingTabTitle: true,
    indicateSelfTyping: true,
    isNativeApp: false,
    requiresShadowRoot: false,
    logoutOnUserError: true,
    usesGoogleAnalytics: true,
    trackTicketUpdates: true,
    messageTextMaxLength: 4000,
    saveNotificationSettingsInStorage: false,
    allowDarkMode: false,
  },
};
