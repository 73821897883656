import { inject, Injectable, NgZone } from '@angular/core';
import { App } from '@capacitor/app';
import { Store } from '@ngrx/store';
import { CommonState } from '../../../../store/src/common-store/common.state';
import { setIsVisible } from '../../../../store/src/common-store/device-store/actions/device.actions';
import { CONFIG } from '../../../../essentials/types/src/mea-config';

@Injectable({ providedIn: 'root' })
export class VisibilityService {
  private config = inject(CONFIG);
  private ngZone = inject(NgZone);
  private store: Store<CommonState> = inject(Store);

  private isForceHidden = false;

  constructor() {
    this.store.dispatch(setIsVisible({ isVisible: !document.hidden }));

    const isNativeApp = this.config.featureFlags.isNativeApp;
    if (isNativeApp) {
      App.addListener('appStateChange', (state) => {
        this.ngZone.run(() => {
          this.store.dispatch(setIsVisible({ isVisible: state.isActive }));
        });
      });
    } else {
      window.addEventListener('blur', () => {
        this.ngZone.run(() => {
          this.onBlur();
        });
      });

      const pageLifecycleApiEvents = ['focus', 'pageshow', 'pagehide', 'visibilitychange', 'freeze', 'resume'];
      pageLifecycleApiEvents.forEach((event) =>
        window.addEventListener(
          event,
          () => {
            this.ngZone.run(() => {
              this.updateVisibilityStatus();
            });
          },
          { capture: true }
        )
      );
    }
  }

  public forceHidden() {
    this.isForceHidden = true;
    this.store.dispatch(setIsVisible({ isVisible: false }));
  }

  public disableForceHidden() {
    this.isForceHidden = false;
    this.store.dispatch(setIsVisible({ isVisible: !document.hidden }));
  }

  private onBlur() {
    this.store.dispatch(setIsVisible({ isVisible: false }));
  }

  private updateVisibilityStatus() {
    if (!this.isForceHidden) {
      this.store.dispatch(setIsVisible({ isVisible: !document.hidden }));
    }
  }
}
