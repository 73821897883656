import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../essentials/types/src/actionType';
import { Appointment } from '../../../../essentials/types/src/appointment';

export const initAppointments = createAction(`${ActionType.Appointment}: Init appointments`);
export const loadAppointments = createAction(`${ActionType.Appointment}: Load appointments`);
export const loadAppointmentsSuccess = createAction(
  `${ActionType.Appointment}: Load appointments success`,
  props<{ appointments: Appointment[] }>()
);
export const setAppointment = createAction(
  `${ActionType.Appointment}: Set Appointment`,
  props<{ appointment: Appointment }>()
);
export const deleteAppointment = createAction(
  `${ActionType.Appointment}: Delete Appointment`,
  props<{ dateTime: string }>()
);
export const loadAppointmentsFailure = createAction(`${ActionType.Appointment}: Load appointments failure`);
