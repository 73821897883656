import gql from 'graphql-tag';
import { consentAttributes } from '../../../../common/resources/src/graphql/attributes';

export default gql`
    query getConsentsOrRevocationsForPharmacy($nextToken: String) {
        getConsentsOrRevocationsForPharmacy(nextToken: $nextToken) {
            __typename
            consents {
                ${consentAttributes}
            }
            nextToken
        }
    }
`;
