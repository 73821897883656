import { AsyncPipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, firstValueFrom, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { CustomModalController } from '../../../../../../common/ui-components/src/ionic/controllers/custom-modal.controller';
import { ModalHeaderComponent } from '../../../../../../common/ui-components/src/ionic/modal-header/modal-header.component';
import { RemoveCommonChatDuettFields } from '../../../../../../essentials/types/src/common-chat-duett-fields';
import { AddTextblockToMessage } from '../../../../../../essentials/types/src/duett-schema/de.meineapotheke.chat/add_textblock/jsonschema/1-0-0';
import { Textblock } from '../../../../../../essentials/types/src/textblock';
import { TextblockCategory } from '../../../../../../essentials/types/src/textblockCategory';
import { isNotNullOrUndefined } from '../../../../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { CommonState } from '../../../../../../store/src/common-store/common.state';
import { analyticsDuettEvent } from '../../../../../../store/src/common-store/other/actions/common-analytics.actions';
import { loadTextblocksIfNotPresent } from '../../../../../../store/src/pharmacy/textblocks/actions/textblock.actions';
import {
  selectCategorizedUsableTextblocks,
  selectTextblock,
  selectTextblockCategories,
} from '../../../../../../store/src/pharmacy/textblocks/selectors/textblock.selectors';
import { getFirstTextblockInList } from '../../../../../../store/src/pharmacy/textblocks/selectors/util/textblock-selector.util';
import { TextblockState } from '../../../../../../store/src/pharmacy/textblocks/state/textblock.state';
import { PHARMACY_ROUTES, PharmacyRoutesInterface } from '../../../../interfaces/pharmacy-routes-interface';
import { ChatTextblockEditorComponent } from '../../chat-textblock-editor/chat-textblock-editor.component';
import { TextblocksListComponent } from '../../textblocks-list/textblocks-list.component';

@Component({
  selector: 'common-chat-textblocks',
  templateUrl: './chat-textblocks.component.html',
  styleUrls: ['./chat-textblocks.component.scss'],
  standalone: true,
  imports: [
    ModalHeaderComponent,
    IonicModule,
    TextblocksListComponent,
    ChatTextblockEditorComponent,
    TranslateModule,
    AsyncPipe,
  ],
})
export class ChatTextblocksComponent implements OnInit, OnDestroy {
  selectedTextblockId$ = new ReplaySubject<string>(1);
  selectedTextblock$ = this.selectedTextblockId$.pipe(switchMap((id) => this.store.select(selectTextblock(id))));

  textblockCategories$: Observable<TextblockCategory[]> = this.store.select(selectTextblockCategories);
  categorizedTextblocks$: Observable<{ [category: string]: Textblock[] }> = this.store.select(
    selectCategorizedUsableTextblocks
  );

  private unsubscribe$ = new Subject<void>();

  constructor(
    private modalController: CustomModalController,
    private store: Store<CommonState & { textblockData: TextblockState }>,
    private router: Router,
    @Inject(PHARMACY_ROUTES) private pharmacyRoutes: PharmacyRoutesInterface
  ) {}

  ngOnInit(): void {
    this.store.dispatch(loadTextblocksIfNotPresent());
    this.selectFirstTextblockInList();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public selectTextblockId(textblockId: string) {
    this.selectedTextblockId$.next(textblockId);
  }

  public async submit(template: string) {
    const selectedTextblock = await firstValueFrom(this.selectedTextblock$);
    if (selectedTextblock) {
      this.trackTextblockWasAddedToMessageEvent(selectedTextblock.id || '', selectedTextblock.category);
    }
    await this.modalController.dismiss({
      ...selectedTextblock,
      template,
    });
  }

  async navigateToSettings() {
    const selectedTextblockId = await firstValueFrom(this.selectedTextblockId$);
    await this.router.navigate([...this.pharmacyRoutes.textblockSettings, selectedTextblockId]);
    await this.modalController.dismiss();
  }

  private selectFirstTextblockInList() {
    combineLatest([this.categorizedTextblocks$, this.textblockCategories$])
      .pipe(
        takeUntil(this.unsubscribe$),
        map(([categorizedTextblocks, categories]) => getFirstTextblockInList(categories, categorizedTextblocks)),
        isNotNullOrUndefined(),
        take(1)
      )
      .subscribe((firstTextblockInList) => {
        if (firstTextblockInList.id) {
          this.selectedTextblockId$.next(firstTextblockInList.id);
        }
      });
  }

  private trackTextblockWasAddedToMessageEvent(textblockId: string, category: string) {
    const event: RemoveCommonChatDuettFields<AddTextblockToMessage> = {
      event_type: 'add_textblock',
      textblock_id: textblockId,
      category,
    };
    this.store.dispatch(analyticsDuettEvent({ event }));
  }
}
