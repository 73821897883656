@if (categorizedTextblocks) {
  <ion-list lines="none">
    @for (category of textblockCategories; track category) {
      <div class="category">
        <ion-list-header
          ><ion-label>{{ category }}</ion-label></ion-list-header
        >
        @for (textblock of categorizedTextblocks[category]; track textblock) {
          <ion-item
            [class.active]="textblockIsSelected(textblock)"
            [class.deactivated]="textblock.isDeactivated"
            mode="md"
            (click)="onTextblockClick(textblock.id)"
            button="true"
            >{{ textblock.title }}</ion-item
          >
        }
      </div>
    }
  </ion-list>
}
