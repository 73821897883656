import { Component, Input } from '@angular/core';
import { ColorScheme } from '../legal-text-container/legal-text-container.component';

@Component({
  selector: 'common-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  standalone: true,
})
export class TermsOfServiceComponent {
  @Input() colorScheme: ColorScheme | undefined;
}
