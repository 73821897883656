import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthState } from '../../../../essentials/types/src/user-auth.state';
import { Logger } from '../../../../essentials/util/src/logger';
import { assertUnreachable } from '../../../../essentials/util/src/typescript.util';
import { UserService } from './user.service';

const logger = new Logger('RouteGuardService');

@Injectable({
  providedIn: 'root',
})
export class RouteGuardService {
  redirectUrl: { [key: string]: string } = {};

  constructor(
    private router: Router,
    private userService: UserService
  ) {}

  resetRedirectUrlStack() {
    this.redirectUrl = {};
  }

  routeGuardCheck({
    url,
    requiredState,
    defaultRedirectUrl,
    allowGuest = false,
  }: {
    url: string;
    requiredState: AuthState;
    defaultRedirectUrl: string;
    allowGuest?: boolean;
  }): Observable<boolean> {
    let redirectUrlFound = false;

    return this.userService.loadedUser$.pipe(
      take(1),
      map((user) => {
        if (allowGuest && (!user || user.isGuest)) {
          return true;
        }
        if (requiredState === AuthState.signedIn) {
          return user !== null;
        } else if (requiredState === AuthState.signedOut) {
          return user === null;
        }
        assertUnreachable(requiredState);
      }),
      tap(async (correctState) => {
        if (!correctState) {
          logger.info(`Access denied. Tried to access '${url}', redirecting to '${defaultRedirectUrl}'`);
          this.redirectUrl[requiredState] = url;
          await this.router.navigateByUrl(defaultRedirectUrl);
        } else {
          const redirectUrl = this.redirectUrl[requiredState];
          if (redirectUrl) {
            logger.info(`Access granted, redirecting to '${redirectUrl}'`);
            redirectUrlFound = true;
            this.redirectUrl[requiredState] = '';
            await this.router.navigateByUrl(redirectUrl);
          }
        }
      }),
      map((correctState) => !!correctState && !redirectUrlFound)
    );
  }
}
