import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { openHelpModal } from '../../../../../../libs/store/src/common-store/other/actions/help.actions';
import { CustomModalController } from '../../../../../../libs/common/ui-components/src/ionic/controllers/custom-modal.controller';
import { LegalAndHelpPage } from '../../legal-and-help/legal-and-help.page';

@Injectable()
export class HelpEffects {
  openHelpModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openHelpModal),
        switchMap(async (action) => {
          const page = action.page ? action.page : '';
          const metaPage = await this.modalCtrl.create({
            component: LegalAndHelpPage,
            componentProps: { subPage: page },
            showBackdrop: true,
            backdropDismiss: true,
          });
          await metaPage.present();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private modalCtrl: CustomModalController
  ) {}
}
