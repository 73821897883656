import { NgTemplateOutlet } from '@angular/common';
import { Component, inject } from '@angular/core';
import { CONFIG } from '../../../../../essentials/types/src/mea-config';
import { ShadowRootComponent } from '../shadow-root/shadow-root.component';

@Component({
  selector: 'mea-optional-shadow-root',
  templateUrl: './optional-shadow-root.component.html',
  styleUrls: ['./optional-shadow-root.component.scss'],
  standalone: true,
  imports: [ShadowRootComponent, NgTemplateOutlet],
})
export class OptionalShadowRootComponent {
  private config = inject(CONFIG);

  requiresShadowRoot = this.config.featureFlags.requiresShadowRoot;
}
