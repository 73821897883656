import { Action, createReducer, on } from '@ngrx/store';
import update from 'immutability-helper';
import cloneDeep from 'lodash-es/cloneDeep';
import keyBy from 'lodash-es/keyBy';
import { RequestState } from '../../../../../essentials/types/src/requestState';
import { TextblockCategory } from '../../../../../essentials/types/src/textblockCategory';
import { logoutUser } from '../../../common-store/user-store/actions/user.actions';
import {
  createTextblock,
  deleteTextblock,
  deleteTextblockFailure,
  deleteTextblockSuccess,
  restoreTextblock,
  restoreTextblockFailure,
  restoreTextblockSuccess,
  setSelectedTextblockId,
  setTextblocks,
  updateTextblock,
  updateTextblockFailure,
  updateTextblockSuccess,
} from '../actions/textblock.actions';
import { TextblockState } from '../state/textblock.state';

export const initialTextblockState: TextblockState = {
  textblocks: {},
  textblockCategories: [
    TextblockCategory.pharmacyInfo,
    TextblockCategory.advice,
    TextblockCategory.reservation,
    TextblockCategory.completion,
  ],
  selectedTextblockId: '',

  requestStates: {
    createTextblock: RequestState.Init,
    updateTextblock: RequestState.Init,
    deleteTextblock: RequestState.Init,
  },
};

const _textblockReducer = createReducer(
  initialTextblockState,
  on(setTextblocks, (state: TextblockState, { textblocks }) => ({
    ...state,
    textblocks: keyBy(textblocks, 'id'),
  })),
  on(setSelectedTextblockId, (state: TextblockState, { textblockId }) => ({
    ...state,
    selectedTextblockId: textblockId,
  })),
  on(createTextblock, (state: TextblockState, { textblock }) =>
    update(state, {
      textblocks: { $merge: { [textblock.id]: textblock } },
    })
  ),
  on(updateTextblock, (state: TextblockState) =>
    update(state, {
      requestStates: { $merge: { updateTextblock: RequestState.InProgress } },
    })
  ),
  on(updateTextblockSuccess, (state: TextblockState, { textblock }) =>
    update(state, {
      textblocks: { $merge: { [textblock.id]: textblock } },
      requestStates: { $merge: { updateTextblock: RequestState.Success } },
    })
  ),
  on(updateTextblockFailure, (state: TextblockState) =>
    update(state, {
      requestStates: { $merge: { updateTextblock: RequestState.Error } },
    })
  ),

  on(deleteTextblock, restoreTextblock, (state: TextblockState) =>
    update(state, {
      requestStates: { $merge: { deleteTextblock: RequestState.InProgress } },
    })
  ),
  on(deleteTextblockSuccess, (state: TextblockState, { textblockId }) =>
    update(state, {
      textblocks: { $unset: [textblockId] },
      requestStates: { $merge: { deleteTextblock: RequestState.Success } },
      selectedTextblockId: { $set: initialTextblockState.selectedTextblockId },
    })
  ),
  on(restoreTextblockSuccess, (state: TextblockState, { textblockId }) =>
    update(state, {
      textblocks: { $unset: [textblockId] },
      requestStates: { $merge: { deleteTextblock: RequestState.Success } },
    })
  ),
  on(deleteTextblockFailure, restoreTextblockFailure, (state: TextblockState) =>
    update(state, {
      requestStates: { $merge: { deleteTextblock: RequestState.Error } },
    })
  ),
  on(logoutUser, () => cloneDeep(initialTextblockState))
);

export function textblockReducer(state: TextblockState | undefined, action: Action): TextblockState {
  return _textblockReducer(state, action);
}
