import { createSelector } from '@ngrx/store';
import { CommonState } from '../../common-store/common.state';
import { AppointmentState } from './appointment.state';

const selectAppointmentState = (state: CommonState & { appointment: AppointmentState }) => state.appointment;

export const selectAppointmentLoadStatus = createSelector(
  selectAppointmentState,
  (state: AppointmentState) => state.appointmentLoadStatus
);

export const selectAppointments = createSelector(selectAppointmentState, (state: AppointmentState) =>
  Object.values(state.appointments)
);
