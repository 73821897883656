import { NgClass } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { IconNamePipe } from '../../../../../essentials/util/src/pipes/icon-name.pipe';
import { ErrorComponent } from '../../errors/error/error.component';

@Component({
  selector: 'mea-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputComponent,
    },
  ],
  standalone: true,
  imports: [NgClass, FormsModule, IonicModule, ErrorComponent, IconNamePipe],
})
export class InputComponent implements ControlValueAccessor {
  @Input() autocomplete = 'on';
  @Input() disabled = false;
  @Input() maxlength: number | null = null;
  @Input() autofocus = false;
  @Input() readonly = false;

  @Input() type = 'text';
  @Input() label?: string;
  @Input() name = '';
  @Input() placeholder = '';
  @Input() error: string | null | undefined;

  @Input() iconLeft?: string;
  @Output() iconClickLeft = new EventEmitter<void>();

  @Input() iconRight?: string;
  @Output() iconClickRight = new EventEmitter<void>();

  @Input() value: string | undefined;
  @Output() valueChange = new EventEmitter<string | undefined>();

  @Output() focused = new EventEmitter();
  @Output() blurred = new EventEmitter();

  private _input: ElementRef<HTMLInputElement> | undefined;
  private touched = false;

  get input() {
    return this._input;
  }
  @ViewChild('input', { static: false })
  set input(element: ElementRef<HTMLInputElement> | undefined) {
    this._input = element;
    if (element && this.autofocus) {
      setTimeout(() => element.nativeElement.focus(), 100);
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  updateValue(value: string | undefined) {
    this.onChange(value);
    this.valueChange.emit(value);
    if (!this.touched) {
      this.touched = true;
      this.onTouched();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  blur() {
    this.input?.nativeElement.blur();
  }
}
