import { inject, Pipe, PipeTransform } from '@angular/core';
import { CONFIG } from '../../../types/src/mea-config';

@Pipe({ name: 'iconName', standalone: true })
export class IconNamePipe implements PipeTransform {
  private config = inject(CONFIG);

  private baseUrl = this.config.baseUrl || '';

  transform(value: string): any {
    return `${this.baseUrl}mea-cd-icons/icon/${value}.svg`;
  }
}
