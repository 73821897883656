import { NgStyle } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { VariableReplacementService } from '../../../../../common/resources/src/services/variable-replacement.service';
import { MeaButtonComponent } from '../../../../../common/ui-components/src/buttons/mea-button/mea-button.component';
import { ErrorComponent } from '../../../../../common/ui-components/src/errors/error/error.component';
import { CONFIG, MeaConfig } from '../../../../../essentials/types/src/mea-config';
import { CommonState } from '../../../../../store/src/common-store/common.state';
import { selectPharmacy } from '../../../../../store/src/common-store/user-store/selectors/user.selectors';

@Component({
  selector: 'common-chat-textblock-editor',
  templateUrl: './chat-textblock-editor.component.html',
  styleUrls: ['./chat-textblock-editor.component.scss'],
  standalone: true,
  imports: [FormsModule, NgStyle, ErrorComponent, MeaButtonComponent, TranslateModule],
})
export class ChatTextblockEditorComponent implements OnDestroy {
  @Input() editorMode = true;
  @Output() save = new EventEmitter<string>();
  @Output() edit = new EventEmitter<void>();

  templateParts: { type: 'input' | 'text'; text?: string; name?: string; placeholder?: string }[] = [];
  hasMissingVariables = false;
  isNativeApp: boolean;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<CommonState>,
    private variableReplacementService: VariableReplacementService,
    @Inject(CONFIG) private config: MeaConfig
  ) {
    this.isNativeApp = this.config.featureFlags.isNativeApp;
  }

  @Input() set template(template: string) {
    this.hasMissingVariables = false;
    this.store
      .select(selectPharmacy)
      .pipe(
        takeUntil(this.unsubscribe$),
        map((pharmacy) => {
          return pharmacy ? this.variableReplacementService.replaceVariables(template, { pharmacy }) : template;
        })
      )
      .subscribe((replacedTemplate) => {
        this.templateParts = replacedTemplate.split('$$').map((part, index) => {
          if (index % 2 === 0) {
            return { type: 'text', text: part };
          }
          return { type: 'input', name: part, placeholder: part.replace(/_/g, ' ') };
        });
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public submit() {
    if (this.templateParts.filter((part) => part.type === 'input' && !part.text).length > 0) {
      this.hasMissingVariables = true;
      return;
    }

    this.save.emit(this.templateParts.reduce((acc, part) => acc + part.text, ''));
  }

  editTextblock() {
    this.edit.emit();
  }
}
