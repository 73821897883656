import { AsyncPipe, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { IonicModule, IonNav } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import groupBy from 'lodash-es/groupBy';
import sortBy from 'lodash-es/sortBy';
import { combineLatest, firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Dictionary } from 'ts-essentials';
import { AppsyncCommonAppointmentService } from '../../../../../common/resources/src/services/appsync/appsync-common-appointment.service';
import { IconButtonComponent } from '../../../../../common/ui-components/src/buttons/icon-button/icon-button.component';
import { ErrorComponent } from '../../../../../common/ui-components/src/errors/error/error.component';
import { CustomModalController } from '../../../../../common/ui-components/src/ionic/controllers/custom-modal.controller';
import { Appointment } from '../../../../../essentials/types/src/appointment';
import { MeaUser } from '../../../../../essentials/types/src/chatUser';
import { CONFIG, MeaConfig } from '../../../../../essentials/types/src/mea-config';
import { mergeAvailableAppointmentTypes, sortAppointments } from '../../../../../essentials/util/src/appointment.util';
import { AppointmentTypePipe } from '../../../../../essentials/util/src/pipes/appointment-type.pipe';
import { BerlinTimePipe } from '../../../../../essentials/util/src/pipes/berlin-time.pipe';
import { selectActiveConversation } from '../../../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { CommonState } from '../../../../../store/src/common-store/common.state';
import { selectCognitoId } from '../../../../../store/src/common-store/user-store/selectors/user.selectors';
import { AppointmentConfirmationComponent } from '../appointment-confirmation/appointment-confirmation.component';

dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);

const berlinTime = 'Europe/Berlin';

@Component({
  templateUrl: './appointment-selection.component.html',
  styleUrls: ['./appointment-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonicModule,
    IconButtonComponent,
    ErrorComponent,
    AsyncPipe,
    DatePipe,
    TranslateModule,
    AppointmentTypePipe,
    BerlinTimePipe,
  ],
})
export class AppointmentSelectionComponent implements OnInit {
  sortedAppointments$ = new ReplaySubject<Appointment[]>(1);
  availableAppointmentTypes$ = this.sortedAppointments$.pipe(
    map((sortedAppointments) => Array.from(mergeAvailableAppointmentTypes(sortedAppointments)).sort(sortAppointments))
  );

  groupedAvailableAppointments$: Observable<Dictionary<Appointment[][]>> = combineLatest([
    this.sortedAppointments$,
    this.availableAppointmentTypes$,
  ]).pipe(
    map(([sortedAppointments, availableAppointmentTypes]) =>
      availableAppointmentTypes.reduce((groupedAppointments, appointmentType) => {
        const availableAppointments = sortedAppointments.filter(
          (appointment) =>
            appointment.availableAppointmentTypes && appointment.availableAppointmentTypes.has(appointmentType)
        );
        return {
          ...groupedAppointments,
          [appointmentType]: Object.values(
            groupBy(availableAppointments, (appointment: Appointment) =>
              dayjs(appointment.dateTime).tz(berlinTime).format('YYYY-MM-DD')
            )
          ),
        };
      }, {})
    ),
    shareReplay(1)
  );

  numberOfAvailableAppointmentsInNextTwoWeeks$: Observable<Dictionary<number>> = combineLatest([
    this.sortedAppointments$,
    this.availableAppointmentTypes$,
  ]).pipe(
    map(([sortedAppointments, availableAppointmentTypes]) => {
      const dateTimeInTwoWeeks = dayjs().tz(berlinTime).add(2, 'week');
      const appointmentsWithinTwoWeeks = sortedAppointments.filter(({ dateTime }) =>
        dayjs(dateTime).tz(berlinTime).isSameOrBefore(dateTimeInTwoWeeks, 'day')
      );
      return availableAppointmentTypes.reduce(
        (dictionary, appointmentType) => ({
          ...dictionary,
          [appointmentType]: appointmentsWithinTwoWeeks.filter(
            (appointment) =>
              appointment.availableAppointmentTypes && appointment.availableAppointmentTypes.has(appointmentType)
          ).length,
        }),
        {}
      );
    }),
    shareReplay(1)
  );

  isPharmacy: boolean;

  constructor(
    public modalCtrl: CustomModalController,
    private nav: IonNav,
    private appsyncCommonAppointmentsService: AppsyncCommonAppointmentService,
    private store: Store<CommonState>,
    @Inject(CONFIG) private config: MeaConfig
  ) {
    this.isPharmacy = !config.clientApp;
  }

  async ngOnInit() {
    const pharmacyCognitoId = this.isPharmacy
      ? await firstValueFrom(this.store.select(selectCognitoId))
      : await firstValueFrom(
          this.store
            .select(selectActiveConversation)
            .pipe(map((conversation) => (conversation?.chatPartner as MeaUser)?.cognitoId))
        );

    const availableAppointments = pharmacyCognitoId
      ? await this.appsyncCommonAppointmentsService.getBookableAppointments(pharmacyCognitoId)
      : [];

    this.sortedAppointments$.next(sortBy(availableAppointments, (appointment: Appointment) => appointment.dateTime));
  }

  async selectAppointment(appointment: Appointment, appointmentType: string) {
    await this.nav.push(AppointmentConfirmationComponent, { appointment, appointmentType });
  }

  calculateEndTime(appointment: Appointment) {
    return dayjs(appointment.dateTime).tz(berlinTime).add(appointment.durationMinutes, 'minute').toISOString();
  }

  trackDay(day: Appointment[]) {
    return dayjs(day[0]?.dateTime).tz(berlinTime).format('YYYY-MM-DD');
  }
}
