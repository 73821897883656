import gql from 'graphql-tag';

export default gql`
  mutation deleteTextblock($id: String!) {
    deleteTextblock(id: $id) {
      __typename
      id
      idOfDefaultTextblock
    }
  }
`;
