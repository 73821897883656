import gql from 'graphql-tag';

export default gql`
  mutation updatePharmacyChatUserEncryptedPrivateKey(
    $cognitoId: ID!
    $encryptedPrivateKey: String!
    $encryptionSalt: String!
  ) {
    updatePharmacyChatUser(
      cognitoId: $cognitoId
      user: { encryptedPrivateKey: $encryptedPrivateKey, encryptionSalt: $encryptionSalt }
    ) {
      __typename
      cognitoId
    }
  }
`;
