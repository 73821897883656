import gql from 'graphql-tag';

export default gql`
  query getAllTextblocks {
    getAllTextblocks {
      __typename
      id
      title
      template
      category
      idOfDefaultTextblock
      cognitoId
      isDeactivated
    }
  }
`;
