<div>
  meadirekt ist eine Marke der Sanacorp Pharmahandel GmbH.<br />
  <br />
  <b>Anschrift:</b><br />
  Sanacorp Pharmahandel GmbH<br />
  Semmelweisstraße 4<br />
  82152 Planegg<br />
  <br />
  Telefon 089 8581-0<br />
  Telefax 089 8581-260<br />
  <br />
  <a href="https://www.sanacorp.de" target="_blank" rel="noopener noreferrer">www.sanacorp.de</a><br />
  <a href="mailto:info@sanacorp.de">info&#64;sanacorp.de</a><br />
  <br />
  Ihren technischen Kundensupport erreichen Sie unter:<br />
  <a href="mailto:mea@sanacorp.de">mea&#64;sanacorp.de</a>
  <br />
  <br />
  <br />
  Geschäftsführer:<br />
  Patrick Neuss (Vorsitzender)<br />
  Frank Hennings<br />
  Antje Saalfeld<br />
  Frank Sczesny<br />
  <br />
  Handelsregisternummer:<br />
  HRB 170537 Amtsgericht München<br />
  Umsatzsteueridentifikationsnummer:<br />
  DE256803264<br />
  <br />
  Aufsichtsbehörde: Regierung von Oberbayern<br />
  <a href="https://www.sanacorp.de/grosshandelslizenzen/" target="_blank" rel="noopener noreferrer">
    Verzeichnis der Aufsichtsbehörden und Großhandelserlaubnisse gem. §52a AMG</a
  ><br />
  <br />
  Inhaltlich verantwortlich für redaktionelle Inhalte:<br />
  Manuel Kuhn<br />
  Sanacorp Pharmahandel GmbH<br />
  Semmelweisstraße 4<br />
  82152 Planegg<br />
  Telefon 089 8581-700<br />
  <a href="mailto:kommunikation@sanacorp.de">kommunikation&#64;sanacorp.de</a>

  <h2>Hinweis zum Urheberrecht</h2>
  @if (!isNativeApp) {
    meadirekt verwendet Open-Source-Software. Lizenzinformationen finden Sie
    <a href="https://www.meineapotheke.de/meadirekt-chat/3rdpartylicenses.txt" rel="noopener noreferrer" target="_blank"
      >hier</a
    >.<br />
    <br />
    meadirekt Apothekenanwendung verwendet den Audiosound von freesound: notification1-freesound.wav von Thoribass (<a
      href="https://freesound.org/people/Thoribass"
      target="_blank"
      rel="noopener noreferrer"
      >https://freesound.org/people/Thoribass</a
    >).<br />
    <br />
  }
  meadirekt verwendet Open Sans. Lizenzinformationen finden Sie
  <a href="https://medien.meineapotheke.de/text/OFL.txt" rel="noopener noreferrer" target="_blank">hier</a>.<br />
  <br />
  meadirekt verwendet Daten von GeoNames (<a href="https://www.geonames.org/" target="_blank" rel="noopener noreferrer"
    >https://www.geonames.org/</a
  >). Lizenzinformationen finden Sie
  <a href="https://creativecommons.org/licenses/by/4.0/" rel="noopener noreferrer" target="_blank">hier</a>.
</div>
