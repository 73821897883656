import gql from 'graphql-tag';
import { appointmentAttributes } from '../../../../common/resources/src/graphql/attributes';

export default gql`
  query getMyAppointments($nextToken: String) {
    getMyAppointments(nextToken: $nextToken) {
      __typename
      appointments {
        ${appointmentAttributes}
      }
      nextToken
    }
  }
`;
