import gql from 'graphql-tag';
import { appointmentAttributes } from '../../../../common/resources/src/graphql/attributes';

export default gql`
  subscription createdOrUpdatedAppointment($pharmacyCognitoId: ID!) {
    createdOrUpdatedAppointment(pharmacyCognitoId: $pharmacyCognitoId) {
        ${appointmentAttributes}
    }
  }
`;
