import gql from 'graphql-tag';
import { appointmentAttributes } from '../../../../../common/resources/src/graphql/attributes';

export default gql`
    mutation cancelAppointment($dateTime: AWSDateTime!) {
        cancelAppointment(dateTime: $dateTime) {
            ${appointmentAttributes}
        }
    }
`;
