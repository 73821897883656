import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const APPOINTMENT_NAVIGATION_SERVICE = new InjectionToken<AppointmentNavigationServiceInterface>(
  'APPOINTMENT_NAVIGATION_SERVICE'
);

export interface AppointmentNavigationServiceInterface {
  appointmentExists$: Observable<boolean | undefined>;
  navigateToAppointment(dateTime?: string): Promise<void>;
}
