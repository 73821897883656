import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceWorker } from '@aws-amplify/core';
import { firstValueFrom, from, merge, timer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Logger } from '../../../../essentials/util/src/logger';

const logger = new Logger('ServiceWorkerService');
const PUSH_NOTIFICATION_TIMEOUT = 10000;

@Injectable({
  providedIn: 'root',
})
export class ServiceWorkerService {
  private serviceWorker = new ServiceWorker();
  private pushNotificationPublicKey: string | undefined;
  private serviceWorkerRegistration: ServiceWorkerRegistration | undefined;

  constructor(private router: Router) {}

  public async registerServiceWorkerAndAddEventListener(environment: any) {
    try {
      await this.registerServiceWorker(environment.pushNotificationPublicKey);
      navigator.serviceWorker?.addEventListener('message', (event) => {
        if (event.data && event.data.navigateTo) {
          this.router.navigateByUrl(event.data.navigateTo);
        }
      });
    } catch (err) {
      console.error('Failed to register service worker:', err);
    }
  }

  public async enablePushNotifications(): Promise<PushSubscription | null> {
    logger.info('Trying to enable push notifications');
    const pushNotificationPublicKey = this.pushNotificationPublicKey;
    if (!pushNotificationPublicKey) {
      return null;
    }
    try {
      return (await firstValueFrom(
        merge(
          from(this.serviceWorker.enablePush(pushNotificationPublicKey)).pipe(
            tap((swSub) => logger.info('Push notifications enabled', JSON.stringify(swSub)))
          ),
          timer(PUSH_NOTIFICATION_TIMEOUT).pipe(
            tap(() => logger.info('User did not enable push notifications, timeout.')),
            map(() => null)
          )
        )
      )) as PushSubscription | null;
    } catch (e) {
      if ((e as any)?.message !== 'Registration failed - permission denied') {
        logger.error('Failed to subscribe user to push notifications', e);
      }
      return null;
    }
  }

  getPushSubscriptionFromBrowser(): Promise<PushSubscription | null> | null {
    if ('serviceWorker' in navigator && 'PushManager' in window && this.serviceWorkerRegistration) {
      return this.serviceWorkerRegistration.pushManager.getSubscription();
    } else {
      return null;
    }
  }

  private async registerServiceWorker(pushNotificationPublicKey: string): Promise<void> {
    this.pushNotificationPublicKey = pushNotificationPublicKey;
    const serviceWorkerRegistration = await this.serviceWorker.register('./service-worker.js', './');
    logger.info('Service Worker is registered', serviceWorkerRegistration);
    this.serviceWorkerRegistration = serviceWorkerRegistration as ServiceWorkerRegistration;
  }
}
