import { Component, OnInit, ViewChild } from '@angular/core';
import { IonNav, IonicModule } from '@ionic/angular';
import { AppointmentSelectionComponent } from './appointment-selection/appointment-selection.component';
import { OptionalShadowRootComponent } from '../../../../common/ui-components/src/shadow-dom/optional-shadow-root/optional-shadow-root.component';

@Component({
  selector: 'mea-appointment-selection-nav',
  template: '<mea-optional-shadow-root><ion-nav #nav></ion-nav></mea-optional-shadow-root>',
  standalone: true,
  imports: [OptionalShadowRootComponent, IonicModule],
})
export class AppointmentSelectionNavComponent implements OnInit {
  @ViewChild('nav', { static: true }) nav: IonNav | undefined;

  private readonly rootPage;

  constructor() {
    this.rootPage = AppointmentSelectionComponent;
  }

  async ngOnInit() {
    await this.nav?.setRoot(this.rootPage);
  }
}
