import gql from 'graphql-tag';
import { appointmentAttributes } from '../../../../../common/resources/src/graphql/attributes';

export default gql`
  mutation deleteAppointment($dateTime: AWSDateTime!) {
    deleteAppointment(dateTime: $dateTime) {
        ${appointmentAttributes}
    }
  }
`;
