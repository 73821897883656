import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appointmentType',
  standalone: true,
})
export class AppointmentTypePipe implements PipeTransform {
  transform(value: string): any {
    return value.substring(value.indexOf('_') + 1);
  }
}
