/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AppsyncService, AppsyncServiceClient } from '../../../common/resources/src/services/appsync/appsync.service';
import { Consent } from '../../../essentials/types/src/consent';
import { Logger } from '../../../essentials/util/src/logger';
import getConsentsOrRevocationsForPharmacy from '../graphql/queries/getConsentsOrRevocationsForPharmacy';
import updatedConsentForPharmacy from '../graphql/subscriptions/updatedConsentForPharmacy';

const logger = new Logger('AppsyncUserConsentService');

@Injectable({
  providedIn: 'root',
})
export class AppsyncUserConsentService {
  constructor(private appsyncService: AppsyncService) {}

  async getConsentsForPharmacy(): Promise<Consent[]> {
    const client = await this.appsyncService.getClient();

    let next: string | undefined;
    const consentsOrRevocations: Consent[] = [];
    do {
      const { consents, nextToken } = await this.getPaginatedConsents(client, next);
      consentsOrRevocations.push(...consents);
      next = nextToken;
    } while (next);
    return consentsOrRevocations;
  }

  // ************* Queries *************

  private getPaginatedConsents = async (
    client: AppsyncServiceClient,
    nextToken: string | undefined
  ): Promise<{ consents: Consent[]; nextToken: string | undefined }> => {
    let data;
    try {
      data = (
        await client.query({
          query: getConsentsOrRevocationsForPharmacy,
          variables: { nextToken },
        })
      ).data;
    } catch (err) {
      logger.error('Unexpected error retrieving consents', err);
    }
    if (data) {
      return {
        consents: data.getConsentsOrRevocationsForPharmacy.consents,
        nextToken: data.getConsentsOrRevocationsForPharmacy.nextToken,
      };
    } else {
      return { consents: [], nextToken: undefined };
    }
  };

  // ************* Subscriptions *************

  updatedConsentForPharmacy(pharmacyId: string): Observable<Consent> {
    return from(this.appsyncService.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: updatedConsentForPharmacy,
            variables: { pharmacyId },
          })
          .pipe(map((response) => response.data.updatedConsentForPharmacy))
      )
    );
  }
}
