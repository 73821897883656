import { Injectable } from '@angular/core';
import getConversationIdsByDay from '../../../../../../../../libs/common/resources/src/graphql/queries/getConversationIdsByDay';
import { AppsyncService } from '../../../../../../../../libs/common/resources/src/services/appsync/appsync.service';
import { BackendConversationIdsByDay, ConversationIdsByDay } from '../../../types/conversationIdsByDay';

@Injectable({
  providedIn: 'root',
})
export class AppsyncSettingsService {
  constructor(private appsyncService: AppsyncService) {}

  async fetchConversationsByDay(lastDays: number): Promise<ConversationIdsByDay[]> {
    const client = await this.appsyncService.getClient();

    const { data } = await client.query({
      query: getConversationIdsByDay,
      variables: { lastDays },
    });

    return data.getConversationIdsByDay.map((entry: BackendConversationIdsByDay) => ({
      date: entry.date,
      conversationIds: new Set<string>(entry.conversationIds),
    }));
  }
}
