@if (href) {
  <a class="mea-link {{ type }}" href="{{ href }}">
    @if (icon) {
      <ion-icon [src]="icon | iconName"></ion-icon>
    }
    <div class="mea-link-text">{{ text }}</div>
  </a>
}

@if (innerHtml) {
  <div [innerHtml]="innerHtml"></div>
}

@if (!innerHtml && !href) {
  <div class="mea-link {{ type }}">
    @if (icon) {
      <ion-icon [src]="icon | iconName"></ion-icon>
    }
    <div class="mea-link-text">{{ text }}</div>
  </div>
}
