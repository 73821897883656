import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import isNil from 'lodash-es/isNil';
import { CustomAmplifyService } from '../../../common/resources/src/services/amplify.service';
import { AppsyncGetPharmacyService } from '../../../common/resources/src/services/appsync/appsync-get-pharmacy.service';
import { StorageService } from '../../../common/resources/src/services/storage.service';
import { isPharmacyChatUser, MeaUser } from '../../../essentials/types/src/chatUser';
import { CONFIG, MeaConfig } from '../../../essentials/types/src/mea-config';
import {
  PharmacyLoginDetailsWithCustomerId,
  SignInResult,
  SingleSignOnServiceInterface,
} from '../../../essentials/types/src/service-interfaces/single-sign-on.service.interface';
import { SsoLoginParams } from '../../../essentials/types/src/ssoLoginParams';
import { concatRoutes } from '../../../essentials/util/src/concat-routes.util';
import { PharmacySignInService } from './pharmacy-signin.service';

@Injectable({ providedIn: 'root' })
export class SingleSignOnService implements SingleSignOnServiceInterface {
  isInitialized = false;

  constructor(
    private amplifyService: CustomAmplifyService,
    private keycloakService: KeycloakService,
    private appsyncGetPharmacyService: AppsyncGetPharmacyService,
    private pharmacySignInService: PharmacySignInService,
    private router: Router,
    private storageService: StorageService,
    @Inject(CONFIG) private config: MeaConfig
  ) {}

  async signInWithKeycloak({
    redirectRoute,
    redirectAppRoute,
    clientId,
  }: {
    redirectRoute: string[];
    redirectAppRoute?: string;
    clientId?: string;
  }): Promise<SignInResult> {
    await this.initKeycloak({ redirectRoute, redirectAppRoute, clientId });

    const chatRoles = this.getKeycloakChatRoles();

    if (chatRoles.length > 0) {
      const pharmacies = await this.getPharmaciesFromBackend(chatRoles.map(({ customerId }) => customerId));
      if (pharmacies) {
        return { type: 'pharmacy-selection', pharmacies };
      }
    }
    return { type: 'no-pharmacy' };
  }

  async signIntoSconnectWithKeycloak({
    customerId,
    redirectRoute,
    redirectSconnectRoute,
  }: {
    customerId: string;
    redirectRoute: string[];
    redirectSconnectRoute: string[];
  }): Promise<{ type: 'success' }> {
    if (this.config.featureFlags.logoutOnUserError) {
      await this.storageService.set(`${customerId}_loggedInWithSso`, true);
    }
    await this.initKeycloak({ redirectRoute, clientId: 'sanacorp-connect' });
    await this.router.navigate(redirectSconnectRoute, { state: { customerId } });
    return { type: 'success' };
  }

  async signIntoPharmacyWithKeycloak({
    ssoLoginParams,
    redirectRoute,
    redirectAppRoute,
  }: {
    ssoLoginParams: SsoLoginParams;
    redirectRoute: string[];
    redirectAppRoute?: string;
  }): Promise<SignInResult> {
    if (this.config.featureFlags.logoutOnUserError) {
      await this.storageService.set(`${ssoLoginParams.customerId}_loggedInWithSso`, true);
    }
    await this.initKeycloak({ redirectRoute, redirectAppRoute, ssoLoginParams });
    await this.amplifySignInWithSsoToken(
      ssoLoginParams.customerId,
      await this.keycloakService.getToken(),
      ssoLoginParams.conversationId
    );
    return { type: 'success' };
  }

  async logoutFromKeycloak() {
    if (this.isInitialized) {
      this.resetKeycloakService();
      await this.keycloakService.logout();
    }
  }

  async getKeycloakToken() {
    try {
      return await this.keycloakService.getToken();
    } catch (e) {
      return undefined;
    }
  }

  resetKeycloakService() {
    this.isInitialized = false;
  }

  async isPharmacyLoggedInWithSso(currentUser: MeaUser | null): Promise<boolean> {
    if (currentUser && currentUser.cognitoUsername && isPharmacyChatUser(currentUser)) {
      return (await this.storageService.get(`${currentUser.cognitoUsername}_loggedInWithSso`)) ?? false;
    }
    return false;
  }

  async isSsoSessionExpired() {
    return !(await this.keycloakService.isLoggedIn());
  }

  async initKeycloakOnLogin() {
    if (this.isInitialized) {
      return;
    }
    this.isInitialized = true;

    await this.keycloakService.init({
      config: {
        url: this.config.keycloak?.baseUrl,
        realm: this.config.keycloak?.realm ?? '',
        clientId: 'meadirekt-chat',
      },
      initOptions: {
        onLoad: 'check-sso',
        checkLoginIframe: false,
      },
      shouldAddToken: ({ url }) => {
        const acceptablePaths = [this.config.keycloak?.shouldAddToken];
        return acceptablePaths.some((path) => path && url.includes(path));
      },
    });
  }

  private async amplifySignInWithSsoToken(username: string, token: string, conversationId?: string) {
    let user = await this.amplifyService.auth().signIn(username);
    if (user?.challengeName === 'CUSTOM_CHALLENGE') {
      user = await this.amplifyService.auth().sendCustomChallengeAnswer(user, token);
      await this.pharmacySignInService.retrieveUserFromBackendAndRedirect(user, conversationId);
    }
  }

  private async getPharmaciesFromBackend(customerIds: string[]): Promise<PharmacyLoginDetailsWithCustomerId[]> {
    const pharmacies: (PharmacyLoginDetailsWithCustomerId | undefined)[] = await Promise.all(
      customerIds.map(async (customerId) => {
        try {
          const loginDetails = await this.appsyncGetPharmacyService.getPharmacyLoginDetailsByCustomerId(customerId);
          return { ...loginDetails, customerId };
        } catch {
          return undefined;
        }
      })
    );
    return pharmacies.filter((pharmacy): pharmacy is PharmacyLoginDetailsWithCustomerId => !!pharmacy);
  }

  private getKeycloakChatRoles(): { customerId: string; role: string }[] {
    const roles = this.keycloakService.getUserRoles(true);
    return roles
      .map((role) => role.match(/(?<customerId>.+)_chat_(?<role>.+)/)?.groups as { customerId: string; role: string })
      .filter((role) => !isNil(role));
  }

  private async initKeycloak({
    redirectRoute,
    redirectAppRoute,
    ssoLoginParams,
    clientId = 'meadirekt-chat',
  }: {
    redirectRoute: string[];
    redirectAppRoute?: string;
    ssoLoginParams?: SsoLoginParams;
    clientId?: string;
  }): Promise<void> {
    if (this.isInitialized) {
      return;
    }
    this.isInitialized = true;

    let redirectWebRoute = `${window.location.origin}${concatRoutes(redirectRoute)}`;
    if (ssoLoginParams) {
      const urlParameters = Object.entries(ssoLoginParams)
        .map((entry) => entry.join('='))
        .join('&');
      if (urlParameters.length) {
        redirectWebRoute += `?${urlParameters}`;
      }
    }
    const redirectUri = redirectAppRoute ?? redirectWebRoute;

    await this.keycloakService.init({
      config: {
        url: this.config.keycloak?.baseUrl,
        realm: this.config.keycloak?.realm ?? '',
        clientId,
      },
      initOptions: {
        onLoad: 'login-required',
        redirectUri,
        checkLoginIframe: false,
      },
      shouldAddToken: ({ url }) => {
        const acceptablePaths = [this.config.keycloak?.shouldAddToken];
        return acceptablePaths.some((path) => path && url.includes(path));
      },
    });
  }
}
