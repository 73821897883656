import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import isNil from 'lodash-es/isNil';
import { merge } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import {
  updateArchivedConversation,
  updateConversationChatPartner,
  updateConversationLinkForConversation,
  updateDeletionRecordForConversation,
  updateOpenTicketReminderNotificationForConversation,
  updateTicketHistoryForConversation,
} from '../../../../../store/src/common-store/chat-store/actions/chat-conversation-update.actions';
import { newOrUpdateConversation } from '../../../../../store/src/common-store/chat-store/actions/chat-conversation.actions';
import {
  updatedBackendAppointment,
  updatedBackendShoppingCartInChat,
} from '../../../../../store/src/common-store/chat-store/actions/chat-decryption.actions';
import {
  newBackendMessage,
  newLocalMessage,
  newOwnBackendMessage,
  updateMessage,
} from '../../../../../store/src/common-store/chat-store/actions/chat-message.actions';
import { startSelfTyping } from '../../../../../store/src/common-store/chat-store/actions/chat-typing.actions';
import { CommonState } from '../../../../../store/src/common-store/common.state';
import { selectFrontendSessionId } from '../../../../../store/src/common-store/device-store/selectors/device.selectors';
import BackendUserConversation from '../../../../../essentials/types/src/backendUserConversation';
import FeatureFlags from '../../../../../essentials/types/src/feature-flags';
import { CONFIG } from '../../../../../essentials/types/src/mea-config';
import { ConversationMappingUtil } from '../../../../../essentials/util/src/conversation-mapping.util';
import { Logger } from '../../../../../essentials/util/src/logger';
import { AppsyncConversationService } from '../appsync/appsync-conversation.service';
import { AppsyncMessageService } from '../appsync/appsync-message.service';
import { SubscriptionManagementService } from './subscription-management.service';

const logger = new Logger('ChatSubscriptionService');

@Injectable({
  providedIn: 'root',
})
export class ChatSubscriptionService {
  private config = inject(CONFIG);
  private appsyncConversationService = inject(AppsyncConversationService);
  private appsyncMessageService = inject(AppsyncMessageService);
  private subscriptionManagementService = inject(SubscriptionManagementService);
  private store: Store<CommonState> = inject(Store);

  private readonly featureFlags: FeatureFlags = this.config.featureFlags;

  public startChatSubscriptions(cognitoId: string) {
    logger.info('Starting chat subscriptions for user', cognitoId);

    logger.debug('Watching for conversation updates');
    this.watchForCreatedConversations(cognitoId);
    this.watchUpdatedUserConversation(cognitoId);

    this.watchLocalSentMessages();
    logger.debug('Watching own backend messages');
    this.watchOwnBackendMessages(cognitoId);
    logger.debug('Watching for new messages');
    this.watchNewMessages(cognitoId);
    logger.debug('Watching for message updates');
    this.watchUpdatedSentMessages(cognitoId);
    this.watchUpdatedReceivedMessages(cognitoId);

    if (this.featureFlags.indicateSelfTyping) {
      logger.debug('Watching for self typing');
      this.watchSelfStartedTyping(cognitoId);
    }
    logger.debug('Finished starting chat subscriptions');
  }

  private watchForCreatedConversations(cognitoId: string) {
    const createdConversation$ = merge(
      this.appsyncConversationService.newOwnBackendConversations(cognitoId),
      this.appsyncConversationService.createdConversations(cognitoId)
    ).pipe(
      map((conversations) => (conversations ? conversations.find((c) => c.ownerId === cognitoId) : null)),
      filter((conversation): conversation is BackendUserConversation => !isNil(conversation))
    );

    this.subscriptionManagementService.subscribe('createdConversations', createdConversation$, (conversation) =>
      this.store.dispatch(newOrUpdateConversation({ conversation }))
    );
  }

  private watchUpdatedUserConversation(cognitoId: string) {
    const updatedUserConversation$ = this.appsyncConversationService.updatedUserConversation(cognitoId);

    this.subscriptionManagementService.subscribe(
      'updatedUserConversation',
      updatedUserConversation$,
      (updatedUserConversation: BackendUserConversation) => {
        const {
          conversationId: backendConversationId,
          conversationLink,
          participants,
          conversation,
          ownerId,
          reminderNotification,
          showReminder,
        } = updatedUserConversation;

        const firstSegmentId = conversation?.firstSegmentId;
        const conversationId = firstSegmentId || backendConversationId;

        if (participants && participants.length > 0 && conversationId && ownerId) {
          this.store.dispatch(
            updateConversationChatPartner({
              conversationId,
              chatPartner: ConversationMappingUtil.getChatPartner(updatedUserConversation),
            })
          );
        }

        const deletionRecord = conversation?.deletionRecord;
        if (!isNil(deletionRecord)) {
          this.store.dispatch(
            updateDeletionRecordForConversation({
              conversationId,
              deletionRecord,
            })
          );
        }

        const archivedByEnduser = conversation?.archivedByEnduser;
        if (!isNil(archivedByEnduser)) {
          this.store.dispatch(updateArchivedConversation({ conversationId, archivedByEnduser }));
        }

        const encryptedAppointment = conversation?.encryptedAppointment;
        if (!isNil(encryptedAppointment)) {
          this.store.dispatch(updatedBackendAppointment({ conversationId, encryptedAppointment }));
        }

        const encryptedShoppingCart = conversation?.encryptedShoppingCart;
        if (!isNil(encryptedShoppingCart)) {
          this.store.dispatch(updatedBackendShoppingCartInChat({ conversationId, encryptedShoppingCart }));
        }

        const ticketHistory = conversation?.ticketHistory;
        if (!isNil(ticketHistory)) {
          this.store.dispatch(updateTicketHistoryForConversation({ conversationId, ticketHistory }));
        }

        if (!isNil(reminderNotification)) {
          this.store.dispatch(
            updateOpenTicketReminderNotificationForConversation({ conversationId, reminderNotification, showReminder })
          );
        }

        if (conversationLink) {
          this.store.dispatch(updateConversationLinkForConversation({ conversationId, conversationLink }));
        }
      }
    );
  }

  private watchLocalSentMessages() {
    this.subscriptionManagementService.subscribe(
      'watchLocalSentMessages',
      this.appsyncMessageService.onOwnMessage,
      (message) => {
        this.store.dispatch(newLocalMessage({ message }));
      }
    );
  }

  private watchOwnBackendMessages(cognitoId: string) {
    this.subscriptionManagementService.subscribe(
      'watchOwnBackendMessages',
      this.appsyncMessageService.newOwnBackendMessages(cognitoId),
      (message) => this.store.dispatch(newOwnBackendMessage({ message }))
    );
  }

  private watchNewMessages(cognitoId: string) {
    this.subscriptionManagementService.subscribe(
      'newMessages',
      this.appsyncMessageService.newMessages(cognitoId),
      (message) => this.store.dispatch(newBackendMessage({ message }))
    );
  }

  private watchUpdatedSentMessages(cognitoId: string) {
    this.subscriptionManagementService.subscribe(
      'updatedSentMessages',
      this.appsyncMessageService.updatedSentMessages(cognitoId),
      (message) => this.store.dispatch(updateMessage({ message }))
    );
  }

  private watchUpdatedReceivedMessages(cognitoId: string) {
    this.subscriptionManagementService.subscribe(
      'updatedReceivedMessages',
      this.appsyncMessageService.updatedReceivedMessages(cognitoId),
      (message) => this.store.dispatch(updateMessage({ message }))
    );
  }

  private watchSelfStartedTyping(cognitoId: string) {
    const selfStartedTyping$ = this.appsyncMessageService.selfStartedTyping(cognitoId).pipe(
      withLatestFrom(this.store.select(selectFrontendSessionId)),
      filter(([typing, frontendSessionId]) => typing.frontendSessionId !== frontendSessionId)
    );

    this.subscriptionManagementService.subscribe('watchSelfStartedTyping', selfStartedTyping$, ([typing]) => {
      const timestamp = Date.now();
      this.store.dispatch(startSelfTyping({ conversationId: typing.conversationId, timestamp }));
    });
  }
}
