import gql from 'graphql-tag';

export default gql`
  mutation updatePharmacyCognitoId($pharmacyId: ID!, $cognitoId: ID!) {
    updatePharmacy(pharmacyId: $pharmacyId, pharmacy: { cognitoId: $cognitoId }) {
      __typename
      id
      cognitoId
    }
  }
`;
