import { createSelector } from '@ngrx/store';
import { PharmacySearchState } from './pharmacy-search.state';

const selectPharmacySearchState = (state: { pharmacySearch: PharmacySearchState }) => state.pharmacySearch;

export const selectPharmacySearchTerm = createSelector(
  selectPharmacySearchState,
  (state: PharmacySearchState) => state.searchTerm
);

export const selectPharmacySearchTotalResultsForQuery = createSelector(
  selectPharmacySearchState,
  (state: PharmacySearchState) => state.totalResultsForQuery
);

export const selectPharmacySearchOffset = createSelector(
  selectPharmacySearchState,
  (state: PharmacySearchState) => state.searchResults.length
);

export const selectPharmacySearchResults = createSelector(
  selectPharmacySearchState,
  (state: PharmacySearchState) => state.searchResults
);

export const selectPharmacySearchRequestState = (key: keyof PharmacySearchState['requestStates']) =>
  createSelector(selectPharmacySearchState, ({ requestStates }) => requestStates[key]);
