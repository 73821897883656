<div class="tos" [class.dark]="style === 'dark'">
  @if (showTitle) {
    <h3>ALLGEMEINE NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT</h3>
  }
  @if (userIsSignedIn) {
    <mea-button (buttonClick)="onTosButtonClicked()">
      {{ 'Download' | translate }}
    </mea-button>
  }
  <legal-text type="pharmacy-tos" [colorScheme]="style"></legal-text>
</div>
