import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../essentials/types/src/actionType';
import { Consent } from '../../../../essentials/types/src/consent';

export const loadUserConsents = createAction(`${ActionType.UserConsent}: Load user consents`);
export const loadUserConsentsSuccess = createAction(
  `${ActionType.UserConsent}: Load user consents success`,
  props<{ consents: Consent[] }>()
);
export const setUserConsent = createAction(
  `${ActionType.UserConsent}: Set user consent`,
  props<{ userConsent: Consent }>()
);
export const loadUserConsentsFailure = createAction(`${ActionType.UserConsent}: Load user consents failure`);
