<label>
  <div class="label-text">{{ label }}</div>
  <div class="mea-relative">
    <input
      #input
      [(ngModel)]="value"
      (ngModelChange)="updateValue(value)"
      (blur)="blurred.emit()"
      (focus)="focused.emit()"
      [autocomplete]="autocomplete"
      [disabled]="disabled"
      [maxlength]="maxlength"
      [readonly]="readonly"
      [name]="name"
      [attr.name]="name"
      [ngClass]="[error ? 'error' : '', iconLeft ? 'iconLeft' : '', iconRight ? 'iconRight' : '']"
      [placeholder]="placeholder"
      [type]="type"
      enterkeyhint="go"
    />
    @if (iconLeft) {
      <ion-icon
        (click)="!disabled ? iconClickLeft.emit() : null"
        [class.clickable-icon]="iconClickLeft.observed && !disabled"
        [src]="iconLeft | iconName"
        class="icon-left"
        tappable
      ></ion-icon>
    }
    @if (iconRight) {
      <ion-icon
        (click)="!disabled ? iconClickRight.emit() : null"
        [class.clickable-icon]="iconClickRight.observed && !disabled"
        [src]="iconRight | iconName"
        class="icon-right"
        tappable
      ></ion-icon>
    }
  </div>
  @if (error) {
    <mea-error>{{ error }}</mea-error>
  }
</label>
