import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
@Pipe({
  name: 'berlinTime',
  standalone: true,
})
export class BerlinTimePipe implements PipeTransform {
  transform(dateString: string, format: string): string | null | undefined {
    return dayjs(dateString).tz('Europe/Berlin').format(format);
  }
}
