import { Textblock } from '../../../../../../essentials/types/src/textblock';
import { TextblockCategory } from '../../../../../../essentials/types/src/textblockCategory';

export function getFirstTextblockInList(
  categories: string[] | null,
  categorizedTextblocks: { [category: string]: Textblock[] } | null
): Textblock | null {
  let firstTextblockInList: Textblock | null = null;
  if (categories && categorizedTextblocks) {
    categories.some((category) => {
      const textblocksInCategory = categorizedTextblocks[category];
      if (textblocksInCategory && textblocksInCategory[0]) {
        firstTextblockInList = textblocksInCategory[0];
        return true;
      }
      return false;
    });
  }
  return firstTextblockInList;
}

export function sortAndCategorizeTextblocks(
  textblockCategories: TextblockCategory[],
  textblocks: Textblock[]
): { [category: string]: Textblock[] } {
  textblocks.sort((textblock1, textblock2) => textblock1.title.localeCompare(textblock2.title));

  const categorizedTextblocks: { [category: string]: Textblock[] } = {};

  textblockCategories.forEach((category) => {
    categorizedTextblocks[category] = textblocks.filter((textblock) => textblock.category === category);
  });

  return categorizedTextblocks;
}
