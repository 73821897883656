import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthStateService } from '../../../../../libs/common/resources/src/services/auth-state.service';
import { RouteGuardService } from '../../../../../libs/common/resources/src/services/route-guard.service';
import { AuthState } from '../../../../../libs/essentials/types/src/user-auth.state';
import { isNotNullOrUndefined } from '../../../../../libs/essentials/util/src/rxjs/isNotNullOrUndefined';
import { PharmacySignInService } from '../../../../../libs/pharmacy-only/src/services/pharmacy-signin.service';
import { selectPharmacy } from '../../../../../libs/store/src/common-store/user-store/selectors/user.selectors';
import { PharmacyPage, PharmacyRoute } from '../pharmacy-routes';
import { MeaState } from '../store/state/mea.state';

export const authPageGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  const guardService = inject(RouteGuardService);
  return guardService.routeGuardCheck({
    url: state.url,
    requiredState: AuthState.signedOut,
    defaultRedirectUrl: PharmacyRoute.Home,
  });
};

export const homePageGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  const guardService = inject(RouteGuardService);
  return guardService.routeGuardCheck({
    url: state.url,
    requiredState: AuthState.signedIn,
    defaultRedirectUrl: PharmacyRoute.Auth,
  });
};

export const backupRestorePageGuard: CanActivateFn = async (): Promise<boolean> => {
  const pharmacySignInService = inject(PharmacySignInService);
  const router = inject(Router);
  if (pharmacySignInService.isSigningIn()) {
    return true;
  } else {
    await router.navigate(PharmacyPage.login);
    return false;
  }
};

export const setPasswordGuard: CanActivateFn = (): Observable<boolean> => {
  const authStateService = inject(AuthStateService);
  const router = inject(Router);
  return authStateService.cognitoUser$.pipe(
    map((cognitoUser) => {
      if (!cognitoUser) {
        router.navigate([''], { replaceUrl: true });
      }
      return !!cognitoUser;
    })
  );
};

export const demoPharmacyGuard: CanActivateFn = (): Observable<boolean> => {
  const store: Store<MeaState> = inject(Store);
  const router = inject(Router);
  return store.select(selectPharmacy).pipe(
    isNotNullOrUndefined(),
    map((pharmacy) => {
      if (pharmacy.sanacorpCustomerId.startsWith('demo-')) {
        return true;
      } else {
        router.navigate([''], { replaceUrl: true });
        return false;
      }
    })
  );
};
