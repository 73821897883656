<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <mea-icon-button (buttonClick)="close()" color="light" icon="close"></mea-icon-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  @if (this.subPage === 'legal-info') {
    <div class="help-content">
      <h3>{{ 'LEGAL_AND_HELP_NAV.LEGAL.LEGAL_INFO' | translate }}</h3>
      <legal-text type="legal-info"></legal-text>
    </div>
  }
  @if (this.subPage === 'license-and-tos') {
    <div class="help-content">
      <license-and-tos [userIsSignedIn]="false" [showTitle]="true" [style]="'dark'"></license-and-tos>
    </div>
  }
  @if (this.subPage !== '') {
    <div class="help-footer">
      @if (subPage !== 'legal-info') {
        <a (click)="openSubPage('legal-info')">{{ 'LEGAL_AND_HELP_NAV.LEGAL.LEGAL_INFO' | translate }}</a>
      }
      @if (subPage !== 'license-and-tos') {
        <a (click)="openSubPage('license-and-tos')">{{ 'LEGAL.LICENSE-AND-TOS' | translate }}</a>
      }
    </div>
  }
</ion-content>
