import { inject, Injectable } from '@angular/core';
import { GetObjectCommand, PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { CONFIG } from '../../../../essentials/types/src/mea-config';
import { MimeTypeEnum } from '../../../../essentials/types/src/mimeTypeEnum';
import { Logger } from '../../../../essentials/util/src/logger';
import { CustomAmplifyService } from './amplify.service';

const logger = new Logger('S3Service');

export interface S3File {
  body: string;
  contentType?: MimeTypeEnum;
}

interface BucketConfigOptions {
  name: string;
  baseUrl: string;
}

interface BucketConfig {
  assets: BucketConfigOptions;
}

@Injectable({ providedIn: 'root' })
export class S3Service {
  private config = inject(CONFIG);
  private amplifyService = inject(CustomAmplifyService);

  private readonly defaultPrefix = 'privatePrefix/';
  private bucketConfig: BucketConfig = {
    assets: {
      name: this.config.bucket.aws_user_files_s3_bucket,
      baseUrl: this.config.bucket.aws_user_files_s3_bucket_base_url,
    },
  };

  async doesFileExist(path: string, prefix = this.defaultPrefix): Promise<boolean> {
    try {
      await this.sendCommand(
        new GetObjectCommand({
          Bucket: this.bucketConfig.assets.name,
          Key: prefix + path,
          Range: 'bytes=0-1',
        })
      );
    } catch (e) {
      logger.info('Cannot get file', path, e);
      return false;
    }
    return true;
  }

  async getExternalImage(url: string): Promise<S3File> {
    const path = url.slice(url.lastIndexOf(this.defaultPrefix) + this.defaultPrefix.length);

    return this.getFile(path);
  }

  async getFile(path: string, bucketKey: keyof BucketConfig = 'assets', prefix = this.defaultPrefix): Promise<S3File> {
    const object = await this.sendCommand(
      new GetObjectCommand({
        Bucket: this.bucketConfig[bucketKey].name,
        Key: prefix + path,
      })
    );

    const bodyBuffer = await new Response(object.Body as ReadableStream).arrayBuffer();
    const body = btoa(new Uint8Array(bodyBuffer).reduce((data2, byte) => data2 + String.fromCharCode(byte), ''));
    return { body, contentType: object.ContentType as MimeTypeEnum };
  }

  public async put(key: string, data: Buffer | Blob, config: { contentType: string }, prefix = this.defaultPrefix) {
    await this.sendCommand(
      new PutObjectCommand({
        Bucket: this.bucketConfig.assets.name,
        Key: prefix + key,
        Body: data,
        ContentType: config.contentType,
      })
    );
  }

  public async putPublicFile(
    key: string,
    data: Buffer | Blob,
    config: { contentType: string },
    prefix = this.defaultPrefix
  ): Promise<{ url: string }> {
    const keyInBucket = prefix + key;
    await this.sendCommand(
      new PutObjectCommand({
        ACL: 'public-read',
        Bucket: this.bucketConfig.assets.name,
        Key: keyInBucket,
        Body: data,
        ContentType: config.contentType,
      })
    );
    return { url: `${this.bucketConfig.assets.baseUrl}/${keyInBucket}` };
  }

  private async sendCommand(command: GetObjectCommand | PutObjectCommand) {
    const s3Client = await this.getClient();
    try {
      return await s3Client.send(command);
    } catch (e: any) {
      if (e.Code === 'RequestTimeTooSkewed') {
        return await s3Client.send(command);
      } else {
        throw e;
      }
    }
  }

  private async getClient(): Promise<S3Client> {
    const credentials = await this.amplifyService.auth().currentCredentials();
    const s3Client = new S3Client({
      region: this.config.aws_config.aws_cognito_region,
      credentials,
    });
    s3Client.middlewareStack.add(
      (next, _context) => async (args) => {
        return await next(args).catch((error: any) => {
          if (error.Code === 'RequestTimeTooSkewed') {
            const serverTime = Date.parse(error.ServerTime);
            s3Client.config.systemClockOffset = serverTime - Date.now();
          }
          throw error;
        });
      },
      { step: 'finalizeRequest', tags: ['CORRECT_TIME_SKEW'] }
    );
    return s3Client;
  }
}
