import gql from 'graphql-tag';

export default gql`
  mutation updateCustomAppointmentTypes($cognitoId: ID!, $customAppointmentTypes: [String]) {
    updatePharmacyChatUser(cognitoId: $cognitoId, user: { customAppointmentTypes: $customAppointmentTypes }) {
      __typename
      cognitoId
      customAppointmentTypes
    }
  }
`;
