import { StoreDevtoolsModule } from '@ngrx/store-devtools';

export const environment = {
  name: 'staging',
  production: false,
  clientApp: false,
  frontendType: 'pharmacy',
  keycloak: {
    baseUrl: 'https://staging.login.sanacorp.cloud/auth',
    realm: 'ApothekenStage',
    shouldAddToken: 'staging.login.sanacorp.cloud',
  },
  aws_config: {
    aws_cognito_identity_pool_id: 'eu-central-1:96fd5832-7e21-4902-9a88-555ea3604415',
    aws_cognito_region: 'eu-central-1',
    aws_user_pools_id: 'eu-central-1_C9QvNfePZ',
    aws_mandatory_sign_in: 'disable',
    aws_user_pools_web_client_id: '20lbhtkgbifaolsum31t5gvn0g',
    aws_appsync_graphqlEndpoint: 'https://xyp3w5jjkzbwznj7ol4q4rnsl4.appsync-api.eu-central-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-central-1',
    aws_appsync_authenticationType: 'AWS_IAM',
  },
  bucket: {
    aws_user_files_s3_bucket: 'sanacorp-assets-store-staging',
    aws_user_files_s3_bucket_base_url: 'https://sanacorp-assets-store-staging.s3.eu-central-1.amazonaws.com',
    aws_image_base_url: 'https://d2zpfqfmzdy968.cloudfront.net/apothekenbilder',
  },
  msgCache: {
    enabled: true,
    lifetime: 86400000, // 1 day
  },
  pushNotificationPublicKey: 'BFPQN5rG6rLE40lNAjriQtt_QeTGAdYyzn0XciBScxPTWiNRdRgt1VPSzJvZ7jjyCdXAZqK7lJZ1s_tpVPnHxMI',
  sentryDSN: 'https://3506fed5d6c94f7db903049be35b2e48@sentry.sanacorp.cloud/9',
  animated: true,
  checkForHolidays: true,
  imports: [StoreDevtoolsModule.instrument({ name: 'NgRx frontend-pharmacy', maxAge: 25 })],
};
