import { Action, createReducer, on } from '@ngrx/store';
import update from 'immutability-helper';
import cloneDeep from 'lodash-es/cloneDeep';
import { LoadStatus } from '../../../../essentials/types/src/loadStatus';
import { markDataWithSubscriptionsAsStale } from '../../common-store/other/actions/subscription.actions';
import { clearUserOnLogout, setUserOnLogin } from '../../common-store/user-store/actions/user.actions';
import {
  deleteAppointment,
  loadAppointments,
  loadAppointmentsFailure,
  loadAppointmentsSuccess,
  setAppointment,
} from './appointment.actions';
import { AppointmentState } from './appointment.state';

const initialAppointmentState: AppointmentState = {
  appointments: {},
  appointmentLoadStatus: LoadStatus.Init,
};

const _appointmentReducer = createReducer(
  initialAppointmentState,

  on(loadAppointments, (state) =>
    update(state, {
      appointmentLoadStatus: {
        $set: state.appointmentLoadStatus === LoadStatus.Init ? LoadStatus.LoadingInitial : LoadStatus.Revalidating,
      },
    })
  ),

  on(loadAppointmentsSuccess, (state, { appointments }) => {
    const appointmentsDictionary = appointments.reduce(
      (dict, appointment) => ({ ...dict, [appointment.dateTime]: appointment }),
      {}
    );
    return update(state, {
      appointments: { $set: appointmentsDictionary },
      appointmentLoadStatus: { $set: LoadStatus.UpToDate },
    });
  }),

  on(setAppointment, (state, { appointment }) =>
    update(state, { appointments: { $merge: { [appointment.dateTime]: appointment } } })
  ),

  on(deleteAppointment, (state, { dateTime }) => update(state, { appointments: { $unset: [dateTime] } })),

  on(loadAppointmentsFailure, (state) => update(state, { appointmentLoadStatus: { $set: LoadStatus.Error } })),

  on(markDataWithSubscriptionsAsStale, (state) => {
    if (state.appointmentLoadStatus === LoadStatus.UpToDate) {
      return update(state, { appointmentLoadStatus: { $set: LoadStatus.Stale } });
    } else {
      return state;
    }
  }),

  on(clearUserOnLogout, setUserOnLogin, () => cloneDeep(initialAppointmentState))
);

export function appointmentReducer(state: AppointmentState | undefined, action: Action): AppointmentState {
  return _appointmentReducer(state, action);
}
