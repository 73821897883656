<div>
  <h6>Stand: 01.02.2023</h6>
  <h4>PRÄAMBEL</h4>
  <ol>
    <li>
      Die Sanacorp Pharmahandel GmbH (HRB 170537 Amtsgericht München) (nachfolgend „SANACORP“) bietet als Teil des von
      SANACORP entwickelten Kooperationsmodells &bdquo;<i>mea<sup>®</sup> – meine Apotheke</i>&ldquo; (<a
        href="https://www.meineapotheke.de/"
        >www.meineapotheke.de</a
      >) einen digitalen Apothekenservice bestehend aus verschiedenen Digitalprodukten (nachfolgend
      &bdquo;<strong>MEADIREKT</strong>&ldquo;) an. MEADIREKT richtet sich an stationäre Apotheken und bündelt das
      digitale Angebot an Leistungen zur Unterstützung stationärer Apotheken im Bereich der Werbung, des Marketings und
      des Vertriebs.
    </li>
    <li>
      Im Rahmen dieser allgemeinen Nutzungs- und Lizenzbedingungen (nachfolgend: &bdquo;<strong
        >ALLGEMEINE NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT</strong
      >&ldquo;) werden generelle Regelungen zur Nutzung von MEADIREKT-Produkten und/oder Dienstleistungen<sup>1</sup>
      zwischen SANACORP und der Apothekerin bzw. dem Apotheker<sup>2</sup> (nachfolgend:
      &bdquo;<strong>APOTHEKER</strong>&ldquo;) getroffen. Die ALLGEMEINEN NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT
      bilden dabei den Rahmen für alle aktuellen und zukünftig unter MEADIREKT gebündelten Produkte. Die ALLGEMEINEN
      NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT werden durch die besonderen Nutzungs- und Lizenzbedingungen
      (nachfolgend: &bdquo;<strong>PRODUKTBEZOGENE NUTZUNGS- UND LIZENZBEDINGUNGEN</strong>&ldquo;) ergänzt.
    </li>
    <li>
      Diese ALLGEMEINEN NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT, die jeweils geltenden PRODUKTBEZOGENEN NUTZUNGS- UND
      LIZENZBEDINGUNGEN sowie die separat im Rahmen der Bestellung eines MEADIREKT-Produkts angegebenen Details zu den
      Vertragsparteien, das zu zahlende Entgelt bzw. der Preis, die Vertragslaufzeit und die Produktbeschreibung
      (nachfolgend &bdquo;<strong>VERTRAGSDETAILS</strong>&ldquo;) bilden die vertragliche Grundlage (nachfolgend
      &bdquo;<strong>MEADIREKT PRODUKT VERTRAG</strong>&ldquo;) für die Nutzung des(r) jeweiligen MEADIREKT-Produkts
      bzw. -Dienstleistung.
    </li>
  </ol>
  <p class="footnote">
    <sup>1</sup> MEADIREKT-Produkte und MEADIREKT-Dienstleistungen werden nachfolgend mit MEADIREKT bezeichnet.<br />
    <sup>2</sup> Aus Gründen der einfacheren Lesbarkeit wird im Folgenden auf die geschlechtsneutrale Differenzierung
    (z.B. Apotheker/in) verzichtet. Entsprechende Begriffe gelten im Sinne der Gleichbehandlung für alle Geschlechter.
  </p>
  <h4>&#8544;.&nbsp;Anbieter und Geltungsbereich</h4>
  <ol>
    <li>Anbieter von MEADIREKT ist SANACORP.</li>
    <li>
      MEADIREKT richtet sich ausschließlich an APOTHEKER, welche ihren Sitz in Deutschland haben und/oder über eine nach
      deutschem Recht erteilte Apothekenbetriebserlaubnis i.S.d. Apothekenbetriebsordnung (ApBetrO) verfügen. APOTHEKER
      sind Unternehmer i.S.v. § 14 Abs. 1 des Bürgerlichen Gesetzbuches (BGB)
    </li>
  </ol>
  <h4>&#8545;.&nbsp;Geltung und Ergänzung des jeweils existierenden mea-Kooperationsvertrages</h4>
  <ol>
    <li>
      SANACORP und APOTHEKER haben einen Kooperationsvertrag &bdquo;mea<sup>®</sup> – meine Apotheke&ldquo;
      (nachfolgend: &bdquo;<strong>Kooperationsvertrag</strong>&ldquo;) geschlossen. Gegenstand des
      Kooperationsvertrages ist die Beteiligung des APOTHEKERS an dem durch SANACORP entwickelten Kooperationsmodells.
    </li>
    <li>
      Das Kooperationsmodell beinhaltet insbesondere Unterstützung-, Marketing- und Werbekonzepte, welche die Tätigkeit
      des APOTHEKERS auf Endkundenebene unterstützen und fördern sollen (nachfolgend:
      &bdquo;<strong>mea-Kooperationskonzept</strong>&ldquo;). Im Verhältnis zwischen dem APOTHEKER und SANACORP
      erstreckt sich das mea-Kooperationskonzept auf die in dem Kooperationsvertrag bezeichneten Kooperationsapotheken.
    </li>
    <li>
      Das mea-Kooperationskonzept setzt sich aus sog. mea-Kernleistungen (§ 3 des Kooperationsvertrages) und sog.
      Zusatzleistungen (§ 4 des Kooperationsvertrages) zusammen. Bei den aktuell und/oder zukünftig unter MEADIREKT
      gebündelten Produkten und/oder Dienstleistungen handelt es sich um Zusatzleistungen i.S.v. § 4 des
      Kooperationsvertrages.
    </li>
    <li>
      Zur Klarstellung wird darauf hingewiesen, dass abweichende, entgegenstehende und/oder ergänzende Allgemeine
      Geschäftsbedingungen (AGB) der APOTHEKER im Zusammenhang mit MEADIREKT nur dann und nur insoweit
      Vertragsbestandteil werden, wie SANACORP ihrer Geltung ausdrücklich schriftlich zugestimmt hat.
    </li>
  </ol>
  <h4>&#8546;.&nbsp;Grundlagen von MEADIREKT</h4>
  <ol>
    <li>
      Gegenstand von MEADIREKT ist die Unterstützung des APOTHEKERS im Bereich des digitalen Marketings bzw. im Bereich
      der Nutzung von digitalen Produkten im Apothekenbetrieb. MEADIREKT beschränkt sich dabei auf die schlichte
      Unterstützung des APOTHEKERS. SANACORP wird durch bzw. über MEADIREKT weder direkt noch indirekt auf der
      Endkundenebene zwischen APOTHEKER und Kunden des APOTHEKERS (nachfolgend:
      &bdquo;<strong>ENDKUNDEN</strong>&ldquo;) tätig und nimmt insbesondere keinen Einfluss auf
      <ol class="custom-list-abc">
        <li>die pharmazeutische und/oder pharmakologische Beratung des ENDKUNDEN durch den APOTHEKER,</li>
        <li>den Zugang der APOTHEKER zu ENDKUNDEN und/oder</li>
        <li>
          die Abgabe von apothekenpflichtigen Arzneimitteln bzw. sonstige Produkte aus dem Bereich des
          Apothekensortiments an ENDKUNDEN.
        </li>
      </ol>
    </li>
    <li>
      Produkte, Anwendungen oder Dienstleistungen, welche eine umfassende pharmazeutische bzw. pharmakologische Beratung
      des Endkunden durch den APOTHEKER ganz oder teilweise ersetzen, gehören nicht zum Inhalt bzw. zum Gegenstand von
      MEADIREKT.
    </li>
    <li>
      Grundbedingung für die Nutzung von MEADIREKT-Produkten und/oder Dienstleistungen durch den APOTHEKER ist, dass
      dieser sämtliche im Zeitpunkt der jeweiligen Nutzung gegenüber ENDKUNDEN oder sonstigen Dritten im Rahmen der im
      Territorium der Bundesrepublik Deutschland für den Betrieb von APOTHEKEN, die Abgabe apothekenpflichtiger
      Arzneimittel und/oder sonstiger Produkte sowie für die Ausübung einer pharmazeutischen Tätigkeit geltenden
      rechtlichen und berufs- oder standesrechtlichen Vorgaben achtet und einhält.
    </li>
    <li>
      Die ordnungsgemäße und vertragsgemäße Nutzung von MEADIREKT-Produkten und/oder Dienstleistungen durch den
      APOTHEKER ermöglicht keine Abgabe von apothekenpflichtigen Arzneimitteln, welche nicht in Einklang mit den
      insoweit aktuell geltenden Vorgaben – insbesondere dem Arzneimittelgesetz (AMG), der Apothekenbetriebsordnung
      (ApBetrO) und der für APOTHEKER geltenden Berufsordnungen – steht.
    </li>
    <li>
      MEADIREKT-Produkte und/oder Dienstleistungen sowie hiermit im Zusammenhang stehende Marketing- bzw. Werbemaßnahmen
      werden jeweils so ausgestaltet, dass sie sich auf MEADIREKT-Produkte und/oder Dienstleistungen als solche sowie
      die Kooperation &bdquo;<i>mea<sup>®</sup> – meine Apotheke</i>&ldquo; beziehen.
    </li>
    <li>
      MEADIREKT-Produkte und/oder Dienstleistungen nehmen keinen Einfluss auf die Auswahlmöglichkeiten bzw. die Auswahl
      einer Apotheke durch den Endkunden. Die Auswahl steuert alleine der ENDKUNDE. SANACORP kann deshalb nicht
      gewährleisten, dass die Nutzung von MEADIREKT-Produkten und/oder Dienstleistungen zu einer Begründung neuer
      Kundenverhältnisse führt bzw. eine Bindung von Bestandskunden fördert. Ebenso findet durch SANACORP keine
      Verteilung von potentiellen Kunden an APOTHEKEN statt.
    </li>
  </ol>
  <h4>&#8547;.&nbsp;Pflichten des Apothekers in Zusammenhang mit MEADIREKT</h4>
  <p>Im Zusammenhang mit der Nutzung von MEADIREKT hat der APOTHEKER folgende Verpflichtungen einzuhalten:</p>
  <ol>
    <li>
      <strong>Erlaubnis zum Betrieb einer Apotheke</strong>
      <p>
        MEADIREKT darf ausschließlich durch APOTHEKER genutzt werden, welche über eine gültige und im konkreten
        Einzelfall erforderliche Erlaubnis zum Betrieb einer Apotheke (insb. Apothekenbetriebserlaubnis gem. ApBetrO)
        verfügen. Die Nutzung von MEADIREKT ist auf Kooperationsapotheken i.S.d. Kooperationsvertrages beschränkt. Ob
        und in welchem Umfang der APOTHEKER, Personal in die Nutzung von MEADIREKT einbindet, obliegt seiner
        Entscheidung. Sofern der APOTHEKER Personal in die Nutzung von MEADIREKT einbindet, hat er dieses durch
        geeignete Maßnahmen zu überwachen und insbesondere sicherzustellen, dass die unter Ziff. III. der ALLGEMEINEN
        NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT geregelten Vorgaben eingehalten werden.
      </p>
    </li>
    <li>
      <strong>Keine Nutzung zu apothekenfremden Zwecken </strong>
      <p>
        In der Sache ist die Nutzung von MEADIREKT auf eine apothekenübliche Nutzung beschränkt, welche den
        Kooperationsapotheken dient. Eine Nutzung zu apothekenfremden Zwecken ist untersagt.
      </p>
    </li>
    <li>
      <strong>Umfassende Einhaltung sämtlicher regulatorischer Vorgaben</strong>
      <p>
        Der APOTHEKER ist verpflichtet, im Zusammenhang mit der Nutzung von MEADIREKT zu gewährleisten, dass die im
        konkreten Zeitpunkt und Einzelfall geltenden regulatorischen Vorgaben - insbesondere für den Betrieb einer
        Apotheke (insb. ApoG und ApBetrO), die Abgabe apotheken- und/oder verschreibungspflichtiger Arzneimittel (insb.
        AMG und ApBetrO) und den Bereich der Werbung auf dem Gebiet des Heilwesens - umfassend und ohne jede
        Einschränkung eingehalten werden.
      </p>
      <p>Hierzu gehören insbesondere folgende Aspekte:</p>
      <ul>
        <li>Keine Durchführung einer ärztlichen Beratung / klare Abgrenzung zur ärztlichen Beratung</li>
        <li>Einsatz von pharmazeutischem Fachpersonal für Beratung und Information</li>
        <li>Ordnungsgemäße und umfassende Information der ENDKUNDEN</li>
        <li>Einhaltung der Vorschriften und Rahmenbedingungen zur Abgabe von apothekenpflichtigen Arzneimitteln.</li>
      </ul>
    </li>
    <li>
      <strong>Ordnungsgemäße Erfüllung der Informations- & Beratungspflicht </strong>
      <p>
        Im Zusammenhang mit der Nutzung von MEADIREKT hat der APOTHEKER zu gewährleisten, dass er sämtliche ihm
        obliegende Informations- und Beratungspflichten – insbesondere gem. § 20 Abs. 1 und § 17 Abs. 2a Nr. 7 ApBetrO –
        erfüllt. Dies sichert der APOTHEKER verbindlich zu.
      </p>
      <p>
        Der APOTHEKER sichert insbesondere zu, eine umfassende und ordnungsgemäße Information- und Beratung i.S.d. der
        jeweils geltenden Vorgaben der ApBetrO oder vergleichbarer Regelungen auch in denjenigen Einzelfällen zu
        gewährleisten, in welchen die Nutzung von MEADIREKT durch den APOTHEKER Aspekte beinhalten sollte, welche als
        pharmazeutische Beratung des Endkunden durch den APOTHEKER angesehen werden könnten. Der APOTHEKER garantiert
        auch insoweit, alle erforderlichen Maßnahmen zu ergreifen, um eine ordnungsgemäße, sorgfältige und umfassende
        Information und Beratung des Endkunden zu gewährleisten. Falls erforderlich wird er die gebotene Information und
        Beratung im Rahmen des direkten Kundenkontakts außerhalb von MEADIREKT PRODUKTEN noch einmal nachholen.
      </p>
    </li>
    <li>
      <strong>Aktive Beteiligung an MEADIREKT</strong>
      <p>
        Die unter § 6 des Kooperationsvertrages geregelten Mitwirkungsobliegenheiten gelten auch im Zusammenhang mit der
        Nutzung von MEADIREKT.
      </p>
      <p>
        MEADIREKT lebt von der aktiven Beteiligung der APOTHEKER. Vor diesem Hintergrund ist der APOTHEKER verpflichtet,
        MEADIREKT aktiv zu nutzen.
      </p>
    </li>
    <li>
      <strong>Evaluation von MEADIREKT</strong>
      <p>
        SANACORP plant, MEADIREKT stetig weiterzuentwickeln. Hierzu ist eine Evaluation von MEADIREKT durch die
        Teilnehmer unabdingbar. Der APOTHEKER soll deshalb in regelmäßigen Abständen an Evaluationen teilnehmen und eine
        Weiterentwicklung von MEADIREKT durch SANACORP auch in sonstiger Weise fördern.
      </p>
    </li>
    <li>
      <strong>Information über Maßnahmen (insb. Abmahnungen) durch Dritte</strong>
      <p>
        Falls der APOTHEKER von einem Dritten wegen im Zusammenhang mit MEADIREKT Inhalten abgemahnt oder anderweitig in
        Anspruch genommen wird, ist der APOTHEKER verpflichtet, SANACORP hierüber unverzüglich, spätestens innerhalb von
        2 Werktagen nach eigener Kenntnis der Inanspruchnahme zu informieren. SANACORP ist in diesem Fall berechtigt,
        jedoch nicht verpflichtet, die Rechtsverteidigung für den APOTHEKER zu übernehmen.
      </p>
    </li>
    <li>
      <strong>Dokumentation der Nutzung</strong>
      <p>
        Es obliegt dem APOTHEKER, zu entscheiden, ob und in welchem Umfang der APOTHEKER seine Nutzung von MEADIREKT und
        die einzelnen Bestellungen bzw. Chats insbesondere zum Nachweis seiner Beratungsverpflichtung protokolliert bzw.
        dokumentiert. SANACORP empfiehlt insoweit eine möglichst umfassende und nachvollziehbare Dokumentation. Hierzu
        enthalten die MEADIREKT PRODUKTE Export- bzw. Druckfunktionen.
      </p>
    </li>
  </ol>
  <h4>&#8548;.&nbsp;Haftung und Haftungsbegrenzung</h4>
  <ol>
    <li>
      SANACORP haftet verschuldensabhängig nur bei Vorsatz und grober Fahrlässigkeit sowie bei der fahrlässigen
      Verletzung wesentlicher Vertragspflichten (Kardinalpflichten), deren Erfüllung die ordnungsgemäße Durchführung der
      Ergänzungsvereinbarung erst ermöglicht und auf deren Einhaltung der APOTHEKER regelmäßig vertrauen darf, sowie bei
      Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit. Im Falle einfacher Fahrlässigkeit ist die
      Haftung von SANACORP je Schadensfall begrenzt auf den vorhersehbaren, vertragstypischen Schaden der von einem
      regulären Nutzer für die reguläre (monatliche) Nutzung des konkreten MEADIREKT-Produkts bzw. der konkreten
      MEADIREKT Dienstleistung zu entrichten wäre.
    </li>
    <li>
      Für den Verlust von Daten durch den APOTHEKER im Zusammenhang mit MEADIREKT haftet SANACORP insoweit nicht, als
      der Schaden darauf beruht, dass es der APOTHEKER unterlassen hat, regelmäßige Datensicherungen durchzuführen und
      dadurch sicherzustellen, dass verlorengegangene Daten mit vertretbarem Aufwand wiederhergestellt werden können.
    </li>
    <li>
      Die vorstehenden Regelungen gelten auch zugunsten der gesetzlichen Vertreter, Mitarbeiter und Erfüllungsgehilfen
      von Sanacorp, sofern Ansprüche direkt gegen diese geltend gemacht werden.
    </li>
    <li>Die Vorschriften des Produkthaftungsgesetz (ProdHaftG) bleiben unberührt.</li>
  </ol>
  <h4>&#8549;.&nbsp;Geheimhaltungspflicht</h4>
  <ol>
    <li>
      In Ergänzung zu den unter § 11 des Kooperationsvertrages geregelten Geheimhaltungsverpflichtungen ist der
      APOTHEKER verpflichtet, den Inhalt der ALLGEMEINEN NUTZUNGS- UND LIZENZ-BEDINGUNGEN MEADIREKT und sämtlicher
      PRODUKTBEZOGENER NUTZUNGS- UND LIZENZ-BEDINGUNGEN gegenüber Dritten vertraulich zu behandeln.
    </li>
    <li>
      Klarstellend wird darauf hingewiesen, dass ergänzende bzw. weitergehende Geheimhaltungsverpflichtungen im
      Einzelfall im Rahmen PRODUKTBEZOGENER NUTZUNGS- UND LIZENZBEDINGUNGEN bzw. durch sonstige einzelvertragliche
      Vereinbarungen zwischen SANACORP und dem APOTHEKER begründet werden können.
    </li>
  </ol>
  <h4>&#8550;.&nbsp;Schutz- und Nutzungsrechte</h4>
  <ol>
    <li>
      Sofern und soweit SANACORP dem APOTHEKER im Zusammenhang mit MEADIREKT im Rahmen PRODUKTBEZOGENER NUTZUNGS- UND
      LIZENZ-BEDINGUNGEN und/oder sonstiger einzelvertraglicher Vereinbarungen Schutz- und/oder Nutzungsrechte einräumt,
      dienen die im konkreten Fall jeweils eingeräumten Schutz- und/oder Nutzungsrechte ausschließlich dem Zweck, dem
      APOTHEKER die Nutzung von MEADIREKT zu ermöglichen. Weitere und/oder weitergehende Rechte bzw. Lizenzen werden
      stillschweigend nicht eingeräumt. Hierzu bedarf es einer gesonderten Vereinbarung zwischen SANACORP und dem
      APOTHEKER.
    </li>
    <li>
      In Ergänzung zu § 9 des Kooperationsvertrages ist der APOTHEKER damit einverstanden, dass SANACORP den APOTHEKER
      bzw. die konkreten Kooperationsapotheken im Zusammenhang mit Veröffentlichungen der SANACORP bzw. im Rahmen des
      Internetauftritts von MEADIREKT aufführt. Der APOTHEKER ist weiter damit einverstanden, dass SANACORP das
      individuelle Apothekenlogo und/oder den Namen seiner Kooperationsapotheke für MEADIREKT verwendet. Der APOTHEKER
      sichert zu, dass diese Verwendung des Logos/Namens rechtlich zulässig ist und Rechte Dritter nicht beeinträchtigt.
    </li>
    <li>Auf § 7 und § 9 des Kooperationsvertrages wird hingewiesen.</li>
  </ol>
  <h4>&#8551;.&nbsp;Freistellungen</h4>
  <ol>
    <li>
      Sofern und soweit ein Endkunde (Kunde der APOTHEKE) oder ein sonstiger Dritter einen Anspruch in Bezug auf eine
      Handlung, Information oder Inhalte von Chats aus oder im Zusammenhang mit MEADIREKT gegen SANACORP erhebt, stellt
      der APOTHEKER SANACORP in rechtlich zulässigem Umfang von sämtlichen Haftungen, Schäden, Verlusten und
      Aufwendungen jeglicher Art (incl. angemessener Kosten für anwaltliche Beratung) und sonstige Kosten frei bzw. hält
      SANACORP schadlos, sofern der Anspruch im Zusammenhang mit folgenden Gesichtspunkten steht bzw. erhoben wird:
      <ol class="custom-list-abc">
        <li>
          Verstoß / Verstöße gegen
          <ol class="custom-list-123">
            <li>die ALLGEMEINEN NUTZUNGS- UND LIZENZ-BEDINGUNGEN MEADIREKT</li>
            <li>die jeweils anwendbaren PRODUKTBEZOGENER NUTZUNGS- UND LIZENZBEDINGUNGEN</li>
            <li>sonstige individualvertragliche Vereinbarungen zwischen dem APOTHEKER und SANACORP und/oder</li>
            <li>im Zeitpunkt der Vornahme der Handlung geltendes Recht und/oder</li>
          </ol>
        </li>
        <li>Falschdarstellungen</li>
      </ol>
    </li>
    <li>
      Der APOTHEKER wird in dem von SANACORP geforderten Umfang an der Verteidigung gegen jeden i.S.v. Ziff. VII. 1.
      erhobenen Anspruch eines Endkunden und/oder Dritten mitwirken. Dies sichert der APOTHEKER hiermit ausdrücklich zu.
    </li>
  </ol>
  <h4>&#8552;.&nbsp;Datenschutz</h4>
  <ol>
    <li>
      Die Nutzung bzw. Inanspruchnahme von MEADIREKT durch den APOTHEKER kann datenschutzrechtliche
      Verarbeitungsvorgänge beinhalten. In diesem Zusammenhang ist der APOTHEKER verpflichtet, sämtliche
      datenschutzrechtlichen Anforderungen und Verpflichtungen uneingeschränkt zu erfüllen, welche sich aus und im
      Zusammenhang mit der Nutzung bzw. Inanspruchnahme von MEADIREKT ergeben.
    </li>
    <li>
      Der APOTHEKER sichert die umfassende Einhaltung der jeweils geltenden datenschutzrechtlichen Vorgaben und
      Bestimmungen auch gegenüber SANACORP zu. Der APOTHEKER wird darauf hingewiesen, dass Verstöße gegen
      datenschutzrechtliche Vorgaben und Bestimmungen eine Verpflichtung zur Freistellung von SANACORP i.S.v. Ziff. VII.
      dieser Vereinbarung auslösen können.
    </li>
    <li>
      Im Bereich der Verarbeitung von personenbezogenen Daten des APOTHEKERS im Zusammenhang mit MEADIREKT gelten die
      ALLGEMEINEN DATENSCHUTZHINWEISE FÜR GESCHÄFTSPARTNER DER SANACORP-GRUPPE (
      <a href="https://www.sanacorp.de/content/de/mea/datenschutz_2/index.jsp"
        >https://www.sanacorp.de/content/de/mea/datenschutz_2/index.jsp</a
      >). Die allgemeinen Datenschutzhinweise für Geschäftspartner der Sanacorp-Gruppe regeln allgemeine Grundsätze der
      Datenverarbeitung von Daten, die beispielsweise anlässlich der Vertragsdurchführung oder Geschäftsanbahnung
      erfasst werden. Soweit speziellere Regelungen zum Datenschutz getroffen werden, geltend diese Datenschutzhinweise
      nachrangig.
    </li>
    <li>
      Die Bereitstellung sämtlicher MEADIREKT Produkte erfolgt – soweit nicht in diesen ALLGEMEINEN NUTZUNGS- UND
      LIZENZBEDINGUNGEN MEADIREKT oder PRODUKTSPEZIFISCHEN NUTZUNGS- UND LIZENZBEDINGUNGEN anders geregelt –
      grundsätzlich als Auftragsverarbeitung i.S.v. Art. 28 DSGVO entsprechend der Auftragsverarbeitungsvereinbarung in
      <strong>Anhang 1</strong>, welche integraler Bestandteil dieser ALLGEMEINEN NUTZUNGS- UND LIZENZBEDINGUNGEN
      MEADIREKT ist und hiermit Bestandteil jedes <strong>MEADIREKT PRODUKT VERTRAG</strong> wird. Die Parteien sind
      sich darüber einig, dass die Einbeziehung der Auftragsverarbeitungsvereinbarung (Anhang 1) in diesen Vertrag dem
      Erfordernis des Art. 28 Abs. 9 DSGVO (elektronische Form) gerecht wird.
    </li>
  </ol>
  <h4>&#8553;.&nbsp;Orientierungshilfen und Mustertexte</h4>
  <ol>
    <li>
      SANACORP stellt als unverbindliche Orientierungshilfen Mustertexte bzw. technische Hilfestellung zur Erstellung
      von Rechtstexten, insbesondere AGB und Datenschutztexte zur Verwendung innerhalb der MEADIREKT PRODUKTE zur
      Verfügung.
    </li>
    <li>
      Solche Texte oder ggf. durch Eingabe individueller Angeben zusammengestellte Mustertexte sind unverbindliche
      Hilfestellung. Mit der Bereitstellung von Orientierungshilfen ist keine Garantie für die inhaltliche
      Vollständigkeit und Richtigkeit sowie für die Anwendbarkeit der Orientierungshilfe auf die konkrete Situation des
      APOTHEKERS verbunden. Die Orientierungshilfe ersetzt insbesondere keine Rechtsberatung. Im Zusammenhang mit der
      Bereitstellung von unverbindlichen Orientierungshilfen ist jede Haftung von SANACORP gegenüber dem APOTHEKER
      ausgeschlossen.
    </li>
  </ol>
  <h4>&#8554;.&nbsp;Beendigung bzw. Einstellung von MEADIREKT</h4>
  <ol>
    <li>
      SANACORP ist jederzeit berechtigt, MEADIREKT als solches bzw. einzelne Bestandteile von MEADIREKT einzustellen
      bzw. zu modifizieren. Einer Ankündigung bzw. einer ausdrücklichen Kündigung sowie einer Begründung hierfür bedarf
      es nicht. Sollte ein MEADIREKT-Produkt innerhalb von zwei Jahren nach Vertragsbeginn eingestellt werden, wird eine
      gegebenenfalls geleistete Einrichtungsgebühr (anteilig) erstattet. Mit einer etwaigen Erstattung der
      Einrichtungsgebühr sind sämtliche Ansprüche des APOTHEKERS aufgrund der Einstellung abgegolten. Insbesondere
      erfolgt kein Aufwendungsersatz durch SANACORP.
    </li>
    <li>
      Eine Einstellung von MEADIREKT oder einzelner Bestandteile von MEADIREKT kommt insbesondere in Betracht, wenn (a)
      ein Gericht aus dem Bereich der Zivil- oder Strafgerichtsbarkeit und/oder eine Behörde SANACORP, einzelnen
      Apothekern und/oder Endkunden den Betrieb und/oder die Nutzung von MEADIREKT oder einzelner Teilbereiche von
      MEADIREKT untersagen oder an Auflagen bzw. Bedingungen knüpfen, welche MEADIREKT nicht erfüllt.
    </li>
  </ol>
  <h4>&#8555;.&nbsp;Verfügbarkeit</h4>
  <p>
    Eine ununterbrochene und vollumfängliche Verfügbarkeit von Digitalprodukten kann nach dem derzeitigen Stand der
    Technik nicht gewährleistet werden. SANACORP haftet daher nicht für die ständige und ununterbrochen fehlerfreie
    Verfügbarkeit von MEADIREKT-Produkten. Störungen oder Wartungsarbeiten können die Nutzungsmöglichkeit einschränken
    oder zeitweise unterbrechen. Soweit SANACORP Einfluss auf Unterbrechungen hat (z.B. bei Wartungsarbeiten), ist
    SANACORP bemüht, solche Unterbrechungen möglichst kurz zu halten.
  </p>
  <h4>&#8553;&#8546;.&nbsp;Übertragung</h4>
  <ol>
    <li>
      Sämtliche unter MEADIREKT angebotenen Leistungen sind grundsätzlich nicht übertragbar und dürfen insbesondere nur
      durch den APOTHEKER genutzt werden, welcher einen MEADIREKT PRODUKT VERTRAG abgeschlossen hat.
    </li>
    <li>
      Sanacorp ist berechtigt, sämtliche oder einzelne MEADIREKT PRODUKT VERTRÄGE des APOTHEKERS auf eine andere
      Gesellschaft zu übertragen, sofern es sich hierbei um ein im Sinne der §§ 15 ff. AktG mit der Sanacorp
      Pharmahandel GmbH verbundenes Unternehmen handelt. Eine Zustimmung des APOTHEKERS hierzu ist nicht erforderlich.
    </li>
  </ol>
  <h4>&#8553;&#8547;.&nbsp;Änderung der Nutzungsbedingungen</h4>
  <ol>
    <li>
      SANACORP kann diese ALLGEMEINEN NUTZUNGS- UND LIZENZ-BEDINGUNGEN MEADIREKT sowie die PRODUKTSPEZIFISCHE NUTZUNGS-
      UND LIZENZBEDINGUNGEN jederzeit und ohne Angabe von Gründen ändern.
    </li>
    <li>
      Änderungen werden den APOTHEKERN mindestens 2 Wochen vor Inkrafttreten der Änderungen in Textform mitgeteilt. Ein
      Verweis auf die Internetadressse, unter welcher die aktualisierte Fassung der ALLGEMEINEN NUTZUNGS- UND
      LIZENZBEDINGUNGEN MEADIREKT bzw. der PRODUKTSPEZIFISCHE NUTZUNGS- UND LIZENZBEDINGUNGEN verfügbar sind, ist
      insoweit ausreichend.
    </li>
    <li>
      Wird der geänderten Fassung der ALLGEMEINEN NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT bzw. der PRODUKTSPEZIFISCHE
      NUTZUNGS- UND LIZENZBEDINGUNGEN nicht binnen eines Monats ab Zugang der Nachricht über die Änderung widersprochen,
      gelten die geänderten ALLGEMEINEN NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT bzw. die jeweiligen
      PRODUKTSPEZIFISCHEN NUTZUNGS- UND LIZENZBEDINGUNGEN als akzeptiert.
    </li>
    <li>
      Im Falle eines Widerspruches enden sämtliche Vertragsverhältnisse zwischen dem Apotheker und Sanacorp im Bereich
      MEADIREKT mit Inkrafttreten der geänderten ALLGEMEINEN NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT bzw.
      PRODUKTSPEZIFISCHE NUTZUNGS- UND LIZENZBEDINGUNGEN.
    </li>
  </ol>
  <h4>Anhang I – AVV gemäß Art. 28 DSGVO<br />AUFTRAGSVERARBEITUNGSVEREINBARUNG</h4>
  <ol>
    <li>
      Anwendungsbereich, Gegenstand, Art und Zweck der Auftragsverarbeitung ergeben sich aus dem jeweiligen MEADIREKT
      PRODUKT VERTRAG.
    </li>
    <li>
      Die Art der verwendeten personenbezogenen Daten, die Kategorien der durch die Verarbeitung betroffenen Personen
      ist in dem jeweiligen MEADIREKT PRODUKT VERTRAG konkret beschrieben.
    </li>
    <li>
      Die Erbringung der vertraglich vereinbarten Datenverarbeitung findet ausschließlich in einem Mitgliedsstaat der
      Europäischen Union oder in einem anderen Vertragsstaat des Abkommens über den Europäischen Wirtschaftsraum statt.
      Jede Verlagerung in ein Drittland bedarf der vorherigen Zustimmung des APOTHEKERS und darf nur erfolgen, wenn die
      besonderen Voraussetzungen der Artt. 44 ff. DSGVO erfüllt sind.
    </li>
    <li>
      <strong>Datensicherheit</strong><br /><br />
      SANACORP trifft angemessene technisch-organisatorische Maßnahmen nach dem Stand der Technik i.S.d. Art. 32 DSGVO.
      Auf Anfrage stellt SANACORP dem APOTHEKER eine Übersicht über die getroffenen technisch-organisatorischen
      Maßnahmen bereit.<br /><br />

      Die technischen und organisatorischen Maßnahmen unterliegen dem technischen Fortschritt und der Weiterentwicklung.
      Insoweit ist es SANACORP gestattet, alternative adäquate Maßnahmen umzusetzen. Dabei darf das Sicherheitsniveau
      der festgelegten Maßnahmen nicht unterschritten werden.<br /><br />

      SANACORP darf die Daten, die im Auftrag verarbeitet werden, grundsätzlich nur nach dokumentierter Weisung des
      APOTHEKER berichtigen, löschen oder deren Verarbeitung einschränken. Soweit eine betroffene Person sich
      diesbezüglich unmittelbar an den SANACORP wendet, wird der SANACORP dieses Ersuchen an den APOTHEKER weiterleiten.
    </li>
    <li>
      <strong>Sanacorp gewährleistet die Einhaltung folgender Vorgaben:</strong><br /><br />
      <ol class="custom-list-abc">
        <li>
          Schriftliche Bestellung eines Datenschutzbeauftragten, der seine Tätigkeit gemäß Art. 38 und 39 DSGVO ausübt.
        </li>
        <li>
          Die Wahrung der Vertraulichkeit gemäß Artt. 28 Abs. 3 S. 2 lit. b, 29, 32 Abs. 4 DSGVO. SANACORP setzt bei der
          Verarbeitung personenbezogener Daten nur Beschäftigte ein, die auf die Vertraulichkeit verpflichtet wurden.
        </li>
        <li>
          SANACORP verarbeitet personenbezogene Daten ausschließlich auf schriftliche Weisung des APOTHEKERS, es sei
          denn, dass er gesetzlich zur Verarbeitung verpflichtet ist.
        </li>
        <li>
          SANACORP unterstützt, unter Berücksichtigung der ihm zur Verfügung stehenden Mittel und in Abhängigkeit der
          Verhältnismäßigkeit und Angemessenheit des konkreten Einzelfalls, den APOTHEKER bei der Erfüllung der
          Pflichten aus Kapitel 3 der DSGVO (Betroffenenrechte).
        </li>
        <li>
          SANACORP unterstützt, unter Berücksichtigung der ihm zur Verfügung stehenden Mittel und in Abhängigkeit der
          Verhältnismäßigkeit und Angemessenheit des konkreten Einzelfalls, den APOTHEKER bei der Einhaltung der in den
          Artikeln 32 bis 36 DSGVO genannten Pflichten
        </li>
        <li>
          Für Unterstützungsleistungen, die nicht in der Leistungsbeschreibung enthalten oder nicht auf ein
          Fehlverhalten von SANACORP zurückzuführen sind, kann der SANACORP eine Vergütung beanspruchen.
        </li>
      </ol>
    </li>
    <li>
      <strong>Unterauftragsverhältnisse</strong><br /><br />

      Unter Unterauftragsverhältnisse im Sinne dieser Regelung sind solche Dienstleistungen zu verstehen, die sich
      unmittelbar auf die Erbringung der Hauptleistung beziehen. Nicht hierzu gehören Nebenleistungen, die SANACORP z.B.
      als Telekommunikationsleistungen, Post-/ Transportdienstleistungen, Wartung und Benutzerservice oder im Bereich
      der Entsorgung von Datenträgern sowie sonstige Maßnahmen zur Sicherstellung der Vertraulichkeit, Verfügbarkeit,
      Integrität und Belastbarkeit der Hard- und Software von Datenverarbeitungsanlagen in Anspruch nimmt.<br /><br />

      Eine Liste der aktuellen Unterauftragnehmer von SANACORP wird auf Anfrage des APOTHEKERS bereitgestellt. Der
      APOTHEKER ist mit dem Einsatz der eingesetzten Unterauftragnehmer einverstanden Bei der Hinzuziehung neuer oder
      weiterer Unterauftragnehmer informiert SANACORP den APOTHEKER. Der APOTHEKER kann in diesem Fall Einspruch
      erheben.<br /><br />

      Erbringt der Unterauftragnehmer die vereinbarte Leistung außerhalb der EU/des EWR stellt SANACORP die
      datenschutzrechtliche Zulässigkeit durch entsprechende Maßnahmen sicher. Gleiches gilt, wenn Dienstleister zur
      Erfüllung von Nebenleistungen i.S.v. Ziff. 6 Abs. 1 eingesetzt werden sollen.<br /><br />

      Eine weitere Auslagerung durch den Unterauftragnehmer bedarf der ausdrücklichen Zustimmung des Hauptauftragnehmers
      (mind. Textform); sämtliche vertraglichen Regelungen in der Vertragskette sind auch dem weiteren
      Unterauftragnehmer aufzuerlegen.
    </li>
    <li>
      <strong>Kontrollrechte des APOTHEKERS</strong><br /><br />

      Der APOTHEKER hat das Recht, im Benehmen mit SANACORP Überprüfungen durchzuführen oder durch im Einzelfall zu
      benennende Prüfer durchführen zu lassen. Der APOTHEKER hat hierbei das Recht, sich durch Stichprobenkontrollen,
      die rechtzeitig anzumelden sind, zu Geschäftszeiten von der Einhaltung dieser Vereinbarung durch SANACORP in
      dessen Geschäftsbetrieb zu überzeugen.<br /><br />

      Für die Ermöglichung von Kontrollen durch den APOTHEKER kann SANACORP einen Vergütungsanspruch geltend machen.
    </li>
    <li>
      <strong>Weisungsbefugnis des APOTHEKERS</strong><br /><br />

      Weisungen sollen grundsätzlich, soweit SANACORP die technische Möglichkeit hierzu geschaffen hat, durch Nutzung
      der der Administrationsfunktionen im jeweiligen Produktbackend (z.B. Löschen Funktion) ausgeübt werden.
    </li>
    <li>
      <strong>Mündliche Weisungen bestätigt der APOTHEKER unverzüglich (mind. Textform).</strong><br /><br />

      SANACORP hat den APOTHEKER unverzüglich zu informieren, wenn SANACORP der Meinung ist, eine Weisung verstoße gegen
      Datenschutzvorschriften. SANACORP ist berechtigt, die Durchführung der entsprechenden Weisung solange auszusetzen,
      bis sie durch den APOTHEKER bestätigt oder geändert wird.
    </li>
    <li>
      <strong>Löschung und Rückgabe von personenbezogenen Daten</strong><br /><br />
      Nach Abschluss der vertraglich vereinbarten Arbeiten oder früher nach Aufforderung durch den APOTHEKER –
      spätestens mit Beendigung des konkreten MEADIREKT PRODUKT VERTRAG – hat SANACORP sämtliche im Rahmen der
      Auftragsverarbeitung in seinen Besitz gelangten Unterlagen, erstellte Verarbeitungs- und Nutzungsergebnisse sowie
      Datenbestände, die im Zusammenhang mit dem Auftragsverhältnis stehen, dem APOTHEKER auszuhändigen oder nach
      vorheriger Zustimmung datenschutzgerecht zu vernichten.
    </li>
  </ol>
  <h3>PRODUKTSPEZIFISCHE NUTZUNGS- UND LIZENZBEDINGUNGEN<br />MEADIREKT – CHAT</h3>
  <h4>Stand: 28.09.2021</h4>
  <h4>PRÄAMBEL</h4>
  <ol>
    <li>
      SANACORP bietet APOTHEKEN im Rahmen von MEADIREKT eine WebApplikation als Kommunikationsmittel zwischen dem
      APOTHEKER und Endkunden (nachfolgend &bdquo;<strong>MEADIREKT–CHAT</strong>&ldquo;) an. MEADIREKT-CHAT ist aktuell
      ausschließlich als WebApplikation (aktuell:
      <a href="https://www.meineapotheke.de/meadirekt-chat">https://www.meineapotheke.de/meadirekt-chat</a>) konzipiert.
      Nachfolgend werden produktspezifische Aspekte der Nutzung des MEADIREKT CHATS durch den APOTHEKER geregelt.
    </li>
    <li>
      Diese PRODUKSPEZIFISCHEN NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT-CHAT stellen zusammen mit ALLGEMEINEN NUTZUNGS-
      UND LIZENZBEDINGUNGEN MEADIREKT sowie den separat im Rahmen der Bestellung des MEADIREKT CHAT angegebenen Details
      zu den Vertragsparteien, dem Preis, der Vertragslaufzeit, der Produktbeschreibung und dem Leistungsumfang
      (nachfolgend &bdquo;<strong>VERTRAGSDETAILS</strong>&ldquo;) die vertragliche Grundlage (nachfolgend
      &bdquo;<strong>MEADIREKTCHAT-VERTRAG</strong>&ldquo;) für die Nutzung des MEADIREKT-CHATS dar.
    </li>
  </ol>
  <h4>&#8544;.&nbsp;Funktion und Grundlagen von MEADIREKT-CHAT</h4>
  <ol>
    <li>
      Ziel von MEADIREKT-CHAT ist die Erleichterung der Kommunikation zwischen dem APOTHEKER und Endkunden.
      MEADIREKT-CHAT beschränkt sich dabei auf die schlichte Erleichterung der Kommunikation bzw. die Erweiterung der in
      Betracht kommenden Kommunikationsmittel.
    </li>
    <li>
      Anwendungen, welche eine umfassende pharmazeutische bzw. pharmakologische Beratung des Endkunden durch den
      APOTHEKER ganz oder teilweise ersetzen, gehören nicht zum Inhalt bzw. zum Gegenstand von MEADIREKT-CHAT.
      Grundbedingung für die Nutzung von MEADIREKT-CHAT durch den APOTHEKER ist, dass dieser sämtliche im Zeitpunkt der
      jeweiligen Nutzung gegenüber Endkunden oder sonstigen Dritten im Rahmen der im Territorium der Bundesrepublik
      Deutschland für den Betrieb von Apotheken, die Abgabe apothekenpflichtiger Arzneimittel und/oder sonstiger
      Produkte sowie für die Ausübung einer pharmazeutischen Tätigkeit geltenden rechtlichen und berufs- oder
      standesrechtlichen Vorgaben achtet und einhält.
    </li>
    <li>
      Die ordnungsgemäße Nutzung von MEADIREKT-CHAT durch den APOTHEKER ermöglicht keine Abgabe von apothekenpflichtigen
      Arzneimitteln, welche nicht in Einklang mit den insoweit aktuell geltenden Vorgaben – insbesondere dem
      Arzneimittelgesetz (AMG), der Apothekenbetriebsordnung (ApBetrO) und der für APOTHEKER geltenden Berufsordnungen –
      steht.
    </li>
    <li>
      Zur Klarstellung wird darauf hingewiesen, dass sich SANACORP im Zusammenhang mit der Zusatzleistung
      MEADIREKT-CHAT, welche Gegenstand dieser PRODUKSPEZIFISCHEN NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT - CHAT ist,
      auf die Bereitstellung von MEADIREKT-CHAT als zusätzlichem Kommunikationsmedium und ergänzende Marketingmaßnahmen
      beschränkt. Marketings- bzw. Werbemaßnahmen werden jeweils so ausgestaltet, dass sie sich auf das Produkt
      MEADIREKT-CHAT als solches sowie die Kooperation &bdquo;<i>mea<sup>®</sup> – meine Apotheke</i>&ldquo; beziehen.
      Die im Einzelnen beteiligten Apotheken können und werden durch SANACORP nicht beworben. Weiter obliegt die Auswahl
      der Apotheke für einen Chat dem Kunden. SANACORP kann deshalb nicht gewährleisten, dass MEADIREKT-CHAT zu einer
      Begründung neuer Kundenverhältnisse führt bzw. eine Bindung von Bestandskunden fördert. Ebenso findet durch
      SANACORP keine Verteilung von potentiellen Kunden an Apotheken statt.
    </li>
    <li>
      Zur Kommunikation über MEADIREKT-CHAT stehen dem ENDKUNDEN diverse APOTHEKEN zur Verfügung, welche sich an
      MEADIREKT-CHAT beteiligen. Der ENDKUNDE wählt aus, mit welcher APOTHEKE er chatten bzw. kommunizieren möchte. Er
      hat die Möglichkeit, gezielt nach einer APOTHEKE zu suchen bzw. sich nach bestimmten Paramatern (Umkreissuche,
      PLZ-Suche) APOTHEKEN anzeigen zu lassen, welche sich an MEADIREKT-CHAT beteiligen. Die Hoheit und die Entscheidung
      über die Auswahl der APOTHEKE, mit welcher er kommuniziert bzw. chattet, liegt stets und zu jedem Zeitpunkt beim
      ENDKUNDEN. Nur er bestimmt, mit welcher APOTHEKE er kommuniziert und welche APOTHEKE er zur Abgabe von
      Arzneimitteln, Medizinprodukten oder sonstigen Produkten aufsucht bzw. auswählt.
    </li>
    <li>
      Typische Anwendungsbereiche von MEADIREKT-CHAT sind die Abfrage der Verfügbarkeit bestimmter Arzneimittel bzw.
      sonstiger Produkte aus dem Bereich des Sortiments einer APOTHEKE (Arzneimittel und sonstige Produkte),
      Vorbestellungen und Reservierungen sowie eine Kommunikation zu Fragestellungen (im Nachgang) zur Abgabe von
      Arzneimitteln (Wechsel- und Nebenwirkungen, Haltbarkeit, Aufbewahrung, Dosierung etc.). Die Anwendung bzw.
      Verwendung von MEADIREKT-CHAT zu anderen privaten bzw. gewerblichen Zwecken ist untersagt.
    </li>
    <li>
      Die Kommunikation über MEADIREKT-CHAT kann im Wege von Text- und/oder Sprachnachrichten und bei Hinzubuchung einer
      entsprechenden Option über Videobild erfolgen. Zudem kann der Endkunde dem APOTHEKER Bilddokumente und/oder
      Textdokumente (z.B. Rezepte) übermitteln. Als Webapplikation kann MEADIREKT-CHAT über jeden PC mit
      Internetanschluss bzw. über mobile Endgeräte (Smartphones, Tablets) genutzt werden.
    </li>
    <li>
      Lediglich zur Klarstellung wird darauf hingewiesen, dass sich SANACORP auf die Bereitstellung und den Betrieb von
      MEADIREKT-CHAT als Kommunikationsplattform beschränkt und in den Prozess der Bestellung bzw. Abgabe von
      apothekenpflichtigen Arzneimitteln und/oder sonstigen apothekenüblichen Produkten nicht eingebunden ist.
    </li>
    <li>
      Die Kommunikation im Rahmen von MEADIREKT-CHAT findet ausschließlich zwischen dem ENDKUNDEN und der jeweiligen
      APOTHEKE statt. SANACORP ist hierin weder direkt noch indirekt eingebunden. In dem Betrieb von MEADIREKT-CHAT
      liegt insbesondere keine Vermittlung von pharmazeutischen Beratungsleistungen, ENDKUNDEN oder Kaufverträgen über
      Arzneimittel bzw. sonstige Produkte aus dem Bereich des Apothekensortiments.
    </li>
    <li>
      Vertragliche Verpflichtungen im Zusammenhang mit der Abgabe bzw. dem Verkauf von Arzneimitteln bzw. sonstiger
      Produkte bzw. über sonstige Beratungs- und/oder Dienstleistungen aus dem Bereich der Apotheken werden – je nach
      konkretem Einzelfall – ausschließlich zwischen dem APOTHEKER und dem ENDKUNDEN begründet. Insoweit sind die
      konkret zwischen dem APOTHEKER und dem ENDKUNDEN getroffenen Vereinbarungen maßgeblich.
    </li>
    <li>
      Weitere Einzelheiten zur Funktionsweise und zu den Grundlagen von MEADIREKT-CHAT ergeben sich aus den
      Schulungsunterlagen für MEADIREKT-CHAT.
    </li>
    <li>
      <strong>VIDEOCHAT Funktion</strong>
      <ol>
        <li>
          Als Erweiterung von MEADIREKT-CHAT bietet SANACORP die Möglichkeit der Aktivierung eines Videochat-Modus
          (nachfolgendend &bdquo;<strong>VIDEOCHAT</strong>&ldquo;) im Rahmen des MEADIREKT-CHAT zu nachfolgenden
          ergänzenden Bedingungen an.
        </li>
        <li>
          Durch die VIDEOCHAT-Erweiterung wird es dem Apotheker ermöglicht, durch Aktivierung seiner entsprechenden
          Hardware - welche nicht vom Leistungsumfang erfasst ist - ein Videobild dem ENDKUNDEN zugänglich zu machen und
          Videobilder des Kunden zu empfangen.
        </li>
        <li>
          Der APOTHEKER stellt sicher, dass er den Markenauftritt von &bdquo;<i>mea<sup>®</sup> – meine Apotheke</i
          >&ldquo; im Rahmen der Verwendung des VIDEOCHAT bestmöglich fördert und wird ggf. hierzu übermittelte
          Leitlinien beachten. Diese können insbesondere die Verwendung von &bdquo;<i
            >mea<sup>®</sup> – meine Apotheke</i
          >&ldquo; Markenzeichen im Hintergrund beinhalten.
        </li>
        <li>Die VIDEOCHAT Erweiterung ist kostenlos und muss durch separate Buchung aktiviert werden.</li>
        <li>Die VIDEOCHAT Erweiterung kann jederzeit wieder abbestellt werden.</li>
        <li>
          SANACORP weist darauf hin, dass das ANGEBOT einer VIDEOCHAT Funktion die Anpassung bzw. die Ergänzung von
          Datenschutzinformationen gegenüber Kunden zur Folge hat. Entsprechende Orientierungshilfen werden durch
          SANACORP angeboten.
        </li>
        <li>
          SANACORP gewährleistet eine Verfügbarkeit des VIDEOCHAT von mindestens 98% (Service Level) im Jahresmittel. In
          die Berechnung des Service Levels werden geplante und rechtzeitig angekündigte Wartungsarbeiten nicht
          einbezogen.
        </li>
        <li>
          Die Auswertung oder Aufzeichnung von Audio- und wahlweise Bild- oder Videosignalen ist nicht gestattet. Es
          wird darauf hingewiesen, dass ein unautorisierter Mitschnitt gegen § 201 StGB verstoßen kann.
        </li>
      </ol>
    </li>
    <li>
      <strong>CHAT WIDGET</strong>
      <ol>
        <li>
          Als Bestandteil von MEADIREKT-CHAT bietet SANACORP eine technische Lösung zur Einbindung eines Chat-Moduls auf
          apothekeneigenen Webseiten (nachfolgendend „<strong>CHAT WIDGET</strong>“) an.
        </li>
        <li>
          Soweit nichts abweichend geregelt ist, finden die Bedingungen für den MEADIREKT-CHAT mit Ausnahme von
          Regelungen betreffend apothekenübergreifender Nutzung und Regelungen betreffend Datenverarbeitungsvorgängen
          durch SANACORP als Verantwortlicher (Ziff. II Nr. 4.1. und 4.2. sowie VII. Nr. 1. und 5.) auf das CHAT WIDGET
          Anwendung.
        </li>
        <li>
          Das CHAT WIDGET wird in einer einheitlichen Version für alle Apotheker „as is“ zur Verfügung gestellt. Eine
          Anpassung des Codes oder Designs ist ausdrücklich nicht geschuldet. Für die Implementierung/Installation und
          Wartung des CHAT WIDGET auf apothekereigenen Webseiten ist SANACORP nicht verantwortlich und übernimmt keine
          Gewähr für die ordnungsgemäße Funktionsweise auf solchen . SANACORP stellt ausschließlich Dateien bzw. Code
          und die technische Infrastruktur zum Betrieb des CHAT WIDGET zur Verfügung. SANACORP ist bei der Erbringung
          von Leistungen im Zusammenhang mit dem CHAT WIDGET ausschließlich als Auftragsverarbeiter im Sinne von Art. 28
          DGSVO tätig. Eine Verarbeitung durch Sanacorp als Verantwortlicher im Sinne von Art. 4 Nr. 7 DSGVO erfolgt
          nicht.
        </li>
        <li>
          Apothekenübergreifende Funktionen des MEADIREKT-CHAT sind im Rahmen des CHAT WIDGET nicht verfügbar und werden
          auch nicht innerhalb des Moduls verlinkt.
        </li>
      </ol>
    </li>
  </ol>
  <h4>&#8545;.&nbsp;Zur Nutzung von MEADIREKT-CHAT</h4>
  <ol>
    <li>
      <strong>Erreichbarkeit bzw. Zugang zu MEADIREKT-CHAT</strong><br />
      <ol>
        <li>
          Der ENDKUNDE kann MEADIREKT-CHAT insbesondere über die Website
          <a href="https://www.meineapotheke.de/meadirekt-chat">https://www.meineapotheke.de/meadirekt-chat</a> bzw.
          über individuelle QR-Codes der beteiligten Apotheken erreichen. Die Website kann über einen internetfähigen PC
          bzw. ein mobiles Endgerät mit Internetzugang (Tablet bzw. Smartphone) erreicht werden.
        </li>
        <li>
          Zur Nutzung von MEADIREKT-CHAT über einen PC oder ein mobiles Endgerät müssen folgende Systemvoraussetzungen
          erfüllt sein:
          <ol>
            <li>
              Geräte
              <ul>
                <li>Geräte mit Internetzugang und einem unterstützten Browser</li>
              </ul>
            </li>
            <li>
              Browser
              <ul>
                <li>Chrome, Safari, Edge in der jeweils aktuellen Version</li>
              </ul>
            </li>
            <li>
              Betriebssysteme
              <ul>
                <li>Windows 7 oder neuer</li>
                <li>Android 8 oder neuer</li>
                <li>iOS 14.1 oder neuer</li>
                <li>macOS 10.14 oder neuer</li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          Der APOTHEKER wird darauf hingewiesen, dass sich die Systemvoraussetzungen zu gegebener Zeit ändern können
          bzw. die Nutzung von MEADIREKT-CHAT die Installation von zusätzlichen Software-Komponenten (ggf. auch von
          Drittanbietern) erforderlich machen kann. Der ENDKUNDE kann jeweils die aktuellste Version von MEADIREKT-CHAT
          nutzen. Ein Anspruch bzw. eine Möglichkeit zur Nutzung von MEADIREKT-CHAT in Vorgängerversionen besteht nicht.
        </li>
      </ol>
    </li>
    <li>
      <strong>Keine Garantie der Verfügbarkeit bzw. Nutzbarkeit von MEADIREKT-CHAT</strong>
      <ol>
        <li>
          Es ist beabsichtigt, MEADIREKT-CHAT als Kommunikationsplattform zwischen APOTHEKERN und ENDKUNDEN
          grundsätzlich an 365 Kalendertagen im Jahr über 24 Stunden zur Verfügung zu stellen. Eine ununterbrochene bzw.
          lückenlose (technische) Verfügbarkeit kann jedoch nicht garantiert werden.<br />
          Dies gilt insbesondere auch in Bezug auf Umstände, welche nicht in den Verantwortungsbereich von SANACORP bzw.
          der beteiligten APOTHEKEN fallen und für die Nichtverfügbarkeit ursächlich sind (z.B. Stromausfall, Fehler
          bzw. Diskonnektivität im Datenübertragungsnetz, Störungen im Bereich der Hardware von SANACORP bzw. den
          beteiligten APOTHEKEN).
        </li>
        <li>
          SANACORP beschränkt sich auf die Bereitstellung und den Betrieb von MEADIREKT-CHAT als
          Kommunikationsplattform. Ob und in welchem Umfang sich APOTHEKEN für eine Nutzung von MEADIREKT-CHAT im
          Verhältnis zu ENDKUNDEN entscheiden, kann SANACORP nicht beurteilen. Über die generelle Nutzung, die Nutzung
          im Einzelfall im Verhältnis zu bestimmten ENDKUNDEN, die Art und den Umfang der generellen Nutzung bzw. der
          Nutzung im Einzelfall entscheidet ausschließlich die APOTHEKE.
        </li>
        <li>
          Nichtverfügbarkeiten i.S.v. Ziff. 2.1. und Nichterreichbarkeiten i.S.v. Ziff. 2.2 stellen keine Be- oder
          Einschränkung des vertraglich vereinbarten Leistungsumfanges dar. Gleiches gilt für notwendige bzw. für
          notwendig erachtete Wartungsarbeiten bei SANACORP bzw. bei den teilnehmenden APOTHEKERN. Sofern
          Wartungsarbeiten vorhersehbar sind, werden diese im Regelfall gegenüber den beteiligten APOTHEKERN
          angekündigt.
        </li>
        <li>
          Lediglich zur Klarstellung wird darauf hingewiesen, dass der APOTHEKER keinen Anspruch auf folgende Leistungen
          hat:
          <ol>
            <li>
              Anpassung von MEADIREKT-CHAT an eine geänderte Hard- oder Softwareumgebung einschließlich der Anpassung an
              veränderte Betriebssysteme,
            </li>
            <li>Anpassung von MEADIREKT-CHAT an gesetzliche, behördliche oder hoheitliche Anforderungen,</li>
            <li>
              Beseitigung von Fehlern aus dem Risikobereich der APOTHEKEN – hierzu gehören insbesondere Fehler, welche
              verursacht wurden,
              <ol class="custom-list-abc">
                <li>durch unsachgemäße Bedienung oder Veränderung der Webapplikation und/oder</li>
                <li>
                  durch Verwendung fehlerhafter oder beschädigter Hardware, anomaler Betriebsbedienungen, Ausfall der
                  Stromversorgung oder datenführender Leitungen.
                </li>
              </ol>
            </li>
            <li>
              Zur Klarstellung wird darauf hingewiesen, dass die vorstehende Aufzählung nicht abschließend ist. Aus der
              fehlenden Aufführung von Leistungen kann nicht geschlossen werden, dass diese Leistungen Gegenstand von
              vertraglichen Pflichten von SANACORP sind.
            </li>
          </ol>
        </li>
      </ol>
    </li>
    <li>
      <strong>Bedingungen für ENDKUNDEN</strong><br />
      Innerhalb der Anwendung des MEADIREKT-CHAT wird der ENDKUNDE auf die zwischen ihm und der APOTHEKE und zwischen
      ihm und SANACORP anwendbaren Nutzungsbedingungen und Datenschutzbedingungen (MEADIREKT DATENSCHUTZERKLÄRUNG und
      die Datenschutzerklärung des APOTHEKERS) hingewiesen. Diese können jederzeit öffentlich unter
      <a href="https://www.meineapotheke.de/meadirekt-chat">www.meineapotheke.de/meadirekt-chat</a> eingesehen werden.
      Da im Rahmen des CHAT WIDGET ausschließlich Verarbeitungen im Verhältnis APOTHEKER – ENDKUNDE vorgesehen sind,
      wird in diesem nicht auf die MEADIREKT DATENSCHUTZERKLÄRUNG verwiesen.
    </li>
    <li>
      <strong>Nutzung als Gast bzw. registrierter Nutzung</strong><br />
      Die Nutzung von MEADIREKT-CHAT ist über einen Zugang als Gastnutzer bzw. als registrierter Nutzer möglich:
      <ol>
        <li>
          Nutzung als Gast<br />
          Die Nutzung von MEADIREKT-CHAT ist über einen Gastzugang (&bdquo;Gastnutzer&ldquo;) möglich. In diesem Fall
          setzt die Nutzung keine Registrierung voraus. Erforderlich ist lediglich eine Einwilligung in die Verarbeitung
          der relevanten Kundendaten durch SANACORP bzw. die beteiligten APOTHEKER durch Abgabe einer entsprechenden
          Einwilligungserklärung.
        </li>
        <li>
          Nutzung als registrierter Nutzer<br />
          Zur Nutzung von MEADIREKT-CHAT kann sich der ENDKUNDE registrieren. Zur Registrierung ist aktuell insbesondere
          die Angabe korrekter persönlicher Namens- und Adressdaten erforderlich. Nach erfolgreicher Registrierung kann
          der ENDKUNDE MEADIREKT-CHAT als registrierter Nutzer nutzen. Neben der Eröffnung eines Benutzerkontos ist die
          Abgabe einer Einwilligung in die Verarbeitung der relevanten Kundendaten durch SANCORP bzw. die beteiligten
          APOTHEKEN durch Abgabe einer entsprechenden Einwilligungserklärung erforderlich. Eine Registrierung bietet im
          Vergleich zur Nutzung von MEADIREKT als Gastnutzer insbesondere folgende Vorteile:
          <ol>
            <li>Zugriff auf Chats und Chatverläufe</li>
            <li>
              Speichern von APOTHEKEN als Favoriten. Die als Gastnutzer geführten Chats bleiben nach einer Registrierung
              erhalten
            </li>
          </ol>
        </li>
        <li>
          Gebühren und Kosten
          <ol>
            <li>
              Für die Einrichtung des MEADIREKT-CHAT berechnet SANACORP gegenüber dem APOTHEKER eine einmalige
              Einrichtungsgebühr.
            </li>
            <li>
              Die weitere Nutzung einschließlich dem Hosting ist grundsätzlich durch Zahlungen im Rahmen der
              mea-Kooperation abgegolten.
            </li>
            <li>
              Die Kosten für die Nutzung von digitalen Kommunikationsnetzen bei Nutzung von MEADIREKT-CHAT trägt der
              APOTHEKER.
            </li>
          </ol>
        </li>
      </ol>
    </li>
    <li>
      <strong>Zulässige Nutzung von MEADIREKT-CHAT</strong>
      <ol>
        <li>
          Der APOTHEKER sichert zu, MEADIREKT-CHAT ausschließlich im Einklang mit den geltenden Gesetzen und Richtlinien
          sowie den ALLGEMEINEN NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT und den PRODUKSPEZIFISCHEN NUTZUNGS- UND
          LIZENZBEDINGUNGEN MEADIREKT – CHAT zu nutzen. Die Nutzung hat sich dabei auf den bestimmungsgemäßen Gebrauch
          und die im Rahmen der NUTZUNGSBEDINGUNGEN definierten Anwendungsgebiete von MEADIREKTCHAT zu beschränken.
        </li>
        <li>
          Der APOTHEKER ist verpflichtet, MEADIREKT-CHAT nur für rechtmäßige, berechtigte und zulässige Zwecke zu nutzen
          und wird MEADIREKT-CHAT weder auf eine andere Weise nutzen noch Dritte bei einer Nutzung unterstützen, welche
          insbesondere
          <ol>
            <li>
              die Rechte von MEADIREKT, SANACORP und/oder der ENDKUNDEN bzw. sonstiger Dritter (einschließlich
              Datenschutz- oder Veröffentlichungsrechte, Rechte am geistigen Eigentum oder sonstige Eigentumsrechte)
              verletzt, widerrechtlich verwendet oder verstößt;
            </li>
            <li>
              rechtswidrig, obszön, beleidigend, bedrohend, einschüchternd, belästigend, hasserfüllt, rassistisch oder
              ethnisch anstößig ist, oder zu einer Verhaltensweise anstiftet oder ermuntert, die illegal oder auf
              sonstige Weise unangemessen wäre, einschließlich der Verherrlichung von Gewaltverbrechen;
            </li>
            <li>das Veröffentlichen von Unwahrheiten, Falschdarstellungen oder irreführender Aussagen beinhaltet;</li>
            <li>jemanden nachahmt und/oder</li>
            <li>
              das Versenden illegaler oder unzulässiger Mitteilungen wie Massennachrichten, Auto-Messaging, Auto-Dialing
              und dergleichen umfasst.
            </li>
          </ol>
        </li>
        <li>
          Der APOTHEKER ist verpflichtet, jede indirekte oder direkte Beeinträchtigung von MEADIREKT-CHAT zu
          unterlassen. Hierzu gehört insbesondere, es zu unterlassen
          <ol>
            <li>
              Viren oder sonstige schädlichen Computercodes über MEADIREKT-CHAT zu versenden, zu übermitteln oder zu
              speichern;
            </li>
            <li>
              unberechtigten Zugriff auf MEADIREKT-CHAT und/oder auf Systeme von SANACORP und/oder weiteren beteiligten
              APOTHEKERN zu erlangen oder dies zu versuchen;
            </li>
            <li>die Integrität oder Leistung von MEADIREKT-CHAT zu durchbrechen bzw. zu stören;</li>
            <li>Accounts für MEADIREKT-CHAT über nicht autorisierte Wege bzw. Mittel zu erstellen;</li>
            <li>
              Informationen von bzw. über die beteiligten APOTHEKER bzw. andere Nutzer von MEADIREKT-CHAT auf eine
              unzulässige oder unberechtigte Art und Weise zu sammeln und/oder
            </li>
            <li>
              Software, Webapplikationen oder vergleichbare Anwendungen zu entwickeln und/oder zu verbreiten, welche
              wesentliche Funktionsweisen von MEADIREKT-CHAT enthalten.
            </li>
          </ol>
        </li>
        <li>
          Mit der Nutzung von MEADIREKT-CHAT bestätigt der APOTHEKER, dass ihm die in Bezug auf die über MEADIREKT-CHAT
          übermittelten Informationen, Daten bzw. Dateien ggf. erforderlichen Rechte zustehen.
        </li>
      </ol>
    </li>
    <li>
      <strong>Sicherheit der Zugangsdaten</strong>
      <ol>
        <li>
          Der APOTHEKER ist für die Sicherheit seiner Zugangsdaten (Account) verantwortlich. Insbesondere hat er durch
          geeignete Maßnahmen sicherzustellen, dass Dritte nicht in unberechtigter Art und Weise Zugriff auf die
          Zugangsdaten oder den Account nehmen.
        </li>
        <li>
          Der APOTHEKER hat SANACORP von jeder (drohenden) unberechtigten Nutzung der Zugangsdaten bzw. von jedem
          Sicherheitsverstoß in Bezug auf den Account unverzüglich in Kenntnis zu setzen.
        </li>
      </ol>
    </li>
  </ol>
  <h4>&#8546;.&nbsp;LEISTUNGEN VON SANACORP</h4>
  <p>
    Im Zusammenhang mit der Bereitstellung des Kommunikationsmittels MEADIREKT-CHAT erbringt SANACORP folgende
    Leistungen an den APOTHEKER:
  </p>
  <ol>
    <li>
      <strong>Betrieb und Weiterentwicklung von MEADIREKT-CHAT</strong><br />
      SANACORP betreibt die Webapplikation MEADIREKT-CHAT. SANACORP wird MEADIREKT-CHAT in regelmäßigen Abständen
      weiterentwickeln. Der Umfang und die Art und Weise der Weiterentwicklung liegt im Ermessen von SANACORP.
    </li>
    <li>
      <strong>Zugang zur Nutzung von MEADIREKT-CHAT</strong><br />
      Der APOTHEKER erhält Zugang zu MEADIREKT-CHAT über die Website [<a href="https://apotheke.meineapotheke.de"
        >https://apotheke.meineapotheke.de</a
      >]. Für die Erstanmeldung erhält der APOTHEKER von SANACORP Zugangsdaten, wird dann aber im Rahmen des
      Anmeldevorgangs aufgefordert, sein Passwort zu ändern.
    </li>
    <li>
      <strong>Marketing-Leistungen</strong><br />
      SANACORP wird MEADIREKT-CHAT in angemessenem Umfang bewerben und den APOTHEKER in angemessenem Umfang beim
      Marketing gegenüber den Endkunden unterstützen.
    </li>
    <li>
      <strong>Dokumentation & Speicherung von Daten</strong><br />
      Der gesamte Datentransfer zwischen den jeweils eingesetzten Endgeräten und den Servern von SANACORP erfolgt
      verschlüsselt nach dem jeweils aktuellen, allgemein anerkannten Stand der Technik und nach den Grundsätzen
      ordnungsgemäßer Berufsausübung (derzeit HTTPS). Da eine End-to-End Verschlüsselung der jeweiligen Nachrichten
      zwischen dem APOTHEKER und dem ENDKUNDEN erfolgt, hat SANACORP selbst keine Zugriffsmöglichkeit auf die jeweiligen
      Nachrichten.<br />
      Die Dokumentation und Speicherung der Kommunikation obliegt dem APOTHEKER. SANACORP stellt dem Apotheker in
      besonderen Fällen (etwa bei Vertragsbeendigung) hierfür aber eine zeitlich begrenzte Möglichkeit zur
      Datensicherung zur Verfügung. Der APOTHEKER hat die Möglichkeit, im Rahmen einer Exportfunktion Chatverläufe und
      Inhalte zu Dokumentationszwecken zu exportieren und zu speichern. Dies gilt sowohl in Bezug auf Chatverläufe
      (incl. Inhalte) mit Gastnutzern als auch in Bezug auf Chatverläufe (incl. Inhalte) mit registrierten Nutzern.
    </li>
    <li>
      <strong>Schulung und Support</strong><br />
      SANACORP hat dem APOTHEKER Schulungsunterlagen zur Verfügung gestellt. Bei Fragen zur Handhabung steht dem
      APOTHEKER der jeweils zuständige Außendienstmitarbeiter sowie der Kundenservice der SANACORP zur Verfügung.
    </li>
  </ol>
  <h4>&#8547;.&nbsp;Nicht geschuldete Leistungen</h4>
  <p>Zur Klarstellung wird darauf hingewiesen, dass der APOTHEKER kein Anspruch auf folgende Leistungen:</p>
  <ol>
    <li>
      Anpassung von MEADIREKT-CHAT an eine geänderte Hard- oder Softwareumgebung einschließlich der Anpassung an
      veränderte Betriebssysteme,
    </li>
    <li>Anpassung von MEADIREKT-CHAT an gesetzliche, hoheitliche oder behördliche Anforderungen,</li>
    <li>
      Beseitigung von Fehlern aus dem Risikobereich des APOTHEKERS – hierzu gehören insbesondere Fehler, welche
      verursacht wurden,
      <ol class="custom-list-abc">
        <li>durch unsachgemäße Bedienung oder Veränderung der Webapplikation</li>
        <li>
          durch Verwendung fehlerhafter Hardware, anomaler Betriebsbedienungen, Ausfall der Stromversorgung oder
          datenführender Leitungen
        </li>
      </ol>
    </li>
    <li>
      Die unter Ziff. 1. bis 3. enthaltene Aufzählung ist nicht abschließend. Aus der fehlenden Aufführung von
      Leistungen kann nicht geschlossen werden, dass diese Leistungen Gegenstand der vertraglichen Pflichten von
      SANACORP sind.
    </li>
  </ol>
  <h4>&#8548;.&nbsp;Vertragsdauer und Kündigung</h4>
  <ol>
    <li>
      Die Laufzeit des MEADIREKT-CHAT-VERTRAGES beträgt mindestens 6 Monate und mit Abschluss des
      MEADIREKT-CHAT-VERTRAGES.
    </li>
    <li>
      Der MEADIREKT-CHAT-VERTRAG läuft so lange, bis dieser von SANACORP oder dem APOTHEKER unter Einhaltung einer
      Kündigungsfrist von vier Wochen zum Monatsende gekündigt wird.
    </li>
    <li>
      Die Folgen der Beendigung des Vertragsverhältnisses im Zusammenhang mit der Nutzung und der Bereitstellung von
      MEADIREKT ergeben sich aus § 15 Abs. 1 bis 3 des Kooperationsvertrages. Darüber hinaus wird der APOTHEKER darauf
      hingewiesen, dass mit einer Vertragsbeendigung insbesondere die nachfolgend dargestellten Rechtsfolgen verbunden
      sind:
      <ol class="custom-list-abc">
        <li>Beendigung der Möglichkeit zur Nutzung von MEADIREKT bzw. dem MEADIREKT-CHAT.</li>
        <li>Löschung aller Chats und Kontaktdaten und/oder</li>
        <li>
          Hinweis auf die Beendigung der Teilnahme an Endkunden in MEADIREKT-CHAT durch Nennung des Apothekennamens mit
          entsprechendem Zusatz.
        </li>
      </ol>
    </li>
  </ol>
  <h4>&#8549;.&nbsp;Schutz- und Nutzungsrechte</h4>
  <ol>
    <li>
      SANACORP räumt dem APOTHEKER für den Zeitraum der Geltung der vertraglichen Vereinbarungen zur Bereitstellung und
      Nutzung von MEADIREKT - CHAT bzw. der Geltung der PRODUKTSPEZIFISCHEN NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT –
      CHAT eine beschränkte, widerrufliche, nicht-exklusive, nicht unterlizensierbare und nicht-übertragbare Lizenz zur
      Nutzung von MEADIREKT-CHAT auf der Grundlage der im Rahmen <strong>(a)</strong> der ALLGEMEINE NUTZUNGS- UND
      LIZENZBEDINGUNGEN MEADIREKT und <strong>(b)</strong> der PRODUKTSPEZIFISCHEN NUTZUNGS- UND LIZENZBEDINGUNGEN
      MEADIREKT – CHAT getroffenen Regelungen.
    </li>
    <li>
      Das eingeräumte Nutzungsrecht dient allein dem Zweck, dem APOTHEKER die Nutzung von MEADIREKT-CHAT zu ermöglichen.
      Weitere Lizenzen oder Nutzungsrechte werden weder stillschweigend noch ausdrücklich gewährt.
    </li>
  </ol>
  <h4>&#8550;.&nbsp;Datenschutz</h4>
  <ol>
    <li>
      Im Zusammenhang mit der Nutzung von MEADIREKT-CHAT werden personenbezogene Daten des ENDKUNDEN erhoben,
      verarbeitet und gespeichert. Die Einzelheiten ergeben sich aus der im öffentlichen Bereich des jeweiligen
      MEADIREKT PRODUKTES (derzeit
      <a href="https://www.meineapotheke.de/service/datenschutz/">www.meineapotheke.de/service/datenschutz/</a>)
      einsehbaren Datenschutzerklärung (nachfolgend: &bdquo;<strong>MEINEAPOTHEKE DATENSCHUTZERKLÄRUNG</strong>&ldquo;).
    </li>
    <li>
      Die Nutzung von MEADIREKT-CHAT durch den APOTHEKER beinhaltet – je nach konkretem Einzelfall –
      datenschutzrechtliche Verarbeitungsvorgänge insbesondere in Form eines Auslesens und Speicherns von
      personenbezogenen Daten bzw. von Gesundheitsdaten des ENDKUNDEN. Der APOTHEKER ist verpflichtet, sämtliche
      datenschutzrechtlichen Anforderungen und Verpflichtungen zu erfüllen, welche sich für ihn aus und im Zusammenhang
      mit der Nutzung von MEADIREKT-CHAT ergeben. Insbesondere ist der APOTHEKER gem. Art. 13 DSGVO verpflichtet, die
      ENDKUNDEN über die Verarbeitung von personenbezogenen Daten zu informieren. Die individuelle Datenschutzerklärung
      des APOTHEKERS wird zu Beginn eines Chats eingeblendet und kann zudem unter den Stammdaten der APOTHEKE abgerufen
      werden.
    </li>
    <li>
      Die während des Chatverlaufes erhobenen personenbezogenen Daten (Name, Alias, mitgeteilte Kommunikationsinhalte,
      Bilder, Rezeptdaten, Videobilder) werden im Wege der Auftragsverarbeitung gemäß Art. 28 DSGVO für den APOTHEKER
      als Verantwortlicher i.S.d. Art. 4 Nr. 7 DSGVO verarbeitet. Die Details der Verarbeitung personenbezogener Daten
      der Endkunden regelt <strong>Anhang I</strong> (AVV) der ALLGEMEINEN MEADIRKET NUTZUNGS- UND LIZENZBEDINGUNGEN.
    </li>
    <li>
      Der APOTHEKER hat sicherzustellen, dass er eine dem § 13 DSGVO entsprechende Datenschutzerklärung im
      MEADIREKT-CHAT hinterlegt. Eine unverbindliche Orientierungshilfe bzw. Hilfsmittel zur Erstellung von Mustertexten
      werden nach Maßgabe der entsprechenden Regelungen in den ALLGEMEINEN NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT zur
      Verfügung gestellt.
    </li>
    <li>
      Für den Fall, dass ein ENDKUNDEN eine etwaige Einwilligung in die Verarbeitung personenbezogener gegenüber dem
      APOTHEKER mit Wirkung gegenüber SANACORP widerruft, , ist der APOTHEKER verpflichtet, SANACORP zum Zwecke der
      Löschung des Benutzerkontos unverzüglich zu informieren.
    </li>
    <li>
      Der APOTHEKER sichert die Einhaltung sämtlicher datenschutzrechtlicher, insbesondere sämtlicher in diesen
      Bedingungen ausdrücklich genannter (datenschutzrechtlichen) Pflichten auch gegenüber SANACORP zu. Der APOTHEKER
      wird darauf hingewiesen, dass Verstöße gegen datenschutzrechtliche Pflichten eine Verpflichtung zur Freistellung
      von SANACORP i.S. der ALLGEMEINEN NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT auslösen können.
    </li>
    <li>
      Bei der Nutzung der Website durch den APOTHEKER setzt SANACORP Analysetools ein. Weitere Details können Sie der
      MEINEAPOTHEKE DATENSCHUTZERKLÄRUNG sowie den ALLGEMEINEN DATENSCHUTZHINWEISEN FÜR GESCHÄFTSPARTNER DER
      SANACORP-GRUPPE entnehmen. Soweit nicht abweichend geregelt, erhebt und verwendet SANACORP darüber hinaus derzeit
      keine Daten des APOTHEKERS bei der Nutzung des Chats durch den APOTHEKER oder dessen Mitarbeiter.
    </li>
    <li>
      SANACORP plant künftig, neben den mittels der Analysetools erhobenen Daten, insbesondere folgende Daten bei der
      Nutzung des MEADIREKT-CHATS durch den APOTHEKER bzw. dessen Mitarbeiter zu erheben: Reaktionszeiten,
      Nutzungsverhalten, Klickverhalten, auftretende Fehler, Fehler-Logfiles. SANACORP beabsichtigt, die so erhobenen
      Daten zu verwenden um: (a) das Produkt zu verbessern; (b) die Anzahl der Kontakte, sowie Reaktionszeiten sichtbar
      zu machen, auszuwerten und dem APOTHEKER Auswertungen anbieten zu können; (c) den Service für den ENDKUNDEN und
      den APOTHEKER zu verbessern.
    </li>
  </ol>
  <br />
  <h3>PRODUKTSPEZIFISCHE NUTZUNGS- UND LIZENZBEDINGUNGEN MEINEAPOTHEKE.DE TERMINBUCHUNGSFUNKTION</h3>
  <h4>Stand: 03.02.2022</h4>
  <br />
  <h4>PRÄAMBEL</h4>
  <ol>
    <li>
      SANACORP bietet APOTHEKEN im Rahmen von MEADIREKT eine Applikation zur Buchung von Terminen von ENDKUNDEN bei
      APOTHEKEN (nachfolgend „TERMINBUCHUNGSFUNKTION“) an. Nachfolgend werden produktspezifische Aspekte der Nutzung der
      TERMINBUCHUNGSFUNKTION durch den APOTHEKER geregelt.
    </li>
    <li>
      Diese PRODUKSPEZIFISCHEN NUTZUNGS- UND LIZENZBEDINGUNGEN TERMINBUCHUNGSFUNKTION stellen zusammen mit ALLGEMEINEN
      NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT sowie den separat im Rahmen der Bestellung der TERMINBUCHUNGSFUNKTION
      angegebenen Details zu den Vertragsparteien, dem Preis, der Vertragslaufzeit, der Produktbeschreibung und dem
      Leistungsumfang (nachfolgend „VERTRAGSDETAILS“) die vertragliche Grundlage der Nutzung der TERMINBUCHUNGSFUNKTION
      dar.
    </li>
  </ol>
  <h4>&#8544;.&nbsp;Funktion und Grundlagen der TERMINBUCHUNGSFUNKTION</h4>
  <ol>
    <li>
      Ziel der TERMINBUCHUNGSFUNKTION ist die Schaffung einer digitalen Lösung für die einfache Online Vereinbarung von
      Terminen zwischen dem APOTHEKER und ENDKUNDEN. Diese kann z.B. zur Vereinbarung von Beratungsgesprächen oder
      Test-/Impfterminen genutzt werden.
    </li>
    <li>
      Grundbedingung für die Nutzung der TERMINBUCHUNGSFUNKTION durch den APOTHEKER ist, dass dieser sämtliche im
      Zeitpunkt der jeweiligen Nutzung gegenüber Endkunden oder sonstigen Dritten im Rahmen der im Territorium der
      Bundesrepublik Deutschland für den Betrieb von Apotheken, die Abgabe apothekenpflichtiger Arzneimittel und/oder
      sonstiger Produkte sowie für die Ausübung einer pharmazeutischen Tätigkeit geltenden rechtlichen und berufs- oder
      standesrechtlichen Vorgaben achtet und einhält. Soweit zusätzliche Berechtigungen für die Art des Termines
      notwendig sind, sichert der APOTHEKER zu, dass diese vorliegen und weist dies auf Anfrage SANACORP gegenüber nach.
      Insoweit der APOTHEKER die TERMINBUCHUNGSFUNKTION zum Zwecke der Vereinbarung vom Impfterminen verwendet, ist dies
      insbesondere nur zulässig, wenn er über eine Bescheinigung der zuständigen Landesapothekerkammer über den Nachweis
      der Berechtigung verfügt. Diese ist auf Anfrage von SANACORP unverzüglich zum Zwecke der Überprüfung der
      Berechtigung zu übermitteln.
    </li>
    <li>
      Zur Klarstellung wird darauf hingewiesen, dass sich SANACORP im Zusammenhang mit der Bereitstellung der
      TERMINBUCHUNGSFUNKTION sich auf die reine Bereitstellung und ergänzende Marketingmaßnahmen beschränkt. Marketing-
      bzw. Werbemaßnahmen werden jeweils so ausgestaltet, dass sie sich auf das Produkt als solches sowie die
      Kooperation mea® -meine apotheke bzw. meineapotheke.de beziehen. Die im Einzelnen beteiligten Apotheken können und
      werden durch SANACORP nicht beworben. Weiter obliegt die Auswahl der Apotheke für eine Terminbuchung dem
      ENDKUNDEN. SANACORP kann deshalb nicht gewährleisten, dass die TERMINBUCHUNGSFUNKTION zu einer Begründung neuer
      Kundenverhältnisse führt bzw. eine Bindung von Bestandskunden fördert. Ebenso findet durch SANACORP keine
      Verteilung von potentiellen Kunden an Apotheken statt.
    </li>
    <li>
      Zur Vereinbarung von Terminen stehen dem ENDKUNDEN alle APOTHEKEN zur Verfügung, welche die TERMINBUCHUNGSFUNKTION
      von SANACORP nutzen. Der ENDKUNDE wählt aus, mit welcher APOTHEKE er einen Termin vereinbaren möchte. Er hat die
      Möglichkeit, gezielt nach einer APOTHEKE zu suchen bzw. sich nach bestimmten Paramatern (Umkreissuche, PLZ-Suche)
      APOTHEKEN anzeigen zu lassen. Die Hoheit und die Entscheidung über die Auswahl der APOTHEKE liegt stets und zu
      jedem Zeitpunkt beim ENDKUNDEN.
    </li>
    <li>
      Die Terminvereinbarung, deren Vorbereitung und Durchführung findet ausschließlich zwischen dem ENDKUNDEN und der
      jeweiligen APOTHEKE statt. SANACORP ist hierin weder direkt noch indirekt eingebunden. In dem Betrieb und der
      Bereitstellung der TERMINBUCHUNGSFUNKTION liegt insbesondere keine Vermittlung von Terminen.
    </li>
  </ol>
  <h4>&#8545;.&nbsp;Nutzung der TERMINBUCHUNGSFUNKTION</h4>
  <ol>
    <li>
      Der ENDKUNDE kann die TERMINBUCHUNGSFUNKTION insbesondere über die Meineapotheke.de App, die Website
      <a href="https://www.meineapotheke.de/meadirekt-chat" target="_blank" rel="noopener noreferrer"
        >https://www.meineapotheke.de/</a
      >
      oder über individuelle QR-Codes der beteiligten Apotheken erreichen. Das Angebot kann über einen internetfähigen
      PC bzw. ein mobiles Endgerät mit Internetzugang (Tablet bzw. Smartphone) erreicht werden.
    </li>
    <li>
      Zur Nutzung der TERMINBUCHUNGSFUNKTION über einen PC oder ein mobiles Endgerät mit Internetzugang ist in der Regel
      möglich über einen unterstützten Browser (derzeit unterstützt: Chrome, Safari, Edge in der jeweils neusten
      Version) welcher auf einem unterstützten Betriebssystem installiert wurde (derzeit unterstützt, Windows, Android,
      IOS, MAC OS in der jeweils neusten Version). Eine Nutzung der TERMINBUCHUNGSFUNKTION über die meineapotheke.de App
      erfordert zudem den Download über den Google Appstore bzw. den Apple Appstore, sowie die Installation der
      meineapotheke.de App in der jeweils neusten Fassung.
    </li>
    <li>
      Der APOTHEKER wird darauf hingewiesen, dass sich die Systemvoraussetzungen zu gegebener Zeit ändern können bzw.
      die Nutzung der TERMINBUCHUNGSFUNKTION die Installation von zusätzlichen Software-Komponenten (ggf. auch von
      Drittanbietern) erforderlich machen kann.
    </li>
    <li>
      Es ist beabsichtigt, die TERMINBUCHUNGSFUNKTION grundsätzlich an 365 Kalendertagen im Jahr über 24 Stunden zur
      Verfügung zu stellen. Eine ununterbrochene bzw. lückenlose (technische) Verfügbarkeit kann jedoch nicht garantiert
      werden. Dies gilt insbesondere auch in Bezug auf Umstände, welche nicht in den Verantwortungsbereich von SANACORP
      fallen und für die Nichtverfügbarkeit ursächlich sind (z.B. Stromausfall, Fehler bzw. Diskonnektivität im
      Datenübertragungsnetz, Störungen im Bereich der Hardware von SANACORP).
    </li>
  </ol>
  <h4>&#8546;.&nbsp;Kosten</h4>
  <ol>
    <li>
      Für die Einrichtung der TERMINBUCHUNGSFUNKTION berechnet SANACORP gegenüber dem APOTHEKER eine einmalige
      Einrichtungsgebühr. Für den Betrieb der TERMINBUCHUNGSFUNKTION kann eine monatliche Gebühr erhoben werden. Die
      konkrete Höhe ergibt sich aus dem Buchungsformular.
    </li>
    <li>
      Die Kosten für die Nutzung von digitalen Kommunikationsnetzen bei Nutzung des TERMINBUCHUNGSFUNKTION trägt der
      APOTHEKER.
    </li>
  </ol>
  <h4>&#8547;.&nbsp;Sicherheit der Zugangsdaten</h4>
  <ol>
    <li>
      Der APOTHEKER ist für die Sicherheit seiner Zugangsdaten (Account) verantwortlich. Insbesondere hat er durch
      geeignete Maßnahmen sicherzustellen, dass Dritte nicht in unberechtigter Art und Weise Zugriff auf die
      Zugangsdaten oder den Account nehmen.
    </li>
    <li>
      Der APOTHEKER hat SANACORP von jeder (drohenden) unberechtigten Nutzung der Zugangsdaten bzw. von jedem
      Sicherheitsverstoß in Bezug auf den Account unverzüglich in Kenntnis zu setzen.
    </li>
  </ol>
  <h4>&#8548;.&nbsp;Vertragsdauer und Kündigung</h4>
  <ol>
    <li>Die Laufzeit der Buchung der TERMINBUCHUNGSFUNKTION ergibt sich aus dem Bestellformular.</li>
    <li>
      Die TERMINBUCHUNGSFUNKTION wird grundsätzlich ohne zeitliche Beschränkung gebucht. Die Buchung endet, wenn
      SANACORP oder der APOTHEKER unter Einhaltung einer Kündigungsfrist von vier Wochen zum Monatsende kündigt.
    </li>
  </ol>
  <h4>&#8549;.&nbsp;Schutz- und Nutzungsrechte</h4>
  <ol>
    <li>
      SANACORP räumt dem APOTHEKER für den Zeitraum der Geltung der vertraglichen Vereinbarungen zur Bereitstellung und
      Nutzung der TERMINBUCHUNGSFUNKTION bzw. der Geltung der PRODUKTSPEZIFISCHEN NUTZUNGS- UND LIZENZBEDINGUNGEN
      MEINEAPOTHEKE.DE TERMINBUCHUNGSFUNKTION eine beschränkte, widerrufliche, nicht-exklusive, nicht unterlizensierbare
      und nicht-übertragbare Lizenz zur Nutzung der TERMINBUCHUNGSFUNKTION auf der Grundlage der im Rahmen (a) der
      ALLGEMEINE NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT und (b) der PRODUKTSPEZIFISCHEN NUTZUNGS- UND
      LIZENZBEDINGUNGEN MEINEAPOTHEKE.DE TERMINBUCHUNGSFUNKTION getroffenen Regelungen.
    </li>
    <li>
      Das eingeräumte Nutzungsrecht dient allein dem Zweck, dem APOTHEKER die Nutzung der TERMINBUCHUNGSFUNKTION zu
      ermöglichen. Weitere Lizenzen oder Nutzungsrechte werden weder stillschweigend noch ausdrücklich gewährt.
    </li>
  </ol>
  <h4>&#8550;.&nbsp;Datenschutz</h4>
  <ol>
    <li>
      Im Zusammenhang mit der Nutzung der TERMINBUCHUNGSFUNKTION werden durch SANACORP für den APOTHEKER
      personenbezogene Daten des ENDKUNDEN im Wege der Auftragsverarbeitung erhoben, verarbeitet und gespeichert.
    </li>
    <li>
      Die Nutzung der TERMINBUCHUNGSFUNKTION durch den APOTHEKER beinhaltet – je nach konkretem Einzelfall –
      datenschutzrechtliche Verarbeitungsvorgänge insbesondere in Form eines Auslesens und Speicherns von
      personenbezogenen Daten bzw. von Gesundheitsdaten des ENDKUNDEN. Der APOTHEKER ist verpflichtet, sämtliche
      datenschutzrechtlichen Anforderungen und Verpflichtungen zu erfüllen, welche sich für ihn aus und im Zusammenhang
      mit der Nutzung der TERMINBUCHUNGSFUNKTION ergeben. Insbesondere ist der APOTHEKER gem. Art. 13 DSGVO
      verpflichtet, die ENDKUNDEN über die Verarbeitung von personenbezogenen Daten zu informieren. Die individuelle
      Datenschutzerklärung des APOTHEKERS wird im Rahmen der TERMINBUCHUNGSFUNKTION eingeblendet und kann zudem unter
      den Stammdaten der APOTHEKE abgerufen werden. Eine unverbindliche Orientierungshilfe bzw. Hilfsmittel zur
      Erstellung von Mustertexten werden nach Maßgabe der entsprechenden Regelungen in den ALLGEMEINEN NUTZUNGS- UND
      LIZENZBEDINGUNGEN MEADIREKT zur Verfügung gestellt.
    </li>
    <li>
      Die im Rahmen der TERMINBUCHUNGSFUNKTION erhobenen Daten (E-Mail-Adresse, Name, Datum des Termins, ggf. Zweck des
      Termins) (nachfolgend „TERMINDATEN“) werden im Wege der Auftragsverarbeitung gemäß Art. 28 DSGVO für den APOTHEKER
      als Verantwortlicher i.S.d. Art. 4 Nr. 7 DSGVO verarbeitet. Die Details der Verarbeitung personenbezogener Daten
      der Endkunden regelt Anhang I (AVV) der ALLGEMEINEN MEADIRKET NUTZUNGS- UND LIZENZBEDINGUNGEN.
    </li>
    <li>
      Der APOTHEKER sichert die Einhaltung sämtlicher datenschutzrechtlicher, insbesondere sämtlicher in diesen
      Bedingungen ausdrücklich genannter (datenschutzrechtlichen) Pflichten auch gegenüber SANACORP zu. Der APOTHEKER
      wird darauf hingewiesen, dass Verstöße gegen datenschutzrechtliche Pflichten eine Verpflichtung zur Freistellung
      von SANACORP i.S.v. der ALLGEMEINE NUTZUNGS- UND LIZENZBEDINGUNGEN MEADIREKT auslösen können.
    </li>
  </ol>
</div>
