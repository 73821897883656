import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { ErrorHandler, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { KeycloakAngularModule } from 'keycloak-angular';
import merge from 'lodash-es/merge';
import { CookieService } from 'ngx-cookie-service';
import { CommonStoreModule } from '../../../libs/common/resources/src/common-store/common-store.module';
import { metaReducers } from '../../../libs/common/resources/src/common-store/metaReducers';
import { GlobalErrorHandler } from '../../../libs/common/resources/src/global-error-handler';
import { initSentry } from '../../../libs/common/resources/src/sentry';
import { IconNamePipe } from '../../../libs/essentials/util/src/pipes/icon-name.pipe';
import { AppointmentNavigationService } from '../../../libs/pharmacy-only/src/services/appointment-navigation.service';
import { PharmacyChatPartnerMetadataService } from '../../../libs/pharmacy-only/src/services/pharmacy-chat-partner-metadata.service';
import { SingleSignOnService } from '../../../libs/pharmacy-only/src/services/single-sign-on.service';
import { UserConsentService } from '../../../libs/pharmacy-only/src/services/user-consent.service';
import { PharmacyChatPartnerStoreModule } from '../../../libs/pharmacy-only/src/store/pharmacy-chat-partner-store/pharmacy-chat-partner-store.module';
import { PharmacySubscriptionEffects } from '../../../libs/pharmacy-only/src/store/pharmacy-subscription/pharmacy-subscription.effects';
import { UserConsentEffects } from '../../../libs/pharmacy-only/src/store/user-consent/user-consent.effects';
import { AppointmentEffects } from '../../../libs/web-only/src/appointments/store/appointment.effects';
import { APP_ROUTES } from './app/app-routing';
import { AppComponent } from './app/app.component';
import { loginRoutes, pharmacyRoutes } from './app/pharmacy-routes';
import { TextblockService } from './app/services/textblock.service';
import { AnalyticsEffects } from './app/store/effects/analytics.effects';
import { HelpEffects } from './app/store/effects/help.effects';
import { NavigationEffects } from './app/store/effects/navigation.effects';
import { reducer } from './app/store/reducers';
import { WebpackTranslateLoader } from './app/webpack-translate-loader';

import { environment } from './environments/environment';
import { featureFlags } from './environments/feature-flags';
import { CONFIG } from '../../../libs/essentials/types/src/mea-config';
import { CHAT_PARTNER_METADATA_SERVICE } from '../../../libs/essentials/types/src/service-interfaces/chat-partner-metadata.service.interface';
import { SINGLE_SIGN_ON_SERVICE } from '../../../libs/essentials/types/src/service-interfaces/single-sign-on.service.interface';
import { USER_CONSENT_SERVICE } from '../../../libs/essentials/types/src/service-interfaces/user-consent.service.interface';
import { LOGIN_ROUTES } from '../../../libs/pharmacy-only/src/services/pharmacy-signin.service';
import { PHARMACY_ROUTES } from '../../../libs/pharmacy-only/src/interfaces/pharmacy-routes-interface';
import { APPOINTMENT_NAVIGATION_SERVICE } from '../../../libs/essentials/types/src/service-interfaces/appointment-navigation.service.interface';
import { TEXTBLOCK_SERVICE } from '../../../libs/essentials/types/src/service-interfaces/textblock.service.interface';

const mergeConfigs = () => merge(environment, featureFlags);
declare let VERSION: string;

dayjs.locale('de');
registerLocaleData(localeDe);

if (typeof VERSION !== 'undefined' && environment.name === 'prod') {
  initSentry({
    dsn: environment.sentryDSN,
    release: VERSION,
    environment: environment.name,
  });
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      IonicModule.forRoot({
        animated: environment.animated,
        useSetInputAPI: true,
      }),
      RouterModule.forRoot(APP_ROUTES, { bindToComponentInputs: true }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: WebpackTranslateLoader,
        },
      }),
      IonicStorageModule.forRoot(),
      KeycloakAngularModule,
      StoreModule.forRoot(reducer, {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: !environment.production,
          strictActionImmutability: !environment.production,
        },
      }),
      EffectsModule.forRoot([]),
      CommonStoreModule,
      PharmacyChatPartnerStoreModule,
      EffectsModule.forFeature([
        AnalyticsEffects,
        AppointmentEffects,
        HelpEffects,
        NavigationEffects,
        PharmacySubscriptionEffects,
        UserConsentEffects,
      ]),
      ...environment.imports
    ),
    CookieService,
    IconNamePipe,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: CONFIG, useValue: mergeConfigs() },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: TEXTBLOCK_SERVICE, useClass: TextblockService },
    { provide: SINGLE_SIGN_ON_SERVICE, useClass: SingleSignOnService },
    { provide: LOCALE_ID, useValue: 'de-de' },
    { provide: APPOINTMENT_NAVIGATION_SERVICE, useClass: AppointmentNavigationService },
    { provide: CHAT_PARTNER_METADATA_SERVICE, useClass: PharmacyChatPartnerMetadataService },
    { provide: LOGIN_ROUTES, useValue: loginRoutes },
    { provide: PHARMACY_ROUTES, useValue: pharmacyRoutes },
    { provide: USER_CONSENT_SERVICE, useClass: UserConsentService },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
}).catch((err) => {
  console.log(err);
  window.location.href = './browserNotSupported.html';
});
