import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CommonState } from '../../../store/src/common-store/common.state';
import { selectIsConsentGiven } from '../../../store/src/pharmacy/user-consent/user-consent.selectors';
import { UserConsentState } from '../../../store/src/pharmacy/user-consent/user-consent.state';
import { UserConsentServiceInterface } from '../../../essentials/types/src/service-interfaces/user-consent.service.interface';

@Injectable()
export class UserConsentService implements UserConsentServiceInterface {
  constructor(private store: Store<CommonState & { userConsent: UserConsentState }>) {}

  isChatPartnerConsentGiven(userCognitoId: string): Observable<boolean | undefined> {
    return this.store.select(selectIsConsentGiven(userCognitoId));
  }
}
