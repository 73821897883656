import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommonState } from '../../../../../store/src/common-store/common.state';
import { denyGoogleAnalyticsCookies } from '../../../../../store/src/common-store/other/actions/cookie-banner.actions';
import { CustomModalController } from '../../../../ui-components/src/ionic/controllers/custom-modal.controller';
import { ColorScheme } from '../legal-text-container/legal-text-container.component';

@Component({
  selector: 'common-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  standalone: true,
})
export class PrivacyPolicyComponent {
  @Input() colorScheme: ColorScheme | undefined;

  constructor(
    private modalController: CustomModalController,
    private store: Store<CommonState>
  ) {}

  public denyGoogleAnalytics() {
    this.store.dispatch(denyGoogleAnalyticsCookies());
    this.modalController.dismiss();
  }
}
