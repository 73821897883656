import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

export const enum AuthOrigin {
  LOGIN = 'login',
  SETTINGS_ENDUSER = 'settings_enduser',
}

export interface UsernamePassword {
  username: string;
  password?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthStateService {
  credentials$ = new BehaviorSubject<UsernamePassword>({ username: '' });
  accountVerificationRequired$ = new BehaviorSubject<boolean>(false);
  accountVerificationType$ = new ReplaySubject<undefined | 'email' | 'phone_number'>(1);
  cognitoUser$ = new BehaviorSubject<any>(undefined);

  constructor() {}

  resetCredentials() {
    this.credentials$.next({ username: '' });
  }
}
