import { Component, Input } from '@angular/core';
import { ColorScheme } from '../legal-text-container/legal-text-container.component';

@Component({
  selector: 'pharmacy-license-and-tos',
  templateUrl: './pharmacy-license-and-tos.component.html',
  standalone: true,
})
export class PharmacyLicenseAndTosComponent {
  @Input() colorScheme: ColorScheme | undefined;
}
