import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { CommonState } from '../../../../store/src/common-store/common.state';
import {
  finishAppointmentBooking,
  openAppointmentBookingModal,
} from '../../../../store/src/web/appointments/appointment.actions';
import { CustomModalController } from '../../../../common/ui-components/src/ionic/controllers/custom-modal.controller';
import { AppointmentSelectionNavComponent } from '../appointment-booking/appointment-selection-nav.component';

@Injectable()
export class AppointmentEffects {
  openAppointmentBookingModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openAppointmentBookingModal),
        tap(async () => {
          const modal = await this.modalCtrl.create({ component: AppointmentSelectionNavComponent });
          await modal.present();
          await modal.onDidDismiss();
          this.store.dispatch(finishAppointmentBooking({ bookingSuccess: false }));
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private modalCtrl: CustomModalController,
    private store: Store<CommonState>
  ) {}
}
