import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MeaUser } from '../../../../essentials/types/src/chatUser';
import { CommonState } from '../../../../store/src/common-store/common.state';
import {
  selectCognitoId,
  selectIsRegistered,
  selectUser,
  selectUserHadInitialLoad,
} from '../../../../store/src/common-store/user-store/selectors/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly loadedCognitoId$: Observable<string | null>;
  readonly loadedUser$: Observable<MeaUser | null>;
  readonly loadedIsRegistered$: Observable<boolean>;

  constructor(private store: Store<CommonState>) {
    this.loadedCognitoId$ = combineLatest([
      this.store.select(selectCognitoId),
      this.store.select(selectUserHadInitialLoad),
    ]).pipe(
      filter(([_, userHadInitialLoad]) => userHadInitialLoad),
      map(([cognitoId]) => cognitoId)
    );

    this.loadedIsRegistered$ = combineLatest([
      this.store.select(selectIsRegistered),
      this.store.select(selectUserHadInitialLoad),
    ]).pipe(
      filter(([_, userHadInitialLoad]) => userHadInitialLoad),
      map(([isRegistered]) => !!isRegistered)
    );

    this.loadedUser$ = combineLatest([this.store.select(selectUser), this.store.select(selectUserHadInitialLoad)]).pipe(
      filter(([_, userHadInitialLoad]) => userHadInitialLoad),
      map(([user]) => user)
    );
  }
}
