import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LegalTextType } from '../../../../essentials/types/src/legalTextType';
import { ImprintComponent } from './legal-info/imprint.component';
import { ColorScheme, LegalTextContainerComponent } from './legal-text-container/legal-text-container.component';
import { PharmacyLicenseAndTosComponent } from './pharmacy-tos/pharmacy-license-and-tos.component';
import { PrivacyPolicyComponent } from './privacy/privacy-policy.component';
import { TermsOfServiceComponent } from './tos/terms-of-service.component';

@Component({
  selector: 'legal-text',
  templateUrl: './legal-text.component.html',
  standalone: true,
  imports: [
    LegalTextContainerComponent,
    ImprintComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    PharmacyLicenseAndTosComponent,
  ],
})
export class LegalTextComponent {
  @Input({ required: true }) type!: LegalTextType;
  @Input() colorScheme: ColorScheme | undefined;
  @Output() openSubPage = new EventEmitter<string>();
}
