import gql from 'graphql-tag';

export default gql`
  query getConversationIdsByDay($lastDays: Int!) {
    getConversationIdsByDay(lastDays: $lastDays) {
      date
      conversationIds
    }
  }
`;
