import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';
import { Textblock } from '../../../../../essentials/types/src/textblock';

export const loadTextblocksIfNotPresent = createAction(`${ActionType.Textblock}: Load text blocks if not present`);
export const setTextblocks = createAction(
  `${ActionType.Textblock}: Set text blocks`,
  props<{ textblocks: Textblock[] }>()
);
export const setSelectedTextblockId = createAction(
  `${ActionType.Textblock}: Set selected text block id`,
  props<{ textblockId: string }>()
);
export const createTextblock = createAction(
  `${ActionType.Textblock}: Create Textblock`,
  props<{ textblock: Textblock }>()
);
export const updateTextblock = createAction(
  `${ActionType.Textblock}: Update Textblock`,
  props<{ textblock: Textblock }>()
);
export const updateTextblockSuccess = createAction(
  `${ActionType.Textblock}: Update Textblock Success`,
  props<{ textblock: Textblock }>()
);
export const updateTextblockFailure = createAction(
  `${ActionType.Textblock}: Update Textblock Failure`,
  props<{ message: string }>()
);

export const deleteTextblock = createAction(
  `${ActionType.Textblock}: Delete Textblock`,
  props<{ textblockId: string }>()
);
export const deleteTextblockSuccess = createAction(
  `${ActionType.Textblock}: Delete Textblock Success`,
  props<{ textblockId: string }>()
);
export const deleteTextblockFailure = createAction(
  `${ActionType.Textblock}: Delete Textblock Failure`,
  props<{ message: string }>()
);

export const restoreTextblock = createAction(
  `${ActionType.Textblock}: Restore Textblock`,
  props<{ textblockId: string }>()
);
export const restoreTextblockSuccess = createAction(
  `${ActionType.Textblock}: Restore Textblock Success`,
  props<{ textblockId: string; idOfDefaultTextblock: string }>()
);
export const restoreTextblockFailure = createAction(
  `${ActionType.Textblock}: Restore Textblock Failure`,
  props<{ message: string }>()
);
