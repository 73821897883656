import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import Autolinker from 'autolinker';
import { IconNamePipe } from '../../../../../essentials/util/src/pipes/icon-name.pipe';

@Component({
  selector: 'mea-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  standalone: true,
  imports: [IonicModule, IconNamePipe],
})
export class LinkComponent implements OnChanges {
  @Input() icon: string | undefined;
  @Input() text: string | undefined;
  @Input() type: 'row' | 'column' = 'row';
  @Input() href: string | undefined;
  @Input() externalLink: string | undefined;

  innerHtml: SafeHtml | undefined;

  constructor(
    private sanitizer: DomSanitizer,
    private iconNamePipe: IconNamePipe
  ) {}

  ngOnChanges(): void {
    if (this.externalLink) {
      const iconHtml = this.icon ? `<ion-icon src="${this.iconNamePipe.transform(this.icon)}"></ion-icon>` : '';
      const textHtml = `<div class="mea-link-text">${this.text || this.externalLink}</div>`;
      const type = this.type;
      const replaceFn = (match: { buildTag: () => any }) => {
        const tag = match.buildTag();
        tag.addClass(`mea-link ${type}`);
        tag.setInnerHtml(iconHtml + textHtml);
        return tag;
      };

      this.innerHtml = this.sanitizer.bypassSecurityTrustHtml(
        Autolinker.link(this.externalLink, {
          urls: true,
          newWindow: true,
          stripPrefix: false,
          replaceFn,
        })
      );
    }
  }
}
