import gql from 'graphql-tag';
import { appointmentAttributes } from '../attributes';

export default gql`
  query getBookableAppointments($pharmacyCognitoId: ID!, $nextToken: String) {
    getBookableAppointments(pharmacyCognitoId: $pharmacyCognitoId, nextToken: $nextToken) {
      __typename
      appointments {
        ${appointmentAttributes}
      }
      nextToken
    }
  }
`;
