<ion-button
  (click)="onClick($event)"
  [disabled]="disabled || loading"
  [ngClass]="['mea-' + color, size]"
  [type]="buttonType"
  [fill]="fill"
  size="small"
>
  @if (loading) {
    <ion-spinner [ngClass]="size"></ion-spinner>
  } @else {
    <ion-icon [src]="icon | iconName" [ngClass]="[size, fill]" [attr.aria-label]="icon" slot="icon-only"></ion-icon>
  }
</ion-button>
