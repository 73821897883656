import { MeaUser } from '../chatUser';
import { PharmacyLoginDetails } from '../pharmacy';
import { SsoLoginParams } from '../ssoLoginParams';
import { InjectionToken } from '@angular/core';

export const SINGLE_SIGN_ON_SERVICE = new InjectionToken<SingleSignOnServiceInterface>('SINGLE_SIGN_ON_SERVICE');

export interface PharmacyLoginDetailsWithCustomerId extends PharmacyLoginDetails {
  customerId: string;
}

export type SignInResult =
  | { type: 'success' }
  | { type: 'no-pharmacy' }
  | { type: 'pharmacy-selection'; pharmacies: PharmacyLoginDetailsWithCustomerId[] };

export interface SingleSignOnServiceInterface {
  initKeycloakOnLogin(): Promise<void>;

  isPharmacyLoggedInWithSso(currentUser: MeaUser | null): Promise<boolean>;

  isSsoSessionExpired(): Promise<boolean>;

  logoutFromKeycloak(): Promise<void>;

  getKeycloakToken(): Promise<string | undefined>;

  resetKeycloakService(): void;

  signInWithKeycloak({
    redirectRoute,
    redirectAppRoute,
    clientId,
  }: {
    redirectRoute: string[];
    redirectAppRoute?: string;
    clientId?: string;
  }): Promise<SignInResult>;

  signIntoSconnectWithKeycloak({
    customerId,
    redirectRoute,
    redirectSconnectRoute,
  }: {
    customerId: string;
    redirectRoute: string[];
    redirectSconnectRoute: string[];
  }): Promise<{ type: 'success' }>;

  signIntoPharmacyWithKeycloak({
    ssoLoginParams,
    redirectRoute,
    redirectAppRoute,
  }: {
    ssoLoginParams: SsoLoginParams;
    redirectRoute: string[];
    redirectAppRoute?: string;
  }): Promise<SignInResult>;
}
