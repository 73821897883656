import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadStatus } from '../../../essentials/types/src/loadStatus';
import { AppointmentNavigationServiceInterface } from '../../../essentials/types/src/service-interfaces/appointment-navigation.service.interface';
import { selectActiveConversationId } from '../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { CommonState } from '../../../store/src/common-store/common.state';
import { initAppointments } from '../../../store/src/pharmacy/appointments/appointment.actions';
import {
  selectAppointmentLoadStatus,
  selectAppointments,
} from '../../../store/src/pharmacy/appointments/appointment.selectors';
import { AppointmentState } from '../../../store/src/pharmacy/appointments/appointment.state';

@Injectable()
export class AppointmentNavigationService implements AppointmentNavigationServiceInterface {
  appointmentExists$: Observable<boolean | undefined> = combineLatest([
    this.store.select(selectAppointments),
    this.store.select(selectAppointmentLoadStatus),
    this.store.select(selectActiveConversationId),
  ]).pipe(
    map(([appointments, appointmentLoadStatus, conversationId]) => {
      if (appointmentLoadStatus !== LoadStatus.UpToDate) {
        return undefined;
      }
      return !!appointments.find((appointment) => appointment.decryptedConversationId === conversationId);
    })
  );

  constructor(
    private router: Router,
    private store: Store<CommonState & { appointment: AppointmentState }>
  ) {
    this.store.dispatch(initAppointments());
  }

  async navigateToAppointment(dateTime?: string) {
    if (dateTime) {
      await this.router.navigate(['home', 'termine', dateTime]);
    } else {
      await this.router.navigate(['home', 'termine']);
    }
  }
}
