import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppointmentEncryptionService } from '../../../../common/resources/src/services/encryption/appointment-encryption.service';
import { EncryptionService } from '../../../../common/resources/src/services/encryption/encryption.service';
import { SubscriptionManagementService } from '../../../../common/resources/src/services/subscriptions/subscription-management.service';
import { CommonState } from '../../../../store/src/common-store/common.state';
import { startAppsyncSubscriptions } from '../../../../store/src/common-store/other/actions/subscription.actions';
import { selectCognitoId, selectUser } from '../../../../store/src/common-store/user-store/selectors/user.selectors';
import {
  deleteAppointment,
  initAppointments,
  loadAppointments,
  loadAppointmentsFailure,
  loadAppointmentsSuccess,
  setAppointment,
} from '../../../../store/src/pharmacy/appointments/appointment.actions';
import { selectAppointmentLoadStatus } from '../../../../store/src/pharmacy/appointments/appointment.selectors';
import { AppointmentState } from '../../../../store/src/pharmacy/appointments/appointment.state';
import { Appointment } from '../../../../essentials/types/src/appointment';
import { LoadStatus } from '../../../../essentials/types/src/loadStatus';
import { Logger } from '../../../../essentials/util/src/logger';
import { isNotNullOrUndefined } from '../../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { AppsyncPharmacyAppointmentService } from '../../services/appsync-pharmacy-appointment.service';

const logger = new Logger('AppointmentEffects');

@Injectable()
export class AppointmentEffects {
  initAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initAppointments),
      withLatestFrom(this.store.select(selectAppointmentLoadStatus)),
      filter(([_, appointmentLoadStatus]) =>
        [LoadStatus.Init, LoadStatus.Error, LoadStatus.Stale].includes(appointmentLoadStatus)
      ),
      map(() => loadAppointments())
    )
  );

  loadAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAppointments),
      mergeMap(async () => {
        try {
          const appointments = await this.appsyncPharmacyAppointmentService.getMyAppointments();
          const decryptedAppointments = await this.decryptAppointments(appointments);
          return loadAppointmentsSuccess({ appointments: decryptedAppointments });
        } catch (e) {
          logger.error('Error while loading appointments', e);
          return loadAppointmentsFailure();
        }
      })
    )
  );

  subscribeToAppointmentUpdates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadAppointmentsSuccess),
        withLatestFrom(this.store.select(selectCognitoId).pipe(isNotNullOrUndefined())),
        tap(([_, cognitoId]) => this.subscribeToAppointmentUpdates(cognitoId))
      ),
    { dispatch: false }
  );

  revalidateAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startAppsyncSubscriptions),
      withLatestFrom(this.store.select(selectAppointmentLoadStatus)),
      filter(([_, appointmentLoadStatus]) => LoadStatus.Stale === appointmentLoadStatus),
      map(() => loadAppointments())
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<CommonState & { appointment: AppointmentState }>,
    private encryptionService: EncryptionService,
    private appointmentEncryptionService: AppointmentEncryptionService,
    private appsyncPharmacyAppointmentService: AppsyncPharmacyAppointmentService,
    private subscriptionManagementService: SubscriptionManagementService
  ) {}

  private subscribeToAppointmentUpdates(cognitoId: string) {
    this.subscriptionManagementService.subscribe(
      'createdOrUpdatedAppointment',
      this.appsyncPharmacyAppointmentService.createdOrUpdatedAppointment(cognitoId),
      async (appointment) => {
        const decryptedAppointment = (await this.decryptAppointments([appointment]))[0] as Appointment;
        this.store.dispatch(setAppointment({ appointment: decryptedAppointment }));
      }
    );
    this.subscriptionManagementService.subscribe(
      'deletedAppointment',
      this.appsyncPharmacyAppointmentService.deletedAppointment(cognitoId),
      ({ dateTime }) => this.store.dispatch(deleteAppointment({ dateTime }))
    );
  }

  private async decryptAppointments(appointments: Appointment[]): Promise<Appointment[]> {
    const privateKey = await firstValueFrom(this.store.select(selectUser).pipe(map((user) => user?.privateKey)));
    if (!privateKey) {
      return appointments;
    }
    return appointments.map((appointment) => {
      if (appointment.bookedByName && appointment.bookedByNameIsEncrypted) {
        const decryptedName = this.appointmentEncryptionService.decryptString(
          appointment.bookedByName,
          appointment,
          privateKey
        );
        appointment.bookedByName = decryptedName || 'Name konnte nicht entschlüsselt werden';
      }
      if (appointment.encryptedConversationId) {
        const decryptedConversationId = this.appointmentEncryptionService.decryptString(
          appointment.encryptedConversationId,
          appointment,
          privateKey
        );
        appointment.decryptedConversationId = decryptedConversationId || undefined;
      }
      if (appointment.encryptedNote) {
        const decryptedNote = this.encryptionService.decryptUsingPrivateKey(appointment.encryptedNote, privateKey);
        appointment.decryptedNote = decryptedNote || 'Notiz konnte nicht entschlüsselt werden';
      }
      return appointment;
    });
  }
}
