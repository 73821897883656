import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import dayjs from 'dayjs';
import { firstValueFrom } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GoogleAnalyticsService } from '../../../../../../libs/common/resources/src/services/google-analytics.service';
import { openOrStartConversationWithPharmacy } from '../../../../../../libs/store/src/pharmacy/pharmacy-search/pharmacy-search.actions';
import { analyticsOpenPharmacySearchModal } from '../actions/analytics.actions';

@Injectable()
export class AnalyticsEffects {
  pharmacySearch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(analyticsOpenPharmacySearchModal),
        switchMap(async () => {
          const searchStartTime = dayjs();
          await firstValueFrom(this.actions$.pipe(ofType(openOrStartConversationWithPharmacy)));
          this.analyticsService.sendEvent(
            'User Interaktion',
            'Apotheke-zu-Apotheke Chat gestartet',
            undefined,
            dayjs().diff(searchStartTime, 'ms')
          );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private analyticsService: GoogleAnalyticsService
  ) {}
}
