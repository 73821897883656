import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Textblock } from '../../../../../essentials/types/src/textblock';
import { TextblockCategory } from '../../../../../essentials/types/src/textblockCategory';

@Component({
  selector: 'app-textblocks-list',
  templateUrl: './textblocks-list.component.html',
  styleUrls: ['./textblocks-list.component.scss'],
  standalone: true,
  imports: [IonicModule],
})
export class TextblocksListComponent {
  @Input({ required: true }) categorizedTextblocks!: { [category: string]: Textblock[] } | null;
  @Input() textblockCategories: TextblockCategory[] | null = null;
  @Input() selectedTextblockId: string | null = null;
  @Output() textblockClick = new EventEmitter<string>();

  textblockIsSelected(textblock: Textblock): boolean {
    return (
      textblock.id === this.selectedTextblockId ||
      !!(this.selectedTextblockId && textblock.idOfDefaultTextblock === this.selectedTextblockId)
    );
  }

  onTextblockClick(textblockId: string) {
    this.textblockClick.emit(textblockId);
  }
}
