import gql from 'graphql-tag';

export default gql`
  mutation createTextblock(
    $template: String!
    $title: String!
    $category: String!
    $idOfDefaultTextblock: String
    $isDeactivated: Boolean!
  ) {
    createTextblock(
      textblock: {
        template: $template
        title: $title
        category: $category
        idOfDefaultTextblock: $idOfDefaultTextblock
        isDeactivated: $isDeactivated
      }
    ) {
      __typename
      id
      template
      title
      category
      cognitoId
      idOfDefaultTextblock
      isDeactivated
    }
  }
`;
