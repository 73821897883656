import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import pickBy from 'lodash-es/pickBy';
import {
  Appointment,
  AppointmentTypeCategory,
  BackendAppointment,
  BookAppointmentInput,
} from '../../../../../essentials/types/src/appointment';
import { RemoveCommonChatDuettFields } from '../../../../../essentials/types/src/common-chat-duett-fields';
import { BookAppointment } from '../../../../../essentials/types/src/duett-schema/de.meineapotheke.chat/book_appointment/jsonschema/1-1-0';
import {
  getAppointmentTypeCategory,
  mapBackendAppointmentToAppointment,
} from '../../../../../essentials/util/src/appointment.util';
import { AppsyncErrorUtil } from '../../../../../essentials/util/src/appsync-error.util';
import { Logger } from '../../../../../essentials/util/src/logger';
import { CommonState } from '../../../../../store/src/common-store/common.state';
import { analyticsDuettEvent } from '../../../../../store/src/common-store/other/actions/common-analytics.actions';
import bookAppointment from '../../graphql/mutations/bookAppointment';
import getBookableAppointments from '../../graphql/queries/getBookableAppointments';
import { AppsyncService, AppsyncServiceClient } from './appsync.service';

const logger = new Logger('AppsyncAppointmentService');

@Injectable({ providedIn: 'root' })
export class AppsyncCommonAppointmentService {
  constructor(
    private appSync: AppsyncService,
    private store: Store<CommonState>
  ) {}

  // ************* Queries *************

  async getBookableAppointments(pharmacyCognitoId: string): Promise<Appointment[]> {
    const client = await this.appSync.getClient();

    let next: string | undefined;
    const backendAppointments: BackendAppointment[] = [];
    do {
      const { appointments, nextToken } = await this.getPaginatedBookableAppointments(client, pharmacyCognitoId, next);
      backendAppointments.push(...appointments);
      next = nextToken;
    } while (next);
    return backendAppointments.map(mapBackendAppointmentToAppointment);
  }

  // ************* Mutations *************

  async bookAppointment(bookAppointmentInput: BookAppointmentInput) {
    const client = await this.appSync.getClient();
    const variables = pickBy(bookAppointmentInput);

    await client.mutate({
      mutation: bookAppointment,
      variables,
    });
    this.trackBookAppointmentEvent(variables.selectedAppointmentType);
  }

  // ************* Helpers *************

  private getPaginatedBookableAppointments = async (
    client: AppsyncServiceClient,
    pharmacyCognitoId: string,
    nextToken: string | undefined
  ): Promise<{ appointments: BackendAppointment[]; nextToken: string | undefined }> => {
    let data;
    try {
      data = (
        await client.query({
          query: getBookableAppointments,
          variables: { pharmacyCognitoId, nextToken },
        })
      ).data;
    } catch (err) {
      if (
        AppsyncErrorUtil.isAppsyncError(err) &&
        err.errors.every((error) => AppsyncErrorUtil.isElasticSearchNotFoundError(error))
      ) {
        data = err.data;
      } else {
        logger.error('Unexpected error retrieving appointments', err);
      }
    }
    if (data) {
      return {
        appointments: data.getBookableAppointments.appointments,
        nextToken: data.getBookableAppointments.nextToken,
      };
    } else {
      return { appointments: [], nextToken: undefined };
    }
  };

  // ************* Tracking *************

  private trackBookAppointmentEvent(appointmentType?: string) {
    const event: RemoveCommonChatDuettFields<BookAppointment> = {
      event_type: 'book_appointment',
      appointment_category: appointmentType
        ? getAppointmentTypeCategory(appointmentType)
        : AppointmentTypeCategory.DEFAULT,
    };
    this.store.dispatch(analyticsDuettEvent({ event }));
  }
}
