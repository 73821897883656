import { Action, createReducer, on } from '@ngrx/store';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import { newBackendMessage } from '../../../../../../../libs/store/src/common-store/chat-store/actions/chat-message.actions';
import { BackendMessage } from '../../../../../../../libs/essentials/types/src/backendMessage';
import { resetHomeState } from '../../../store/actions/app.actions';
import { ConversationIdsByDay } from '../../types/conversationIdsByDay';
import {
  loadConversationIdsByDayFailure,
  loadConversationIdsByDaySuccess,
  setSoundNotification,
  setSoundNotificationFromLocalStorage,
  setSoundRepetition,
  setSoundRepetitionFromLocalStorage,
  setWidgetFeatures,
  setWidgetWebsiteMaintainer,
} from '../actions/home.actions';
import { HomeState } from '../state/home.state';

export const initialHomeState: HomeState = {
  errorMessage: '',
  conversationIdsByDay: [],
  settings: {
    soundNotification: false,
    soundRepetition: -1,
  },
  extensions: {
    widget: {
      features: 'CHAT',
      websiteMaintainer: null,
    },
  },
};

const _homeReducer = createReducer(
  initialHomeState,

  on(loadConversationIdsByDaySuccess, (state: HomeState, { conversationIdsByDay }) => ({
    ...state,
    conversationIdsByDay,
  })),

  on(loadConversationIdsByDayFailure, (state: HomeState, { errorMessage }) => ({ ...state, errorMessage })),

  on(newBackendMessage, (state: HomeState, { message }) => ({
    ...state,
    conversationIdsByDay: updateConversationIdsByDay(state.conversationIdsByDay, message),
  })),

  on(resetHomeState, () => initialHomeState),

  on(setSoundNotification, setSoundNotificationFromLocalStorage, (state: HomeState, { soundNotification }) =>
    update(state, { settings: { soundNotification: { $set: soundNotification } } })
  ),

  on(setSoundRepetition, setSoundRepetitionFromLocalStorage, (state: HomeState, { soundRepetition }) =>
    update(state, { settings: { soundRepetition: { $set: soundRepetition } } })
  ),

  on(setWidgetFeatures, (state: HomeState, { widgetFeatures }) =>
    update(state, {
      extensions: {
        widget: {
          features: { $set: widgetFeatures },
        },
      },
    })
  ),

  on(setWidgetWebsiteMaintainer, (state: HomeState, { widgetWebsiteMaintainer }) =>
    update(state, {
      extensions: {
        widget: {
          websiteMaintainer: { $set: widgetWebsiteMaintainer },
        },
      },
    })
  )
);

export function homeReducer(state: HomeState | undefined, action: Action): HomeState {
  return _homeReducer(state, action);
}

function updateConversationIdsByDay(
  conversationIdsByDay: ConversationIdsByDay[],
  message: BackendMessage
): ConversationIdsByDay[] {
  const dateString = dayjs.unix(message.createdAt).format('YYYY-MM-DD');

  const newConversationIdsByDay = [...conversationIdsByDay];

  const dateIndex = conversationIdsByDay.findIndex((entry) => entry.date === dateString);

  if (dateIndex === -1) {
    newConversationIdsByDay.push({ date: dateString, conversationIds: new Set<string>(message.conversationId) });
  } else {
    (newConversationIdsByDay[dateIndex] as ConversationIdsByDay).conversationIds.add(message.conversationId);
  }

  return newConversationIdsByDay;
}
