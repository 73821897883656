import { PharmacyRoutesInterface } from '../../../../libs/pharmacy-only/src/interfaces/pharmacy-routes-interface';
import { LoginRoutesInterface } from '../../../../libs/pharmacy-only/src/services/pharmacy-signin.service';
import { SearchRoutesInterface } from '../../../../libs/pharmacy-only/src/store/pharmacy-search/pharmacy-search.effects';

export enum PharmacyRoute {
  Auth = 'auth',
  SsoRedirect = 'sanacorp-login',
  Login = 'login',
  ResetPassword = 'resetpassword',
  SetPassword = 'setpassword',
  Backup = 'sicherung',
  Restore = 'freigabe',
  Home = 'home',
  Dashboard = 'uebersicht',
  Chat = 'meadirekt-chat',
  Settings = 'einstellungen',
  PharmacyDetails = 'meine-apothekendaten',
  PharmacyImages = 'meine-bilddaten',
  PharmacyOpeningHours = 'meine-oeffnungszeiten',
  ManageDemoPharmacy = 'demo-apotheke-verwalten',
  Security = 'sicherheit-login',
  Notifications = 'benachrichtigungen',
  InitialMessages = 'initialnachrichten',
  TermsOfServicePharmacy = 'nutzungs-lizenzbedingungen-apotheke',
  Textblocks = 'textbausteine',
  Marketing = 'werbematerial',
  AppMarketing = 'app-werbematerial',
  CardLinkMarketing = 'card-link-werbematerial',
  CardLink = 'cardlink',
  Extensions = 'erweiterungen',
  VideoCall = 'video-kommunikation',
  AppointmentsExtension = 'terminbuchung',
  Widgets = 'widgets',
  Analytics = 'analyse',
  Info = 'info',
  DataProtectionEnduser = 'datenschutz-endkunde',
  TermsOfServiceEnduser = 'nutzungsbedingungen-endkunde',
  Imprint = 'impressum',
  Appointments = 'termine',
}

export const PharmacyPage = {
  textblockSettings: [PharmacyRoute.Home, PharmacyRoute.Settings, PharmacyRoute.Textblocks],
  dashboard: [PharmacyRoute.Home, PharmacyRoute.Dashboard],
  chat: [PharmacyRoute.Home, PharmacyRoute.Chat],
  marketing: [PharmacyRoute.Home, PharmacyRoute.Marketing],
  extensions: [PharmacyRoute.Home, PharmacyRoute.Extensions],
  analytics: [PharmacyRoute.Home, PharmacyRoute.Analytics],
  info: [PharmacyRoute.Home, PharmacyRoute.Info],
  settings: [PharmacyRoute.Home, PharmacyRoute.Settings],
  login: [PharmacyRoute.Auth, PharmacyRoute.Login],
  appointments: [PharmacyRoute.Home, PharmacyRoute.Appointments],
  appointmentsExtension: [PharmacyRoute.Home, PharmacyRoute.Extensions, PharmacyRoute.AppointmentsExtension],
  getChatPage: (conversationId: string) => [PharmacyRoute.Home, PharmacyRoute.Chat, conversationId],
};

export const loginRoutes: LoginRoutesInterface = {
  home: [PharmacyRoute.Home],
  login: [PharmacyRoute.Auth, PharmacyRoute.Login],
  backup: [PharmacyRoute.Auth, PharmacyRoute.Backup],
  restore: [PharmacyRoute.Auth, PharmacyRoute.Restore],
  getChatPage: (conversationId: string) => [PharmacyRoute.Home, PharmacyRoute.Chat, conversationId],
};

export const searchRoutes: SearchRoutesInterface = {
  getChatPage: (conversationId: string) => [PharmacyRoute.Home, PharmacyRoute.Chat, conversationId],
};

export const pharmacyRoutes: PharmacyRoutesInterface = {
  textblockSettings: PharmacyPage.textblockSettings,
};

export const marketingRoutes = {
  appMarketing: [PharmacyRoute.Home, PharmacyRoute.Marketing, PharmacyRoute.AppMarketing],
  cardLinkMarketing: [PharmacyRoute.Home, PharmacyRoute.Marketing, PharmacyRoute.CardLinkMarketing],
};
