import { ErrorHandler, Injectable } from '@angular/core';
import { Logger } from '../../../essentials/util/src/logger';

const logger = new Logger('GlobalErrorHandler');

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: Error) {
    logger.error('global error:', error);

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }
}
