<div>
  <h2>MEADIREKT - CHAT</h2>
  <h6>Stand: 01.02.2023</h6>
  <br />
  <h4>§ 1 Gegenstand der NUTZUNGSBEDINGUNGEN / Beschreibung von MEADIREKT-CHAT</h4>
  <ol>
    <li>
      Die nachfolgenden NUTZUNGSBEDINGUNGEN regeln die Inhalte und Bedingungen der Nutzung der Webapplikation
      MEADIREKT-CHAT (nachfolgend: „<b>MEADIREKT-CHAT</b>“). MEADIREKT-CHAT ist Teil des Kooperationsprogrammes „mea® –
      meine Apotheke“ (<a href="https://www.meineapotheke.de/">www.meineapotheke.de</a>) (nachfolgend „<b
        >MEA KOOPERATION</b
      >“). Die Sanacorp Pharmahandel GmbH (nachfolgend: „<b>SANACORP</b>“) stellt die technische Infrastruktur und die
      Software für den MEADIREKT-CHAT zur Nutzung durch Apotheken (nachfolgend „<b>APOTHEKEN</b>“<sup>1</sup>) und deren
      Kunden (nachfolgende „<b>ENDKUNDEN</b>“) zur Verfügung. Die NUTZUNGSBEDINGUNGEN regeln die Nutzung durch den
      ENDKUNDEN und gelten im Verhältnis zwischen (a) SANACORP und den ENDKUNDEN und (b) zwischen den beteiligten
      APOTHEKERN und den ENDKUNDEN.
      <p>
        <sup>1</sup> Aus Gründen der einfacheren Lesbarkeit wird im Folgenden auf die geschlechtsneutrale
        Differenzierung (z.B. Apotheker/in) verzichtet. Entsprechende Begriffe gelten im Sinne der Gleichbehandlung für
        alle Geschlechter.
      </p>
    </li>
    <li>
      Bei der Webapplikation MEADIREKT-CHAT handelt es sich um ein innovatives Kommunikationsmittel zwischen Apotheke
      und ENDKUNDE. Ziel von MEADIREKT-CHAT ist die Erleichterung der Kommunikation zwischen dem APOTHEKER und
      ENDKUNDEN. MEADIREKT-CHAT beschränkt sich dabei auf die schlichte Erleichterung der Kommunikation bzw. eine
      Erweiterung der in Betracht kommenden Kommunikationsmittel.
    </li>
    <li>
      Zur Kommunikation über MEADIREKT-CHAT stehen dem ENDKUNDEN diverse APOTHEKER zur Verfügung, welche sich an
      MEADIREKT-CHAT beteiligen. Der ENDKUNDE wählt aus, mit welcher APOTHEKE er chatten bzw. kommunizieren möchte. Er
      hat die Möglichkeit, gezielt nach einer APOTHEKE zu suchen bzw. sich nach bestimmten Paramatern (Umkreissuche,
      PLZ-Suche) APOTHEKER anzeigen zu lassen, welche sich an MEADIREKT-CHAT beteiligen. Die Hoheit und die Entscheidung
      über die Auswahl der APOTHEKE, mit welcher er kommuniziert bzw. chattet, liegt stets und zu jedem Zeitpunkt beim
      ENDKUNDEN. Nur er bestimmt, mit welcher APOTHEKE er kommuniziert und welche APOTHEKE er zur Abgabe von
      Arzneimitteln, Medizinprodukten oder sonstigen Produkten aufsucht bzw. auswählt.
    </li>
    <li>
      MEADIREKT-CHAT ermöglicht ENDKUNDEN eine Kommunikation mit APOTHEKERN. Der Gegenstand und der Umfang der
      Kommunikation richtet sich nach den jeweils geltenden regulatorischen Vorgaben – insbesondere nach den Vorgaben
      des Arzneimittelgesetzes (AMG), des Apothekengesetzes (ApoG), der Apothekenbetriebsordnung (ApBetrO) und des
      Heilmittelwerberechts (HWG). Die Einhaltung der relevanten Vorgaben durch die APOTHEKE ist in jedem konkreten
      Einzelfall zwingend und liegt im ausschließlichen Verantwortungsbereich der APOTHEKE. Hierzu haben sich die
      beteiligten APOTHEKER gegenüber SANACORP in einer gesonderten Vereinbarung verpflichtet. SANACORP ist –
      insbesondere gegenüber den ENDKUNDEN – nicht verpflichtet bzw. nicht in der Lage, die Einhaltung der
      regulatorischen Vorgaben zu überwachen bzw. zu kontrollieren.
    </li>
    <li>
      Typische Anwendungsbereiche von MEADIREKT-CHAT sind die Abfrage der Verfügbarkeit bestimmter Arzneimittel bzw.
      sonstiger Produkte aus dem Bereich des Sortiments einer Apotheke (Arzneimittel und sonstige Produkte),
      Vorbestellungen und Reservierungen sowie eine Kommunikation zu Fragestellungen (im Nachgang) zur Abgabe von
      Arzneimitteln (Wechsel- und Nebenwirkungen, Haltbarkeit, Aufbewahrung, Dosierung etc.). Die Anwendung bzw.
      Verwendung von MEADIREKT-CHAT zu anderen privaten bzw. gewerblichen Zwecken ist untersagt.
    </li>
    <li>
      Die Kommunikation über MEADIREKT-CHAT kann im Wege von Text erfolgen. Zudem kann der ENDKUNDE der APOTHEKE
      Bilddokumente und/oder Textdokumente (z.B. Rezepte) übermitteln. Als Webapplikation kann MEADIREKT-CHAT über jeden
      PC mit Internetanschluss bzw. über mobile Endgeräte (Smartphones, Tablets) genutzt werden.
    </li>
    <li>
      Lediglich zur Klarstellung wird darauf hingewiesen, dass sich SANACORP auf die Bereitstellung und den Betrieb von
      MEADIREKT-CHAT als Kommunikationsplattform beschränkt und in die Kommunikation über den Chat, den Prozess der
      Bestellung bzw. Abgabe von apothekenpflichtigen Arzneimitteln und/oder sonstigen apothekenüblichen Produkten nicht
      eingebunden ist und hierüber auch keine Informationen erlangt. SANACORP wird ausschließlich als Dienstleister für
      die Apotheken tätig. Die Verarbeitung von Daten durch die APOTHEKE (insbesondere im Zusammenhang mit der
      Kommunikation über den Chat, (Vor-)Bestellung von apothekenpflichtigen Arzneimitteln und/oder sonstigen
      apothekenüblichen Produkten) unter Nutzung der durch Sanacorp zur Verfügung gestellten technischen Infrastruktur
      und Software erfolgt ausschließlich durch die jeweilige ausgewählte APOTHEKE zu den jeweils verlinkten bzw.
      angegebenen Datenschutzbestimmungen der APOTHEKE.
    </li>
    <li>
      Die Kommunikation im Rahmen von MEADIREKT-CHAT findet ausschließlich zwischen dem ENDKUNDEN und der jeweiligen
      APOTHEKE statt. SANACORP ist hierin weder direkt noch indirekt eingebunden. In dem Betrieb von MEADIREKT-CHAT
      liegt insbesondere keine Vermittlung von pharmazeutischen Beratungsleistungen, ENDKUNDEN oder Kaufverträgen über
      Arzneimittel bzw. sonstige Produkte aus dem Bereich des Apothekensortiments. Vertragliche Verpflichtungen im
      Zusammenhang mit der Abgabe bzw. dem Verkauf von Arzneimitteln bzw. sonstiger Produkte bzw. über sonstige
      Beratungs- und/oder Dienstleistungen aus dem Bereich der APOTHEKEN werden – je nach konkretem Einzelfall –
      ausschließlich zwischen der Apotheke und dem ENDKUNDEN begründet. Insoweit sind die konkret zwischen der Apotheke
      und dem ENDKUNDEN getroffenen Vereinbarungen maßgeblich.
    </li>
    <li>
      Die Eröffnung und die Unterhaltung eines Accounts erfolgt mittels der Eingehung eines entsprechenden
      Nutzungsvertrages zwischen ENDKUNDEN und SANACORP wie unter §2 Nr. 4 näher beschrieben. Die Nutzung des Accounts
      unterliegt diesen NUTZUNGSBEDINGUNGEN und der MEADIREKT DATENSCHUTZERKLÄRUNG.
    </li>
  </ol>
  <h4>§ 2 Nutzung von MEADIREKT-CHAT</h4>
  <ol>
    <li>
      <p class="only-margin-bot">
        <b>Erreichbarkeit bzw. Zugang zu MEADIREKT-CHAT</b>
      </p>
      <ol>
        <li>
          Der ENDKUNDE kann MEADIREKT-CHAT insbesondere über die Website
          <a href="https://www.meineapotheke.de/meadirekt-chat">https://www.meineapotheke.de/meadirekt-chat</a> bzw.
          über individuelle QR-Codes der beteiligten APOTHEKER erreichen. Die Website kann über einen internetfähigen PC
          bzw. ein mobiles Endgerät mit Internetzugang (Tablet bzw. Smartphone) erreicht werden.
        </li>
        <li>
          Zur Nutzung von MEADIREKT-CHAT über einen PC oder ein mobiles Endgerät müssen folgende Systemvoraussetzungen
          erfüllt sein:
          <ol>
            <li>
              Geräte
              <ul>
                <li>Geräte mit Internetzugang und einem unterstützten Browser</li>
              </ul>
            </li>
            <li>
              Browser
              <ul>
                <li>Chrome, Safari, Edge in der jeweils aktuellen Version</li>
              </ul>
            </li>
            <li>
              Betriebssysteme
              <ul>
                <li>Windows 7 oder neuer</li>
                <li>Android 8 oder neuer</li>
                <li>iOS 14.1 oder neuer</li>
                <li>macOS 10.14 oder neuer</li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          Der ENDKUNDE wird darauf hingewiesen, dass sich die Systemvoraussetzungen zu gegebener Zeit ändern können bzw.
          die Nutzung von MEADIREKT-CHAT die Installation von zusätzlichen Software-Komponenten (ggf. auch von
          Drittanbietern) erforderlich machen kann. Der ENDKUNDE kann jeweils die aktuellste Version von MEADIREKT-CHAT
          nutzen. Ein Anspruch bzw. eine Möglichkeit zur Nutzung von MEADIREKT-CHAT in Vorgängerversionen besteht nicht.
        </li>
      </ol>
    </li>
    <li>
      <p class="only-margin-bot">
        <b>Keine Garantie der Verfügbarkeit bzw. Nutzbarkeit von MEADIREKT-CHAT</b>
      </p>
      <ol>
        <li>
          <p class="only-margin-bot">
            Es ist beabsichtigt, MEADIREKT-CHAT als Kommunikationsplattform zwischen APOTHEKERN und ENDKUNDEN
            grundsätzlich an 365 Kalendertagen im Jahr über 24 Stunden zur Verfügung zu stellen. Eine ununterbrochene
            bzw. lückenlose (technische) Verfügbarkeit kann jedoch nicht garantiert werden.
          </p>
          Dies gilt insbesondere auch in Bezug auf Umstände, welche nicht in den Verantwortungsbereich von SANACORP bzw.
          der beteiligten APOTHEKEN fallen und für die Nichtverfügbarkeit ursächlich sind (z.B. Stromausfall, Fehler
          bzw. Inkonnentivität im Datenübertragungsnetz, Störungen im Bereich der Hardware von SANACORP bzw. den
          beteiligten APOTHEKEN).
        </li>
        <li>
          <p class="only-margin-bot">
            SANACORP beschränkt sich auf die Bereitstellung und den Betrieb von MEADIREKT-CHAT als
            Kommunikationsplattform. Ob und in welchem Umfang sich APOTHEKER für eine Nutzung von MEADIREKT-CHAT im
            Verhältnis zu ENDKUNDEN entscheiden, kann SANACORP nicht beurteilen. Über die generelle Nutzung, die Nutzung
            im Einzelfall im Verhältnis zu bestimmten ENDKUNDEN, die Art und den Umfang der generellen Nutzung bzw. der
            Nutzung im Einzelfall entscheidet ausschließlich die APOTHEKE. SANACORP kann deshalb insbesondere nicht
            gewährleisten, dass
          </p>
          <ol>
            <li>MEADIREKT-CHAT von APOTHEKERN aktiv bzw. passiv genutzt wird,</li>
            <li>
              MEADIREKT-CHAT von APOTHEKERN aus bestimmten Regionen oder Postleistzahlenbezirken aktiv bzw. passiv
              genutzt wird,
            </li>
            <li>
              die beteiligten APOTHEKER MEADIREKT-CHAT dauerhaft bzw. für einen bestimmten Zeitraum als
              Kommunikationsmittel nutzen,
            </li>
            <li>
              die beteiligten APOTHEKER alle bzw. bestimmte Anfragen über MEADIREKT-CHAT beantworten bzw. ausführen
              und/oder
            </li>
            <li>
              die beteiligten APOTHEKER Anfragen jedes ENDKUNDEN beantworten bzw. innerhalb einer bestimmten
              Antwortfrist bearbeiten.
            </li>
          </ol>
        </li>
        <li>
          Nichtverfügbarkeiten i.S.v. Ziff. 2.1. und Nichterreichbarkeiten i.S.v. Ziff. 2.2 stellen keine Be- oder
          Einschränkung des vertraglich vereinbarten Leistungsumfanges dar. Gleiches gilt für notwendige bzw. für
          notwendig erachtete Wartungsarbeiten bei SANACORP bzw. bei den teilnehmenden APOTHEKEN.
        </li>
        <li>
          SANACORP VERWENDET IM ZUSAMMENHANG MIT DER BEREITSTELLUNG VON MEADIREKT-CHAT UND DER GEWÄHRLEISTUNG EINER
          FEHLERFREIEN UND SICHEREN UMGEBUNG ANGEMESSENE KENNTNISSE UND SORGFALT. SANACORP KANN JEDOCH NICHT
          GARANTIEREN, DASS MEADIREKT-CHAT STETS UND OHNE UNTERBRECHUNGEN , VERZÖGERUNGEN ODER MÄNGEL FUNKTIONIEREN
          WIRD. SANACORP STELLT MEADIREKT-CHAT ALS DIENST WIE ER IST („AS IS“) OHNE JEGLICHE AUSDRÜCKLICHE ODER
          KONKLUDENTE GEWÄHRLEISTUNG BEREIT.
        </li>
        <li>
          <p class="only-margin-bot">
            Lediglich zur Klarstellung wird darauf hingewiesen, dass der ENDKUNDE keinen Anspruch auf folgende
            Leistungen hat:
          </p>
          <ol>
            <li>
              Anpassung von MEADIREKT-CHAT an eine geänderte Hard- oder Softwareumgebung einschließlich der Anpassung an
              veränderte Betriebssysteme,
            </li>
            <li>Anpassung von MEADIREKT-CHAT an gesetzliche, behördliche oder hoheitliche Anforderungen,</li>
            <li>
              <p class="only-margin-bot">
                Beseitigung von Fehlern aus dem Risikobereich der APOTHEKEN bzw. des ENDKUNDEN – hierzu gehören
                insbesondere Fehler, welche verursacht wurden,
              </p>
              <ol type="a">
                <li>durch unsachgemäße Bedienung oder Veränderung der Webapplikation und/oder</li>
                <li>
                  durch Verwendung fehlerhafter oder beschädigter Hardware, anomaler Betriebsbedienungen, Ausfall der
                  Stromversorgung oder datenführender Leitungen.
                </li>
              </ol>
            </li>
            <li>
              Zur Klarstellung wird darauf hingewiesen, dass die unter Ziff. 2.5.1. bis 2.5.3. enthaltene Aufzählung
              nicht abschließend ist. Aus der fehlenden Aufführung von Leistungen kann nicht geschlossen werden, dass
              diese Leistungen Gegenstand von vertraglichen Pflichten von SANACORP sind.
            </li>
          </ol>
        </li>
      </ol>
    </li>
    <li>
      <p class="only-margin-bot">
        <b>Nutzung zur allgemeinen Apothekensuche </b>
      </p>
      MEADIREKT-CHAT kann zur Suche von APOTHEKEN, welche an der MEA KOOPERATION teilnehmen,sowie zur Suche von
      notdiensthabenden Apotheken genutzt werden. Diese Möglichkeit besteht für Gastnutzer und registrierte Nutzer.
      Nutzer werden hierzu auf das Portal der ABDA – Bundesvereinigung Deutscher Apothekerverbände e.V. weitergeleitet.
      Im Rahmen der Apothekensuche kann der ENDKUNDE nach allen öffentlichen APOTHEKEN in Deutschland suchen. Die Suche
      bzw. die Suchmöglichkeiten sind nicht auf diejenigen APOTHEKEN beschränkt, welche sich an MEADIREKT-CHAT
      beteiligen. Die Nutzung zur allgemeinen Apothekensuche ist unabhängig von der Nutzung von MEADIREKT-CHAT als
      Kommunikationsmittel gegenüber beteiligten APOTHEKEN.
    </li>
    <li>
      <p class="only-margin-bot">
        <b>Nutzung als Gast bzw. registrierter Nutzung </b>
      </p>
      <p class="only-margin-bot">
        Die Nutzung von MEADIREKT-CHAT ist über einen Zugang als Gastnutzer bzw. als registrierter Nutzer möglich:
      </p>
      <ol>
        <li>
          <p class="only-margin-bot">Nutzung als Gast</p>
          Die Nutzung von MEADIREKT-CHAT ist über einen Gastzugang („Gastnutzer“) möglich. In diesem Fall setzt die
          Nutzung keine Registrierung voraus. Erforderlich ist lediglich eine Einwilligung in die Verarbeitung der
          relevanten Kundendaten durch SANACORP bzw. die beteiligten APOTHEKEN durch Abgabe einer entsprechenden
          Einwilligungserklärung.
        </li>
        <li>
          <p class="only-margin-bot">Nutzung als registrierter Nutzer</p>
          <p class="only-margin-bot">
            Zur Nutzung von MEADIREKT-CHAT kann sich der ENDKUNDE registrieren. Zur Registrierung ist aktuell
            insbesondere die Angabe korrekter Daten erforderlich. Nach erfolgreicher Registrierung kann der ENDKUNDE
            MEADIREKT-CHAT als registrierter Nutzer nutzen. Neben der Eröffnung eines Benutzerkontos ist die Abgabe
            einer Einwilligung in die Verarbeitung der relevanten Kundendaten durch SANACORP bzw. die beteiligten
            APOTHEKER durch Abgabe einer entsprechenden Einwilligungserklärung erforderlich. Eine Registrierung bietet
            im Vergleich zur Nutzung von MEADIREKT-CHAT als Gastnutzer insbesondere folgende Vorteile:
          </p>
          <ol>
            <li>Zugriff auf Chats und Chatverläufe</li>
            <li>Speicherung von APOTHEKEN als Favoriten</li>
          </ol>
          <p class="only-margin-top">Die als Gastnutzer geführten Chats bleiben nach einer Registrierung erhalten.</p>
        </li>
        <li>
          <p class="only-margin-bot">Einräumung von Nutzungsrecht</p>
          SANACORP räumt dem ENDKUNDEN für den Zeitraum der Geltung dieser NUTZUNGSBEDINGUNGEN eine beschränkte,
          widerrufliche, nicht-exklusive, nicht unterlizenzierbare und nicht-übertragbare Lizenz zur Nutzung von
          MEADIREKT-CHAT auf der Grundlage der NUTZUNGSBEDINGUNGEN ein. Die eingeräumte Lizenz dient ausschließlich der
          Nutzung von MEADIREKT-CHAT durch den ENDKUNDEN.
        </li>
        <li>
          <p class="only-margin-bot">Gebühren und Kosten</p>
          <ol>
            <li>
              Die Nutzung von MEADIREKT-CHAT sowie die Einräumung der insoweit erforderlichen Rechte erfolgen für die
              ENDKUNDEN (Gast- und registrierte Nutzer) kostenlos.
            </li>
            <li>
              Die Kosten für die Nutzung von digitalen Kommunikationsnetzen bei Nutzung von MEADIREKT-CHAT trägt der
              ENDKUNDE.
            </li>
          </ol>
        </li>
      </ol>
    </li>
    <li>
      <p class="only-margin-bot">
        <b>Zulässige Nutzung von MEADIREKT-CHAT </b>
      </p>
      <ol>
        <li>
          Der ENDKUNDE sichert zu, MEADIREKT-CHAT ausschließlich im Einklang mit den geltenden Gesetzen und Richtlinien
          sowie diesen NUTZUNGSBEDINGUNGEN zu nutzen. Die Nutzung hat sich dabei auf den bestimmungsgemäßen Gebrauch und
          die im Rahmen dieser NUTZUNGSBEDINGUNGEN definierten Anwendungsgebiete von MEADIREKT-CHAT zu beschränken.
        </li>
        <li>
          <p class="only-margin-bot">
            Der ENDKUNDE ist verpflichtet, MEADIREKT-CHAT nur für rechtmäßige, berechtigte und zulässige Zwecke zu
            nutzen und wird MEADIREKT-CHAT weder auf eine andere Weise nutzen noch Dritte bei einer Nutzung
            unterstützen, welche insbesondere
          </p>
          <ol>
            <li>
              die Rechte von MEADIREKT-CHAT, SANACORP, den beteiligten APOTHEKERN oder Dritten (einschließlich
              Datenschutz- oder Veröffentlichungsrechte, Rechte am geistigen Eigentum oder sonstige Eigentumsrechte)
              verletzt, widerrechtlich verwendet oder verstößt;
            </li>
            <li>
              rechtswidrig, obszön, beleidigend, bedrohend, einschüchternd, belästigend, hasserfüllt, rassistisch oder
              ethnisch anstößig ist, oder zu einer Verhaltensweise anstiftet oder ermuntert, die illegal oder auf
              sonstige Weise unangemessen wäre, einschließlich der Verherrlichung von Gewaltverbrechen;
            </li>
            <li>das Veröffentlichen von Unwahrheiten, Falschdarstellungen oder irreführender Aussagen beinhaltet;</li>
            <li>jemanden nachahmt und/oder</li>
            <li>
              das Versenden illegaler oder unzulässiger Mitteilungen wie Massennachrichten, Auto-Messaging, Auto-Dialing
              und dergleichen umfasst.
            </li>
          </ol>
        </li>
        <li>
          <p class="only-margin-bot">
            Der ENDKUNDE ist verpflichtet, jede indirekte oder direkte Beeinträchtigung von MEADIREKT-CHAT zu
            unterlassen. Hierzu gehört insbesondere, es zu unterlassen
          </p>
          <ol>
            <li>
              Viren oder sonstige schädlichen Computercodes über MEADIREKT-CHAT zu versenden, zu übermitteln oder zu
              speichern;
            </li>
            <li>
              unberechtigten Zugriff auf MEADIREKT-CHAT und/oder auf Systeme von SANACORP und/oder der beteiligten
              APOTHEKER zu erlangen oder dies zu versuchen;
            </li>
            <li>die Integrität oder Leistung von MEADIREKT-CHAT zu durchbrechen bzw. zu stören;</li>
            <li>Accounts für MEADIREKT-CHAT über nicht autorisierte Wege bzw. Mittel zu erstellen;</li>
            <li>
              Informationen von bzw. über die beteiligten APOTHEKEN bzw. andere Nutzer von MEADIREKT-CHAT auf eine
              unzulässige oder unberechtigte Art und Weise zu sammeln und/oder
            </li>
            <li>
              Software, Webapplikationen oder vergleichbare Anwendungen zu entwickeln und/oder zu verbreiten, welche
              wesentliche Funktionsweisen von MEADIREKT-CHAT enthalten.
            </li>
          </ol>
        </li>
        <li>
          Mit der Nutzung von MEADIREKT-CHAT bestätigt der ENDKUNDE, dass ihm die in Bezug auf die über MEADIREKT-CHAT
          übermittelten Informationen, Daten bzw. Dateien ggf. erforderlichen Rechte zustehen. Insbesondere garantiert
          der ENDKUNDE, über MEADIREKT-CHAT nur Rezepte bzw. ärztliche Verordnungen einzuscannen, welche auf ihn
          höchstpersönlich ausgestellt wurden.
        </li>
      </ol>
    </li>
    <li>
      <p class="only-margin-bot">
        <b>Sicherheit des Accounts (registrierte Nutzer)</b>
      </p>
      <ol>
        <li>
          Der ENDKUNDE ist für die Sicherheit seines Benutzerkontos (Account) verantwortlich. Insbesondere hat er durch
          geeignete Maßnahmen sicherzustellen, dass Dritte nicht in unberechtigter Art und Weise Zugriff auf die
          Zugangsdaten oder den Account nehmen.
        </li>
        <li>
          Der ENDKUNDE hat SANACORP von jeder (drohenden) unberechtigten Nutzung des Benutzerkontos bzw. von jedem
          Sicherheitsverstoß in Bezug auf das Benutzerkonto unverzüglich in Kenntnis zu setzen.
        </li>
      </ol>
    </li>
    <li>
      <p class="only-margin-bot">
        <b>Löschung des Benutzerkontos / Accounts </b>
      </p>
      <ol>
        <li>
          Der ENDKUNDE hat die Möglichkeit, sein Benutzerkonto bzw. seinen Account jederzeit zu löschen. Die Löschung
          kann über die Benutzeroberfläche (Einstellungen: Konto löschen) durchgeführt werden .
        </li>
        <li>
          Nach Löschung des Accounts besteht für den ENDKUNDEN keine Möglichkeit mehr, auf Chatinhalte zurückzugreifen
          bzw. diese einzusehen.
        </li>
        <li>
          Es wird darauf hingewiesen, dass sowohl die im Wege der Auftragsverarbeitung durch die APOTHEKE erhobenen
          Daten als auch die im Rahmen der Eröffnung des Accounts durch SANACORP erhobenen Daten noch bis zu vier Wochen
          ab Löschung gespeichert werden und dann gelöscht werden, sofern kein gesetzliche Aufbewahrungspflicht besteht.
          Das nähere ergibt sich aus der Datenschutzerklärung der APOTHEKE und der MEADIREKT DATENSCHUTZERKLÄRUNG.
        </li>
      </ol>
    </li>
  </ol>
  <h4>§ 3 Änderung der NUTZUNGSBEDINGUNGEN</h4>
  <ol>
    <li>SANACORP kann die vorliegenden NUTZUNGSBEDINGUNGEN jederzeit und ohne Angabe von Gründen ändern.</li>
    <li>
      Änderungen werden registrierten Nutzern mindestens 2 Wochen vor Inkrafttreten der Änderungen in Textform
      mitgeteilt. Ein Verweis auf die Internetadresse, unter welcher die aktualisierte Fassung der NUTZUNGSBEDINGUNGEN
      verfügbar sind, ist insoweit ausreichend. Für Gastnutzer gelten jeweils die im konkreten Zeitpunkt der Nutzung von
      MEADIREKT-CHAT geltenden NUTZUNGSBEDINGUNGEN.
    </li>
    <li>
      Wird der geänderten Fassung der NUTZUNGSBEDINGUNGEN nicht binnen eines Monats ab Zugang der Nachricht über die
      Änderung widersprochen, gelten die geänderten NUTZUNGSBEDINGUNGEN als akzeptiert. SANACORP wird den ENDKUNDEN im
      Rahmen der Mitteilung der Änderung der NUTZUNGSBEDINGUNGEN über sein Widerrufsrecht belehren.
    </li>
    <li>
      Im Fall eines Widerspruchs endet das Vertragsverhältnis zwischen SANACORP und dem ENDKUNDEN mit Inkrafttreten der
      geänderten NUTZUNGSBEDINGUNGEN. Der Zugang des ENDKUNDEN wird in diesem Fall gesperrt.
    </li>
  </ol>
  <h4>§ 4 Haftung und Haftungsbeschränkung</h4>
  <ol>
    <li>
      SANACORP haftet verschuldensabhängig nur bei Vorsatz und grober Fahrlässigkeit sowie bei der fahrlässigen
      Verletzung wesentlicher Vertragspflichten (Kardinalpflichten), deren Erfüllung die ordnungsgemäße Durchführung
      dieser NUTZUNGSBEDINGUNGEN erst ermöglicht und auf deren Einhaltung der ENDKUNDE regelmäßig vertrauen darf, sowie
      bei Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit. Im Falle einfacher Fahrlässigkeit ist
      die Haftung von SANACORP je Schadensfall begrenzt auf den vorhersehbaren, vertragstypischen Schaden. Die Haftung
      für das Fehlen garantierter Eigenschaften, bei Arglist, nach dem Produkthaftungsgesetz, dem
      Bundestdatenschutzgesetz und der Datenschutz-Grundverordnung (DS-GVO) bleibt unberührt.
    </li>
    <li>
      Für den Verlust von Daten haftet SANACORP insoweit nicht, als der Schaden darauf beruht, dass es der ENDKUNDE
      unterlassen hat, regelmäßige Datensicherungen durchzuführen und dadurch sicherzustellen, dass verlorengegangene
      Daten mit vertretbarem Aufwand wiederhergestellt werden können.
    </li>
    <li>
      Die vorstehenden Regelungen unter § 4 Abs. (1) und (2) gelten auch zugunsten der gesetzlichen Vertreter,
      Mitarbeiter und Erfüllungsgehilfen von SANACORP.
    </li>
    <li>
      Die vorstehenden Regelungen unter § 4 Abs. (1) und (2) gelten auch im Verhältnis zwischen ENDKUNDEN und den
      beteiligten APOTHEKERN, deren gesetzlichen Vertreter, Mitarbeiter und Erfüllungsgehilfen.
    </li>
  </ol>
  <h4>§ 5 Freistellung</h4>
  <ol>
    <li>
      <p class="only-margin-bot">
        Sofern und soweit ein Dritter einen Anspruch in Bezug auf eine Handlung, Information oder Inhalte von Chats über
        MEADIREKT-CHAT gegen SANACORP und/oder beteiligte APOTHEKER erhebt, stellt der ENDKUNDE SANACORP und/oder
        konkret betroffenen beteiligte APOTHEKER in rechtlich zulässigem Umfang von sämtlichen Haftungen, Schäden,
        Verlusten und Aufwendungen jeglicher Art (incl. angemessener Kosten für anwaltliche Beratung und sonstigen
        Kosten der Rechtsverfolgung) frei bzw. hält SANACORP und/oder die konkret betroffenen beteiligten APOTHEKER
        schadlos, sofern der Anspruch im Zusammenhang mit folgenden Gesichtspunkten steht bzw. erhoben wird:
      </p>
      <ol>
        <li>Verstoß gegen die NUTZUNGSBEDINGUNGEN und/oder im Zeitpunkt der Vornahme geltendes Recht</li>
        <li>Falschdarstellungen durch den ENDKUNDEN.</li>
      </ol>
    </li>
    <li>
      Der ENDKUNDE wird in dem von SANACORP bzw. der oder den beteiligten APOTHEKERN geforderten Umfang an der
      Verteidigung gegen jeden i.S.v. § 5 (1) erhobenen Anspruch eines Dritten mitwirken. Dies sichert der ENDKUNDE
      hiermit ausdrücklich zu.
    </li>
  </ol>
  <h4>§ 6 Beendigung bzw. Einstellung von MEADIREKT-CHAT</h4>
  <ol>
    <li>
      SANACORP ist jederzeit berechtigt, MEADIREKT-CHAT einzustellen bzw. zu modifizieren. Einer Ankündigung bzw. einer
      ausdrücklichen Kündigung sowie einer Begründung bedarf es nicht.
    </li>
    <li>
      Eine Einstellung von MEADIREKT-CHAT kommt insbesondere in Betracht, wenn (a) ein Gericht aus dem Bereich der
      Zivil- oder Verwaltungsgerichtsbarkeit und/oder (b) eine Behörde SANACORP den Betrieb von MEADIREKT-CHAT durch den
      APOTHEKER und/oder die ENDKUNDEN untersagen oder an Auflagen oder Bedingungen knüpfen, welche die konkret
      streitgegenständliche bzw. relevante Fassung von MEADIREKT-CHAT noch nicht erfüllt.
    </li>
  </ol>
  <h4>§ 7 Hinweis auf Export-Möglichkeit für beteiligte APOTHEKER</h4>
  <p>
    Die beteiligten APOTHEKER haben die Möglichkeit, Chatverläufe und Inhalte zu Dokumentationszwecken zu exportieren
    und zu speichern. Dies gilt sowohl in Bezug auf Chatverläufe (incl. Inhalte) mit Gastnutzern als auch in Bezug auf
    Chatverläufe (incl. Inhalte) mit registrierten Nutzern. Mit dieser Export-Möglichkeit ist der ENDKUNDE
    einverstanden.
  </p>
  <h4>§ 8 Einbindung von externen Dienstleistern</h4>
  <p>
    SANACORP wird im Zusammenhang mit dem Betrieb von MEADIREKT-CHAT externe Dienstleister einsetzen. Dies betrifft
    insbesondere den Bereich des Kundensupports. Hierbei wird SANACORP die im konkreten Einzelfall geltenden
    datenschutzrechtlichen Bestimmungen einhalten.
  </p>
  <h4>§ 9 Datenschutz</h4>
  <p>
    SANACORP und die beteiligten APOTHEKER legen großen Wert auf einen Schutz der Daten der ENDKUNDEN. Bei der
    Verarbeitung von personenbezogenen Daten, die SANACORP bzw. die beteiligten APOTHEKER über die ENDKUNDEN im
    Zusammenhang mit der Nutzung von MEADIREKT-CHAT erhalten, richten sich SANACORP und die beteiligten APOTHEKERN
    streng nach den datenschutzrechtlichen Anforderungen der Europäischen Datenschutzgrundverordnung und dem deutschen
    Datenschutzrecht (BDSG).
  </p>
  <ol>
    <li>
      <p class="only-margin-bot">Bestands- und Nutzungdaten der ENDKUNDEN (SANACORP)</p>
      Im Zusammenhang mit der informatorischen Nutzung der Website
      <a href="https://www.meineapotheke.de/meadirekt-chat">https://www.meineapotheke.de/meadirekt-chat</a> bzw. der
      Nutzung von MEADIREKT-CHAT über einen Gastzugang bzw. als registrierter Nutzer erhebt, verarbeitet bzw. speichert
      SANACORP personenbezogene Daten. Die Einzelheiten sind in der MEADIREKT DATENSCHUTZERKLÄRUNG dargestellt.
    </li>
    <li>
      <p class="only-margin-bot">Beteiligte APOTHEKEN</p>
      Im Zusammenhang mit der Nutzung von MEADIREKT-CHAT über einen Gastzugang bzw. als registrierter Nutzer erheben,
      verarbeiten bzw. speichern die zum Chat ausgewählten APOTHEKER personenbezogene Daten. Die Einhaltung der insoweit
      geltenden datenschutzrechtlichen Anforderungen liegt in der ausschließlichen Verantwortung der beteiligten
      APOTHEKER. Die entsprechende Datenschutzerklärung der beteiligten APOTHEKER können die ENDKUNDEN jederzeit abrufen
      – insbesondere zu Beginn eines Chats. Mit Beginn eines Chats erklärt sich der ENDKUNDE mit der
      Datenschutzerklärung der beteiligten APOTHEKE einverstanden.
    </li>
    <li>
      <p class="only-margin-bot">
        Einwilligungserklärung des ENDKUNDEN gegenüber SANACORP und den beteiligten APOTHEKEN
      </p>
      <p class="only-margin-bot">
        Im Zusammenhang mit der Nutzung von MEADIREKT-CHAT durch den ENDKUNDEN werden personenbezogene Daten (Art. 4 Nr.
        1 DSGVO) und ggf. gesundheitsbezogene Daten (Art. 4 Nr. 15 DSGVO) des Kunden verarbeitet, übermittelt, verwendet
        und gespeichert. Eine pharmazeutische Beratung des Kunden durch die jeweilige APOTHEKE erfolgt auf Grundlage der
        vom Kunden zur Verfügung gestellten Informationen.
      </p>
      <p class="only-margin-bot">
        Die beteiligten APOTHEKEN verwenden die Daten eines ENDKUNDEN zur Abwicklung von Vorbestellungen, Reservierungen
        und – je nach Einzelfall – zur fachgerechten Durchführung von pharmazeutischen Beratungen. SANACORP erhebt
        außerdem anonymisierte Daten über die Nutzung von MEADIREKT-CHAT durch den ENDKUNDEN (Information über
        Nutzerstatus; Daten über die Verweildauer in der Anwendung, Abbruchverhalten, Anzahl Chatnachrichten, verwendete
        Suchbegriffe, Anzahl der Suchen, Anzahl Lieblingsapotheken, Anzahl Kontakte mit APOTHEKEN).
      </p>
      <p class="only-margin-bot">
        Zur Nutzung von MEADIREKT-CHAT ist es erforderlich, dass die ENDKUNDEN mit einer gesonderten
        Einwilligungserklärung in die Verarbeitung ihrer personenbezogenen Daten einwilligen. Die Einwilligungserklärung
        wird zu Beginn des Chats bzw. bei Erstellung eines Nutzerkontos abgefragt. Sie ist jederzeit gegenüber SANACORP
        bzw. der teilnehmenden APOTHEKEN widerrufbar. Nach einem Widerruf der Einwilligungserklärung kann MEADIREKT-CHAT
        nicht mehr genutzt werden.
      </p>
    </li>
    <li>
      <p class="only-margin-bot">Abrufbarkeit von Datenschutzerklärung und Einwilligungserklärung</p>
      Die Datenschutzerklärung von SANACORP, die Datenschutzerklärung der beteiligten APOTHEKEN und die
      Einwilligungserklärung sind unter
      <a href="https://www.meineapotheke.de/meadirekt-chat">https://www.meineapotheke.de/meadirekt-chat</a>
      unter der Rubrik Datenschutz/Impressum bzw. in den Stammdaten der beteiligten APOTHEKEN jederzeit einsehbar,
      download- und druckbar.
    </li>
  </ol>
  <h4>§ 10 Schlussbestimmungen</h4>
  <ol>
    <li>
      Sollte eine Bestimmung dieser NUTZUNGSBEDINGUNGEN unwirksam oder undurchführbar sein oder werden, so berührt dies
      die Wirksamkeit der NUTZUNGSBEDINGUNGEN im Übrigen nicht. In diesem Fall wird die unwirksame oder undurchführbare
      Regelung durch eine wirksame oder durchführbare Regelung ersetzt, die dem Zweck der unwirksamen oder
      undurchführbaren Regelung möglichst nahe kommt; das Gleiche gilt im Falle einer unbeabsichtigten Regelungslücke
      dieser NUTZUNGSBEDINGUNGEN.
    </li>
    <li>
      Unter Ausschluss des Kollissionsrechts findet ausschließlich das Sachrecht der Bundesrepublik Deutschland
      Anwendung. Das UN-Kaufrecht findet keine Anwendung.
    </li>
    <li>
      Ausschließlicher Gerichtsstand für alle Streitigkeiten zwischen SANACORP und dem ENDKUNDEN aus oder im
      Zusammenhang mit diesem Vertrag ist – soweit gesetzlich zulässig – München.
    </li>
  </ol>
</div>
