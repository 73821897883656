import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import isNil from 'lodash-es/isNil';
import { firstValueFrom } from 'rxjs';
import { filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ConversationCreationService } from '../../../../common/resources/src/services/conversation-creation.service';
import { newConversation } from '../../../../store/src/common-store/chat-store/actions/chat-conversation.actions';
import { pipeConversationForPharmacy } from '../../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { CommonState } from '../../../../store/src/common-store/common.state';
import { selectUser } from '../../../../store/src/common-store/user-store/selectors/user.selectors';
import {
  clearPharmacySearchResults,
  loadMorePharmacySearchResults,
  openOrStartConversationWithPharmacy,
  openOrStartConversationWithPharmacyFailure,
  openOrStartConversationWithPharmacySuccess,
  searchForPharmacies,
  searchForPharmaciesFailure,
  searchForPharmaciesSuccess,
  setPharmacySearchTerm,
} from '../../../../store/src/pharmacy/pharmacy-search/pharmacy-search.actions';
import {
  selectPharmacySearchOffset,
  selectPharmacySearchRequestState,
  selectPharmacySearchTerm,
  selectPharmacySearchTotalResultsForQuery,
} from '../../../../store/src/pharmacy/pharmacy-search/pharmacy-search.selectors';
import { PharmacySearchState } from '../../../../store/src/pharmacy/pharmacy-search/pharmacy-search.state';
import BackendUserConversation from '../../../../essentials/types/src/backendUserConversation';
import { MeaUser } from '../../../../essentials/types/src/chatUser';
import { RequestState } from '../../../../essentials/types/src/requestState';
import { Logger } from '../../../../essentials/util/src/logger';
import { PharmacySearchService } from './pharmacy-search.service';

const logger = new Logger('PharmacySearchEffects');

export const SEARCH_ROUTES = new InjectionToken<SearchRoutesInterface>('SEARCH_ROUTES');

export interface SearchRoutesInterface {
  getChatPage: (conversationId: string) => string[];
}

@Injectable()
export class PharmacySearchEffects {
  setSearchTerm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPharmacySearchTerm),
      tap(() => this.store.dispatch(clearPharmacySearchResults())),
      map(({ searchTerm }) => searchForPharmacies({ searchTerm }))
    )
  );

  scrollToBottom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMorePharmacySearchResults),
      withLatestFrom(
        this.store.select(selectPharmacySearchRequestState('search')),
        this.store.select(selectPharmacySearchOffset),
        this.store.select(selectPharmacySearchTotalResultsForQuery)
      ),
      filter(
        ([_, searchRequestState, offset, totalResultsForQuery]) =>
          searchRequestState !== RequestState.InProgress && offset < totalResultsForQuery
      ),
      withLatestFrom(this.store.select(selectPharmacySearchTerm)),
      mergeMap(async ([_, searchTerm]) => searchForPharmacies({ searchTerm }))
    )
  );

  searchForPharmacies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchForPharmacies),
      withLatestFrom(this.store.select(selectPharmacySearchOffset)),
      switchMap(async ([{ searchTerm }, offset]) => {
        try {
          const isSearchTermLongEnough = searchTerm.length > 2;
          const { pharmacies, total } = isSearchTermLongEnough
            ? await this.pharmaciesSearchService.searchPharmacies(searchTerm, offset)
            : { pharmacies: [], total: 0 };
          return searchForPharmaciesSuccess({
            searchResults: pharmacies,
            total,
          });
        } catch (error) {
          logger.error('Error while searching for pharmacies', error);
          return searchForPharmaciesFailure();
        }
      })
    )
  );

  openOrStartConversationWithPharmacy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openOrStartConversationWithPharmacy),
      switchMap(async ({ pharmacy }) => {
        const existingConversation = await firstValueFrom(this.store.pipe(pipeConversationForPharmacy(pharmacy.id)));
        if (existingConversation) {
          await this.router.navigate(this.searchRoutes.getChatPage(existingConversation.id));
        } else {
          try {
            const createdConversation = await this.startNewConversation(pharmacy.pharmacyChatUser);
            this.store.dispatch(newConversation({ conversation: createdConversation }));
            await this.router.navigate(this.searchRoutes.getChatPage(createdConversation.conversationId));
            return openOrStartConversationWithPharmacySuccess();
          } catch (error) {
            logger.error('Error while creating conversation', error);
            return openOrStartConversationWithPharmacyFailure();
          }
        }
        return openOrStartConversationWithPharmacySuccess();
      })
    )
  );

  constructor(
    private actions$: Actions,
    private conversationCreationService: ConversationCreationService,
    private pharmaciesSearchService: PharmacySearchService,
    private router: Router,
    private store: Store<CommonState & { pharmacySearch: PharmacySearchState }>,
    @Inject(SEARCH_ROUTES) private searchRoutes: SearchRoutesInterface
  ) {}

  private async startNewConversation(chatPartner: MeaUser): Promise<BackendUserConversation> {
    const currentUser = await firstValueFrom(this.store.select(selectUser));
    if (isNil(currentUser)) {
      throw Error('Cannot start new conversation because user is null');
    }
    return this.conversationCreationService.createNewConversation(currentUser, chatPartner);
  }
}
