import gql from 'graphql-tag';
import { appointmentAttributes } from '../../../../common/resources/src/graphql/attributes';

export default gql`
  subscription deletedAppointment($pharmacyCognitoId: ID!) {
    deletedAppointment(pharmacyCognitoId: $pharmacyCognitoId) {
        ${appointmentAttributes}
    }
  }
`;
