import { AsyncPipe } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppsyncCommonAppointmentService } from '../../../../../common/resources/src/services/appsync/appsync-common-appointment.service';
import { EncryptionService } from '../../../../../common/resources/src/services/encryption/encryption.service';
import { IconButtonComponent } from '../../../../../common/ui-components/src/buttons/icon-button/icon-button.component';
import { MeaButtonComponent } from '../../../../../common/ui-components/src/buttons/mea-button/mea-button.component';
import { ErrorComponent } from '../../../../../common/ui-components/src/errors/error/error.component';
import { InputComponent } from '../../../../../common/ui-components/src/inputs/input/input.component';
import { CustomModalController } from '../../../../../common/ui-components/src/ionic/controllers/custom-modal.controller';
import { LinkComponent } from '../../../../../common/ui-components/src/navigation/link/link.component';
import { SuccessComponent } from '../../../../../common/ui-components/src/styling/success/success.component';
import { Appointment } from '../../../../../essentials/types/src/appointment';
import { MeaUser } from '../../../../../essentials/types/src/chatUser';
import { CONFIG, MeaConfig } from '../../../../../essentials/types/src/mea-config';
import Pharmacy from '../../../../../essentials/types/src/pharmacy';
import { RequestState } from '../../../../../essentials/types/src/requestState';
import { Logger } from '../../../../../essentials/util/src/logger';
import { BerlinTimePipe } from '../../../../../essentials/util/src/pipes/berlin-time.pipe';
import { IconNamePipe } from '../../../../../essentials/util/src/pipes/icon-name.pipe';
import { isNotNullOrUndefined } from '../../../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { selectActiveConversation } from '../../../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { CommonState } from '../../../../../store/src/common-store/common.state';
import { selectPharmacy, selectUser } from '../../../../../store/src/common-store/user-store/selectors/user.selectors';
import { finishAppointmentBooking } from '../../../../../store/src/web/appointments/appointment.actions';

const logger = new Logger('AppointmentConfirmationComponent');

dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
  selector: 'appointment-confirmation',
  templateUrl: './appointment-confirmation.component.html',
  styleUrls: ['./appointment-confirmation.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    IconButtonComponent,
    InputComponent,
    FormsModule,
    LinkComponent,
    MeaButtonComponent,
    SuccessComponent,
    ErrorComponent,
    AsyncPipe,
    TranslateModule,
    BerlinTimePipe,
    IconNamePipe,
  ],
})
export class AppointmentConfirmationComponent {
  @Input() appointment: Appointment | undefined;
  @Input() appointmentType: string | undefined;
  inputName: string | undefined = '';
  inputEmail = '';
  inputNote = '';
  bookingStatus: RequestState = RequestState.Init;
  isPharmacy = false;
  pharmacy$: Observable<Pharmacy | null | undefined>;

  constructor(
    private modalCtrl: CustomModalController,
    private appsyncCommonAppointmentsService: AppsyncCommonAppointmentService,
    private encryptionService: EncryptionService,
    private store: Store<CommonState>,
    @Inject(CONFIG) private config: MeaConfig
  ) {
    this.isPharmacy = !config.clientApp;
    this.pharmacy$ = this.isPharmacy
      ? this.store.select(selectPharmacy)
      : this.store
          .select(selectActiveConversation)
          .pipe(map((conversation) => (conversation?.chatPartner as MeaUser)?.pharmacy));
  }

  async bookAppointment() {
    this.bookingStatus = RequestState.InProgress;
    const appointment = this.appointment;
    if (!appointment) {
      return;
    }
    const encryptedNote = await this.getEncryptedNote();
    try {
      await this.appsyncCommonAppointmentsService.bookAppointment({
        pharmacyCognitoId: appointment.pharmacyCognitoId,
        dateTime: dayjs(appointment.dateTime),
        name: this.inputName || '',
        email: this.inputEmail,
        selectedAppointmentType: this.appointmentType,
        encryptedNote,
      });
      this.bookingStatus = RequestState.Success;
      this.store.dispatch(finishAppointmentBooking({ bookingSuccess: true }));
    } catch (e) {
      logger.error('Error booking appointment', e);
      this.bookingStatus = RequestState.Error;
    }
  }

  calculateEndTime(appointment: Appointment) {
    return dayjs(appointment.dateTime).add(appointment.durationMinutes, 'minute').toISOString();
  }

  isLoading(): boolean {
    return this.bookingStatus === RequestState.InProgress;
  }

  hadSuccess(): boolean {
    return this.bookingStatus === RequestState.Success;
  }

  hadError(): boolean {
    return this.bookingStatus === RequestState.Error;
  }

  async closeModal() {
    await this.modalCtrl.dismiss();
  }

  private async getEncryptedNote() {
    if (!this.isPharmacy || !this.inputNote) {
      return undefined;
    }
    const publicKey = await firstValueFrom(
      this.store.select(selectUser).pipe(
        isNotNullOrUndefined(),
        map((user) => user.publicKey)
      )
    );
    return this.encryptionService.encryptUsingPublicKey(this.inputNote, publicKey) || undefined;
  }
}
