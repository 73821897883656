import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonContent, IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { IconButtonComponent } from '../../../../../libs/common/ui-components/src/buttons/icon-button/icon-button.component';
import { CustomModalController } from '../../../../../libs/common/ui-components/src/ionic/controllers/custom-modal.controller';
import { LegalTextComponent } from '../../../../../libs/common/ui-templates/src/legal-text/legal-text.component';
import { LicenseAndTosComponent } from '../license-and-tos/license-and-tos.component';

@Component({
  selector: 'page-legal-and-help',
  templateUrl: 'legal-and-help.page.html',
  styleUrls: ['./legal-and-help.page.scss'],
  standalone: true,
  imports: [IonicModule, IconButtonComponent, LegalTextComponent, LicenseAndTosComponent, TranslateModule],
})
export class LegalAndHelpPage implements OnInit {
  @ViewChild(IonContent, { static: true }) content: IonContent | undefined;

  @Input() subPage = '';

  constructor(private modalController: CustomModalController) {}

  close() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.content?.scrollToTop();
  }

  openSubPage(page: string) {
    this.subPage = page;
    return this.content?.scrollToTop();
  }
}
