<common-legal-text-container [colorScheme]="colorScheme">
  @switch (type) {
    @case ('legal-info') {
      <common-imprint></common-imprint>
    }
    @case ('privacy') {
      <common-privacy-policy></common-privacy-policy>
    }
    @case ('tos') {
      <common-terms-of-service> </common-terms-of-service>
    }
    @case ('pharmacy-tos') {
      <pharmacy-license-and-tos> </pharmacy-license-and-tos>
    }
  }
</common-legal-text-container>
