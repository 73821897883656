import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LogoutType } from '../../../../../essentials/types/src/logoutType';
import { CONFIG } from '../../../../../essentials/types/src/mea-config';
import { AlertComponent, MeaAlertButton } from '../../../../ui-components/src/ionic/alert/alert.component';
import { CustomModalController } from '../../../../ui-components/src/ionic/controllers/custom-modal.controller';
import { CustomToastController } from '../../../../ui-components/src/ionic/controllers/custom-toast.controller';
import { Logger } from '../../../../../essentials/util/src/logger';
import { LogoutService } from '../logout.service';

const logger = new Logger('PrivateKeyAlertService');

@Injectable({ providedIn: 'root' })
export class PrivateKeyAlertService {
  private config = inject(CONFIG);
  private translateService = inject(TranslateService);
  private modalController = inject(CustomModalController);
  private toastController = inject(CustomToastController);
  private logoutService = inject(LogoutService);

  private readonly isPharmacy = !this.config.clientApp;
  translateKey = this.isPharmacy ? 'PHARMACY_FRONTEND.PRIVATE_KEY_RESET' : 'PRIVATE_KEY_RESET';

  public async askForKeyPairReset(): Promise<'cancel' | 'continueKeyReset'> {
    logger.info('Asking for private key reset');

    const translations = this.translateService.instant(this.translateKey);

    const alert = await this.modalController.create({
      component: AlertComponent,
      cssClass: 'mea-alert',
      componentProps: {
        innerHtml: translations.RESET_KEY_PAIR,
        buttons: this.getButtons(translations),
      },
      backdropDismiss: false,
    });
    await alert.present();
    const result = await alert.onDidDismiss();
    logger.info('selecting', result.role);
    return result.role as 'cancel' | 'continueKeyReset';
  }

  public async showNoPrivateKeyFoundToast() {
    const translations = this.translateService.instant('E2E');
    const toast = await this.toastController.createToast({
      message: translations['FAIL'],
      buttons: [{ text: translations['LOGOUT'], role: 'cancel' }],
    });
    await toast.present();
    await toast.onDidDismiss();
    await this.logoutService.logout(LogoutType.NoPrivateKey);
  }

  private getButtons(translations: { [key: string]: string }): MeaAlertButton[] {
    if (!this.isPharmacy) {
      return [
        {
          text: translations['CONTINUE'] as string,
          role: 'continueKeyReset',
          type: 'secondary',
        },
        {
          text: translations['CANCEL'] as string,
          type: 'primary',
          role: 'cancel',
          handler: async () => {
            logger.info('Selecting cancel');
            await this.logoutService.logout(LogoutType.NoPrivateKey);
          },
        },
      ];
    } else {
      return [
        {
          text: 'OK',
          role: 'cancel',
          type: 'primary',
        },
      ];
    }
  }
}
