import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MeaButtonComponent } from '../../../../../libs/common/ui-components/src/buttons/mea-button/mea-button.component';
import { LegalTextComponent } from '../../../../../libs/common/ui-templates/src/legal-text/legal-text.component';

@Component({
  selector: 'license-and-tos',
  templateUrl: './license-and-tos.component.html',
  styleUrls: ['./license-and-tos.component.scss'],
  standalone: true,
  imports: [MeaButtonComponent, LegalTextComponent, TranslateModule],
})
export class LicenseAndTosComponent {
  @Input({ required: true }) userIsSignedIn!: boolean;
  @Input({ required: true }) showTitle!: boolean;
  @Input() style: 'dark' | 'light' | undefined;

  onTosButtonClicked() {
    const windowReference = window.open(
      'https://medien.meineapotheke.de/text/Nutzungs-Lizenzbedingungen_Feb2022.pdf',
      '_blank'
    );
    if (windowReference) {
      windowReference.opener = null;
    }
  }
}
