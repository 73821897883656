import gql from 'graphql-tag';

export default gql`
  mutation hideDefaultAppointmentTypes($cognitoId: ID!, $hiddenDefaultAppointmentTypes: [String]) {
    updatePharmacyChatUser(
      cognitoId: $cognitoId
      user: { hiddenDefaultAppointmentTypes: $hiddenDefaultAppointmentTypes }
    ) {
      __typename
      cognitoId
      hiddenDefaultAppointmentTypes
    }
  }
`;
