import { Product, ProductDetails, ProductPackaging } from '../../types/src/product';
import { ShopProduct, ShopProductDetails, ShopProductPackaging } from '../../types/src/shopProduct';
import { Logger } from './logger';

const logger = new Logger('ShopProductUtil');

export class ShopProductUtil {
  public static getShopApiBaseUrl(shopUrl: string | undefined): string | undefined {
    // https://www.meineapotheke.de/shop/... for real shops with api, ...localhost:1234... for local testing
    return shopUrl?.startsWith('https://www.meineapotheke.de/shop/') || shopUrl?.includes('localhost:1234')
      ? this.addMissingSlashToUrl(shopUrl)
      : undefined;
  }

  public static getFullPzn(pzn: string): string {
    if (pzn.length === 8) {
      return pzn;
    } else if (pzn.length === 7) {
      return `0${pzn}`;
    } else {
      logger.error(`invalid pzn: ${pzn}`);
      return pzn;
    }
  }

  public static mapShopProductToProduct(shopProduct: ShopProduct): Product {
    return {
      pzn: shopProduct.pzn,
      basePrice: shopProduct.base_price,
      discount: this.calculateDiscountInPercent(shopProduct.price, shopProduct.avp),
      imageUrl: shopProduct.image_url || undefined,
      name: shopProduct.name,
      price: shopProduct.price,
      retailPrice: shopProduct.avp,
      retailPriceType: 'AVP',
      thumbnailUrl: shopProduct.thumbnail_url || undefined,
      url: shopProduct.url,
      quantityAndUnit: shopProduct.quantity_and_unit,
      crossSellPackagings: shopProduct.cross_sell_packagings
        ?.map(this.mapShopProductPackagingToProductPackaging)
        .sort((a, b) => {
          if (!a.quantityAndUnit || !b.quantityAndUnit) {
            return 1;
          } else if (a.quantityAndUnit.length === b.quantityAndUnit.length) {
            return a.quantityAndUnit > b.quantityAndUnit ? 1 : -1;
          } else {
            return a.quantityAndUnit.length > b.quantityAndUnit.length ? 1 : -1;
          }
        }),
    };
  }

  public static mapShopProductDetailsToProductDetails(shopProductDetails: ShopProductDetails): ProductDetails {
    return {
      pzn: shopProductDetails.pzn,
      basePrice: shopProductDetails.base_price,
      crossSellPackagings: shopProductDetails.cross_sell_packagings.map(this.mapShopProductPackagingToProductPackaging),
      description: shopProductDetails.description,
      discount: this.calculateDiscountInPercent(shopProductDetails.price, shopProductDetails.avp),
      footnotes: shopProductDetails.alerts.map((alert) => ({
        text: alert.text,
        link: alert.link ? { url: alert.link, description: alert.link_title || alert.link } : undefined,
      })),
      imageUrl: shopProductDetails.image_url,
      name: shopProductDetails.name,
      obligatoryTexts: shopProductDetails.obligatory_texts,
      pharmaceuticalForm: shopProductDetails.darreichungsform,
      price: shopProductDetails.price,
      quantityAndUnit: shopProductDetails.quantity_and_unit,
      retailPrice: shopProductDetails.avp,
      retailPriceType: 'AVP',
      thumbnailUrl: shopProductDetails.thumbnail_url || undefined,
      url: shopProductDetails.url,
    };
  }

  public static calculateDiscountInPercent(
    price: number | undefined,
    retailPrice: number | undefined
  ): number | undefined {
    if (price && retailPrice) {
      const discount = Math.trunc((1 - price / retailPrice) * 100);
      return discount >= 5 ? discount : undefined;
    }
    return undefined;
  }

  public static isShopProduct(product: any): product is ShopProduct {
    return product.retailPriceType === undefined;
  }

  private static addMissingSlashToUrl(url: string): string {
    return url.endsWith('/') ? url : `${url}/`;
  }

  private static mapShopProductPackagingToProductPackaging(
    shopProductPackaging: ShopProductPackaging
  ): ProductPackaging {
    return {
      pzn: shopProductPackaging.pzn,
      quantityAndUnit: shopProductPackaging.quantity_and_unit,
      price: shopProductPackaging.price,
    };
  }
}
