import { Observable } from 'rxjs';
import { Dictionary } from 'ts-essentials';
import { ChatPartnerMetadata } from '../chatPartnerMetadata';
import { Conversation } from '../conversation';
import { LoadStatus } from '../loadStatus';
import { InjectionToken } from '@angular/core';

export const CHAT_PARTNER_METADATA_SERVICE = new InjectionToken<ChatPartnerMetadataServiceInterface>(
  'CHAT_PARTNER_METADATA_SERVICE'
);

export interface ChatPartnerMetadataServiceInterface {
  readonly chatPartnerMetadataDictionary$: Observable<Dictionary<ChatPartnerMetadata>>;
  readonly chatPartnerLoadStatus$: Observable<LoadStatus>;

  isChatPartnerSuspended(conversation: Conversation): Observable<boolean>;
  isSuspendedByChatPartner(conversation: Conversation): Observable<boolean>;
}
