import gql from 'graphql-tag';

export default gql`
  query getPharmaciesByText($query: String!, $offset: Int, $limit: Int) {
    getPharmaciesByText(query: $query, offset: $offset, limit: $limit) {
      __typename
      total
      pharmacies {
        id
        name
        cognitoId
        address {
          __typename
          street
          houseNumber
          city
        }
        pharmacyChatUser {
          cognitoId
          publicKey
        }
      }
    }
  }
`;
