import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CustomToastController } from '../../../ui-components/src/ionic/controllers/custom-toast.controller';

@Injectable({
  providedIn: 'root',
})
export class ImageLowMemoryErrorService {
  private translations: any;

  constructor(
    private router: Router,
    private toastController: CustomToastController,
    private translateService: TranslateService
  ) {
    this.translateService.get('CHAT').subscribe((res) => {
      this.translations = res;
    });
  }

  public setFilePickerOpenedTimestampInLocalStorage(): void {
    localStorage.setItem('FilePickerOpenedAt', Date.now().toString());
  }

  public async displayToastIfBrowserWasReloadedAfterUserOpenedImagePicker(pathToBaseChatPage: string) {
    const { urlAfterRedirects } = await this.getFirstNavigationEnd();
    if (
      this.startsWithAndDoesNotEndWithRouteToChatPage(urlAfterRedirects, pathToBaseChatPage) &&
      this.filePickerWasClickedLessThan30SecondsAgo()
    ) {
      this.showPictureUploadFailedToast();
    }
  }

  private async showPictureUploadFailedToast(): Promise<void> {
    localStorage.removeItem('FilePickerOpenedAt');
    this.translateService.get('CHAT.NOTIFICATIONS').subscribe(async (res) => {
      await this.toastController.createAndPresentToast({
        message: res.PICTURE_UPLOAD_FAILED,
      });
    });
  }

  private async getFirstNavigationEnd(): Promise<NavigationEnd> {
    return (await firstValueFrom(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
    )) as NavigationEnd;
  }

  private filePickerWasClickedLessThan30SecondsAgo = () => {
    const lastFilePickerClick = Number(localStorage.getItem('FilePickerOpenedAt'));
    return lastFilePickerClick ? lastFilePickerClick > Date.now() - 30000 : false;
  };

  private startsWithAndDoesNotEndWithRouteToChatPage = (url: string, pathToBaseChatPage: string): boolean =>
    url.startsWith(pathToBaseChatPage) && url.length > pathToBaseChatPage.length + 1;
}
