import gql from 'graphql-tag';
import { pharmacyAttributes, pharmacyChatUserAttributes } from '../attributes';
// This mutation should have the same return values as getMyPharmacyUser
export default gql`
  mutation createPharmacyChatUser(
    $cognitoUsername: String!
    $pharmacyId: ID!
    $publicKey: String!
    $encryptedPrivateKey: String!
    $encryptionSalt: String!
    $contractTimestamp: Int
  ) {
    createPharmacyChatUser(
      user: {
        cognitoUsername: $cognitoUsername
        pharmacyId: $pharmacyId
        publicKey: $publicKey
        encryptedPrivateKey: $encryptedPrivateKey
        encryptionSalt: $encryptionSalt
        contractTimestamp: $contractTimestamp
      }
    ) {
      ${pharmacyChatUserAttributes}
      pharmacy {
        ${pharmacyAttributes}
      }
    }
  }
`;
