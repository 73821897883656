import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../essentials/types/src/actionType';
import { PharmacyWithChatUser } from '../../../../essentials/types/src/pharmacy';

export const clearPharmacySearchResults = createAction(`${ActionType.PharmacySearch}: Clear pharmacy search results`);

export const setPharmacySearchTerm = createAction(
  `${ActionType.PharmacySearch}: Set pharmacy search term`,
  props<{ searchTerm: string }>()
);

export const setTotalResultsForPharmacySearchQuery = createAction(
  `${ActionType.PharmacySearch}: Set total results for pharmacy search query`,
  props<{ totalResultsForQuery: number }>()
);

export const loadMorePharmacySearchResults = createAction(`${ActionType.PharmacySearch}: Load more results`);

export const searchForPharmacies = createAction(
  `${ActionType.PharmacySearch}: Search for pharmacies`,
  props<{ searchTerm: string }>()
);

export const searchForPharmaciesSuccess = createAction(
  `${ActionType.PharmacySearch}: Successful search for pharmacies`,
  props<{ searchResults: PharmacyWithChatUser[]; total: number }>()
);

export const searchForPharmaciesFailure = createAction(`${ActionType.PharmacySearch}: Failed search for pharmacies`);

export const openOrStartConversationWithPharmacy = createAction(
  `${ActionType.PharmacySearch}: Open or start conversation with pharmacy`,
  props<{ pharmacy: PharmacyWithChatUser }>()
);

export const openOrStartConversationWithPharmacySuccess = createAction(
  `${ActionType.PharmacySearch}: Open or start conversation with pharmacy success`
);

export const openOrStartConversationWithPharmacyFailure = createAction(
  `${ActionType.PharmacySearch}: Open or start conversation with pharmacy failure`
);
