import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../../../libs/essentials/types/src/actionType';
import { ConversationIdsByDay } from '../../types/conversationIdsByDay';
import { SoundRepetition, WidgetFeatures, WidgetWebsiteMaintainer } from '../state/home.state';

export const loadConversationIdsByDay = createAction(
  `${ActionType.Chat}: Load conversationIdsByDay`,
  props<{ lastDays: number }>()
);

export const loadConversationIdsByDaySuccess = createAction(
  `${ActionType.Chat}: Load conversationIdsByDay success`,
  props<{ conversationIdsByDay: ConversationIdsByDay[] }>()
);

export const loadConversationIdsByDayFailure = createAction(
  `${ActionType.Chat}: Load conversationIdsByDay failure`,
  props<{ errorMessage: string }>()
);

export const setSoundNotification = createAction(
  `${ActionType.Settings}: Set sound notification`,
  props<{ soundNotification: boolean }>()
);

export const setSoundNotificationFromLocalStorage = createAction(
  `${ActionType.Settings}: Set the initial sound notification from local storage`,
  props<{ soundNotification: boolean }>()
);

export const setSoundRepetition = createAction(
  `${ActionType.Settings}: Set sound repetition rate`,
  props<{ soundRepetition: SoundRepetition }>()
);

export const setSoundRepetitionFromLocalStorage = createAction(
  `${ActionType.Settings}: Set the initial sound repetition rate from local storage`,
  props<{ soundRepetition: SoundRepetition }>()
);

export const setWidgetFeatures = createAction(
  `${ActionType.Extensions}: Set Widget Features`,
  props<{ widgetFeatures: WidgetFeatures }>()
);

export const setWidgetWebsiteMaintainer = createAction(
  `${ActionType.Extensions}: Set Widget Website Maintainer`,
  props<{ widgetWebsiteMaintainer: WidgetWebsiteMaintainer }>()
);

export const initHomeState = createAction(`${ActionType.User}: Init Home state`);

export const initAudioOnInteraction = createAction(`${ActionType.UiUx}: Init Audio on interaction`);
