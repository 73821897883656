import { Injectable, NgZone } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { Store } from '@ngrx/store';
import { CommonState } from '../../../../store/src/common-store/common.state';
import { setIsOnline } from '../../../../store/src/common-store/device-store/actions/device.actions';
import { Logger } from '../../../../essentials/util/src/logger';

const logger = new Logger('NetworkStateService');
@Injectable({
  providedIn: 'root',
})
export class NetworkStateService {
  constructor(
    private ngZone: NgZone,
    private store: Store<CommonState>
  ) {
    Network.getStatus().then((status) => {
      logger.info('initialConnectionStatus', status.connected);
      this.updateIsOnline(status);
    });
    Network.addListener('networkStatusChange', (status) => {
      logger.info('updateConnectionStatus', status.connected);
      this.updateIsOnline(status);
    });
  }

  private updateIsOnline(status: ConnectionStatus) {
    this.ngZone.run(() => {
      this.store.dispatch(setIsOnline({ isOnline: status.connected }));
    });
  }
}
