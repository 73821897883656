import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { combineLatest, Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { UnreadMessagesCountService } from '../../../../../../../../libs/common/resources/src/services/unread-messages-count.service';
import { Logger } from '../../../../../../../../libs/essentials/util/src/logger';
import { MeaState } from '../../../../store/state/mea.state';
import { selectSoundNotification, selectSoundRepetition } from '../../selectors/home.selectors';
import { SoundRepetition } from '../../state/home.state';

const logger = new Logger('AudioService');

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  audio: HTMLMediaElement | undefined;
  timerIsRunning = false;
  private soundNotification = false;
  private soundRepetition: SoundRepetition = -1;

  private stopTimer$ = new Subject<void>();

  constructor(
    private store: Store<MeaState>,
    private unreadMessagesCountService: UnreadMessagesCountService,
    private platform: Platform
  ) {
    const isSafari =
      this.platform.testUserAgent('Safari') &&
      !this.platform.testUserAgent('Chrome') &&
      !this.platform.testUserAgent('Chromium');
    if (!isSafari) {
      logger.info('not in Safari, initializing audio immediately');
      this.initAudio();
    }

    const soundNotification$ = this.store.select(selectSoundNotification);
    const soundRepetition$ = this.store.select(selectSoundRepetition);
    const hasUnreadMessages$ = this.unreadMessagesCountService.unreadMessagesTotalCount$.pipe(
      map((unreadMessagesTotalCount) => unreadMessagesTotalCount > 0)
    );
    combineLatest([hasUnreadMessages$, soundNotification$, soundRepetition$]).subscribe(
      ([hasUnreadMessages, soundNotification, soundRepetition]) => {
        this.soundNotification = soundNotification;
        this.soundRepetition = soundRepetition;
        this.setTimerState(hasUnreadMessages, soundNotification, soundRepetition);
      }
    );
  }

  async playSoundAndResetTimer() {
    await this.playSound();
    this.resetTimer(this.soundRepetition);
  }

  /* Init audio playback if in Safari, since it is only possible on interaction */
  initAudioOnInteraction() {
    if (!this.audio) {
      this.initAudio();
    }
  }

  private setTimerState(hasUnreadMessages: boolean, soundNotification: boolean, soundRepetition: SoundRepetition) {
    if (hasUnreadMessages && soundNotification && soundRepetition !== -1) {
      if (this.timerIsRunning) {
        this.resetTimer(soundRepetition);
      } else {
        this.startTimer(soundRepetition);
      }
    } else {
      this.stopTimer();
    }
  }

  private initAudio() {
    logger.info('Initializing audio');
    this.audio = new Audio();
    this.audio.src = '../../../assets/audio/notification.wav';
    this.audio.load();
  }

  private async playSound() {
    if (this.soundNotification) {
      try {
        if (this.audio) {
          this.audio.paused ? await this.audio.play() : (this.audio.currentTime = 0);
        }
      } catch (e) {
        logger.info('Failed to play audio sound', e);
      }
    }
  }

  private startTimer(soundRepetition: SoundRepetition) {
    if (!this.timerIsRunning && soundRepetition !== -1 && this.soundNotification) {
      this.timerIsRunning = true;
      this.initTimer(soundRepetition * 1000);
    }
  }

  private stopTimer() {
    if (this.timerIsRunning) {
      this.timerIsRunning = false;
      this.stopTimer$.next();
    }
  }

  private resetTimer(soundRepetition: SoundRepetition) {
    if (this.timerIsRunning) {
      this.stopTimer();
    }
    this.startTimer(soundRepetition);
  }

  private initTimer(soundRepetitionInMilliSeconds: number) {
    timer(soundRepetitionInMilliSeconds, soundRepetitionInMilliSeconds)
      .pipe(takeUntil(this.stopTimer$))
      .subscribe(async () => this.playSound());
  }
}
