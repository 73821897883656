import { createSelector } from '@ngrx/store';
import { Dictionary } from 'ts-essentials';
import { Textblock } from '../../../../../essentials/types/src/textblock';
import { CommonState } from '../../../common-store/common.state';
import { TextblockState } from '../state/textblock.state';
import { getFirstTextblockInList, sortAndCategorizeTextblocks } from './util/textblock-selector.util';

const selectTextblockState = (state: CommonState & { textblockData: TextblockState }) => state.textblockData;

export const selectTextblocks = createSelector(selectTextblockState, (state: TextblockState) => state.textblocks);

export const selectTextblockCategories = createSelector(
  selectTextblockState,
  (state: TextblockState) => state.textblockCategories
);

export const selectUsableAndDeactivatedTextblockList = createSelector(
  selectTextblocks,
  (textblocks: Dictionary<Textblock>) => {
    const overriddenDefaults = Object.values(textblocks)
      .filter((textblock) => !!textblock.idOfDefaultTextblock)
      .map((textblock) => textblock.idOfDefaultTextblock);

    return Object.values(textblocks).filter((textblock) => !overriddenDefaults.includes(textblock.id));
  }
);

export const selectUsableTextblockList = createSelector(
  selectUsableAndDeactivatedTextblockList,
  (textblocks: Textblock[]) => textblocks.filter((textblock) => !textblock.isDeactivated)
);

export const selectCategorizedUsableAndDeactivatedTextblocks = createSelector(
  selectTextblockCategories,
  selectUsableAndDeactivatedTextblockList,
  (categories, textblocks) => sortAndCategorizeTextblocks(categories, textblocks)
);

export const selectCategorizedUsableTextblocks = createSelector(
  selectTextblockCategories,
  selectUsableTextblockList,
  (categories, textblocks) => sortAndCategorizeTextblocks(categories, textblocks)
);

export const selectSelectedTextblockOrDefaultId = createSelector(
  selectTextblockState,
  selectCategorizedUsableAndDeactivatedTextblocks,
  selectTextblockCategories,
  ({ selectedTextblockId }: TextblockState, categorizedTextblocks, textblockCategories) => {
    if (selectedTextblockId) {
      return selectedTextblockId;
    }
    const firstTextblockInList = getFirstTextblockInList(textblockCategories, categorizedTextblocks);
    return (firstTextblockInList && firstTextblockInList.id) || '';
  }
);

export const selectTextblock = (textblockId: string | undefined) =>
  createSelector(selectTextblocks, (textblocks: Dictionary<Textblock>) =>
    textblockId ? textblocks[textblockId] : undefined
  );

export const selectSelectedTextblock = createSelector(
  selectTextblocks,
  selectSelectedTextblockOrDefaultId,
  (textblocks: Dictionary<Textblock>, selectedTextblockId) => {
    const customWithDefault = Object.values(textblocks).find(
      (textblock) => textblock.idOfDefaultTextblock === selectedTextblockId
    );

    return customWithDefault || textblocks[selectedTextblockId];
  }
);

export const selectCreateTextblockStatus = createSelector(
  selectTextblockState,
  (state: TextblockState) => state.requestStates.createTextblock
);

export const selectUpdateTextblockStatus = createSelector(
  selectTextblockState,
  (state: TextblockState) => state.requestStates.updateTextblock
);

export const selectDeleteTextblockStatus = createSelector(
  selectTextblockState,
  (state: TextblockState) => state.requestStates.deleteTextblock
);
