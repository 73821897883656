import gql from 'graphql-tag';
import { EncryptionResult } from '../../services/encryption/private-key-backup.service';
import { CreateConversationInput } from '../../services/encryption/reset-key-pair.service';

export interface ResetKeyPairPharmacyInput {
  conversationsToCreate: CreateConversationInput[];
  publicKey: string;
  privateKeyEncryptionResult: EncryptionResult;
}

export default gql`
  mutation resetKeyPairForPharmacy(
    $conversationsToCreate: [ConversationsToCreateInput!]!
    $publicKey: String!
    $privateKeyEncryptionResult: PrivateKeyEncryptionResultInput!
  ) {
    resetKeyPairForPharmacy(
      resetKeyPairInput: {
        conversationsToCreate: $conversationsToCreate
        publicKey: $publicKey
        privateKeyEncryptionResult: $privateKeyEncryptionResult
      }
    )
  }
`;
