import { Injectable } from '@angular/core';
import { PharmacyLoginDetails, PharmacyWithChatUser } from '../../../../../essentials/types/src/pharmacy';
import { AppsyncErrorUtil } from '../../../../../essentials/util/src/appsync-error.util';
import { MeaUserMappingUtil } from '../../../../../essentials/util/src/mea-user-mapping.util';
import getPharmacy from '../../graphql/queries/getPharmacy';
import getPharmacyLoginDetailsByCustomerIdQuery, {
  GetPharmacyLoginDetailsByCustomerIdResult,
} from '../../graphql/queries/getPharmacyLoginDetailsByCustomerId';
import { AppsyncService } from './appsync.service';

@Injectable({ providedIn: 'root' })
export class AppsyncGetPharmacyService {
  constructor(private appsyncService: AppsyncService) {}

  async getPharmacyFromBackend(pharmacyId: string): Promise<PharmacyWithChatUser | null> {
    const client = await this.appsyncService.getClient();
    try {
      const { data } = await client.query({
        query: getPharmacy,
        variables: {
          id: pharmacyId,
        },
      });
      return {
        ...data.getPharmacy,
        pharmacyChatUser: MeaUserMappingUtil.mapBackendMeaUserToMeaUser(data.getPharmacy.pharmacyChatUser),
      };
    } catch (e) {
      if (
        AppsyncErrorUtil.isAppsyncError(e) &&
        e.errors.every((error) => AppsyncErrorUtil.isElasticSearchNotFoundError(error))
      ) {
        return null;
      } else {
        throw e;
      }
    }
  }

  async getPharmacyLoginDetailsByCustomerId(customerId: string): Promise<PharmacyLoginDetails> {
    const client = await this.appsyncService.getClient();
    const { data } = await client.query<GetPharmacyLoginDetailsByCustomerIdResult>({
      query: getPharmacyLoginDetailsByCustomerIdQuery,
      variables: {
        customerId,
      },
    });
    if (data && data.getPharmacyLoginDetailsByCustomerId) {
      return data.getPharmacyLoginDetailsByCustomerId;
    }
    throw new Error(`Could not find pharmacy with customerId: ${customerId}`);
  }
}
