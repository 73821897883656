import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { IconNamePipe } from '../../../../../essentials/util/src/pipes/icon-name.pipe';

type Color = 'primary' | 'dark' | 'light' | 'dark-grey' | 'danger' | 'info' | 'favorite' | 'warning' | 'light-static';

type Size = 'tiny' | 'small' | 'default' | 'large' | 'extra-large';

@Component({
  selector: 'mea-icon-button',
  templateUrl: 'icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  standalone: true,
  imports: [IonicModule, NgClass, IconNamePipe],
})
export class IconButtonComponent {
  @Input({ required: true }) icon!: string;
  @Input() disabled = false;
  @Input() color: Color = 'primary';
  @Input() size: Size = 'default';
  @Input() loading = false;
  @Input() buttonType: 'button' | 'reset' | 'submit' = 'button';
  @Input() fill: 'clear' | 'solid' = 'clear';

  @Output() buttonClick = new EventEmitter<MouseEvent>();

  onClick(event: MouseEvent) {
    event.stopPropagation();
    this.buttonClick.emit(event);
  }
}
