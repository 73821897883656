<div class="editor">
  <form (ngSubmit)="submit()">
    <div class="editor-box">
      @for (part of templateParts; track part) {
        @if (part.type === 'text') {
          <span>{{ part.text }}</span>
        }
        <label>
          @if (part.type === 'input') {
            <input
              [(ngModel)]="part.text"
              [disabled]="!editorMode"
              [name]="part.name || ''"
              [ngStyle]="{ width: (part.placeholder?.length || 0) * 10 + 'px' }"
              [placeholder]="part.placeholder"
            />
          }
        </label>
      }
    </div>
    @if (hasMissingVariables) {
      <mea-error>{{ 'TEXT_BLOCKS.EDITOR.FILL_ALL_VARIABLES_WARNING' | translate }}</mea-error>
    }
    @if (editorMode) {
      <div [class.editor-buttons]="!isNativeApp">
        @if (isNativeApp) {
          <mea-button (buttonClick)="submit()" expand="block">{{ 'TEXT_BLOCKS.EDITOR.INSERT' | translate }}</mea-button>
        } @else {
          <mea-button (buttonClick)="editTextblock()" buttonStyle="secondary">{{
            'TEXT_BLOCKS.EDITOR.EDIT_TEMPLATE' | translate
          }}</mea-button>
          <mea-button (buttonClick)="submit()">{{ 'TEXT_BLOCKS.EDITOR.INSERT' | translate }}</mea-button>
        }
      </div>
    }
  </form>
</div>
