<mea-modal-header title="{{ 'TEXT_BLOCKS.TITLE' | translate }}"></mea-modal-header>
<ion-content>
  <div class="content">
    <app-textblocks-list
      (textblockClick)="selectTextblockId($event)"
      [categorizedTextblocks]="categorizedTextblocks$ | async"
      [selectedTextblockId]="selectedTextblockId$ | async"
      [textblockCategories]="textblockCategories$ | async"
    ></app-textblocks-list>
    @if (selectedTextblock$ | async; as selectedTextblock) {
      <common-chat-textblock-editor
        (edit)="navigateToSettings()"
        (save)="submit($event)"
        [template]="selectedTextblock.template"
      >
      </common-chat-textblock-editor>
    }
  </div>
</ion-content>
