import gql from 'graphql-tag';
import { appointmentAttributes } from '../attributes';

export default gql`
  mutation bookAppointment(
    $pharmacyCognitoId: ID!
    $dateTime: AWSDateTime!
    $name: String!
    $nameIsEncrypted: Boolean
    $encryptedConversationId: String
    $email: AWSEmail
    $selectedAppointmentType: String
    $encryptedNote: String
  ) {
    bookAppointment(
      pharmacyCognitoId: $pharmacyCognitoId
      dateTime: $dateTime
      name: $name
      nameIsEncrypted: $nameIsEncrypted
      encryptedConversationId: $encryptedConversationId
      email: $email
      selectedAppointmentType: $selectedAppointmentType
      encryptedNote: $encryptedNote
    ) {
      ${appointmentAttributes}
    }
  }
`;
