<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'APPOINTMENT.FIND' | translate }}</ion-title>
    <ion-buttons slot="end">
      <mea-icon-button (buttonClick)="modalCtrl.dismiss()" color="dark-grey" icon="close"></mea-icon-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

@if (
  {
    availableAppointmentTypes: availableAppointmentTypes$ | async,
    numberOfAvailableAppointmentsInNextTwoWeeks: numberOfAvailableAppointmentsInNextTwoWeeks$ | async,
    groupedAvailableAppointments: groupedAvailableAppointments$ | async
  };
  as ctx
) {
  <ion-content>
    @if (ctx.availableAppointmentTypes) {
      <ion-accordion-group>
        @for (appointmentType of ctx.availableAppointmentTypes; track appointmentType) {
          <ion-accordion>
            <ion-item slot="header">
              <ion-label class="header"
                >{{ appointmentType | appointmentType }} ({{
                  ctx.numberOfAvailableAppointmentsInNextTwoWeeks?.[appointmentType]
                }}
                {{ ctx.numberOfAvailableAppointmentsInNextTwoWeeks?.[appointmentType] === 1 ? 'Termin' : 'Termine' }}
                in den nächsten 14 Tagen)</ion-label
              >
            </ion-item>
            @if (ctx.groupedAvailableAppointments?.[appointmentType]; as availableAppointmentsForAppointmentType) {
              <ion-list slot="content">
                <ion-item class="outer-content">
                  @if (availableAppointmentsForAppointmentType.length > 0) {
                    <div class="inner-accordion-wrapper">
                      <ion-list-header mode="md">
                        <ion-label>Datum</ion-label>
                        <ion-label class="text-align-right">{{ 'APPOINTMENT.OPEN_SLOTS' | translate }}</ion-label>
                      </ion-list-header>
                      @for (day of availableAppointmentsForAppointmentType; track trackDay(day)) {
                        <ion-accordion-group>
                          <ion-accordion>
                            <ion-item slot="header">
                              <ion-label class="header">{{ day[0]?.dateTime | date: 'EEEE, dd.MM.yyyy' }}</ion-label>
                              <ion-label class="amount" slot="end">{{ day.length }}</ion-label>
                            </ion-item>
                            <ion-list slot="content">
                              @for (appointment of day; track appointment.dateTime) {
                                <ion-item
                                  (click)="selectAppointment(appointment, appointmentType)"
                                  button
                                  class="inset"
                                >
                                  <ion-label>
                                    <h2>
                                      {{ appointment.dateTime | berlinTime: 'HH:mm' }}
                                      -
                                      {{ calculateEndTime(appointment) | berlinTime: 'HH:mm' }}
                                    </h2>
                                  </ion-label>
                                </ion-item>
                              }
                            </ion-list>
                          </ion-accordion>
                        </ion-accordion-group>
                      }
                    </div>
                  }
                </ion-item>
              </ion-list>
            }
          </ion-accordion>
        }
        @if (ctx.availableAppointmentTypes.length === 0) {
          <mea-error>
            Leider sind derzeit keine freien Termine verfügbar.<br />
            {{ isPharmacy ? 'Bitte legen Sie zuerst einen neuen Termin an.' : 'Bitte versuchen Sie es später erneut.' }}
          </mea-error>
        }
      </ion-accordion-group>
    }
  </ion-content>
}
