<ion-header mode="ios">
  <ion-toolbar [color]="color" [class.no-border]="noBorder">
    <ion-buttons slot="start">
      @if (showBackButton) {
        <mea-icon-button (buttonClick)="backButtonClicked.emit()" color="dark" icon="arrow_left"></mea-icon-button>
      }
    </ion-buttons>
    @if (title) {
      <div [ngClass]="{ title: true, small: size === 'small' }">{{ title }}</div>
    }
    <ion-buttons slot="end">
      <mea-icon-button
        (buttonClick)="close()"
        [size]="size === 'small' ? 'default' : 'large'"
        color="dark"
        icon="close"
      ></mea-icon-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
