import gql from 'graphql-tag';
import { appointmentAttributes } from '../../../../../common/resources/src/graphql/attributes';

export default gql`
  mutation createAppointment($dateTime: AWSDateTime!, $durationMinutes: Int!, $availableAppointmentTypes: [String!]) {
    createAppointment(
      appointment: { dateTime: $dateTime, durationMinutes: $durationMinutes, availableAppointmentTypes: $availableAppointmentTypes }
    ) {
      ${appointmentAttributes}
    }
  }
`;
