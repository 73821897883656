<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button [icon]="'arrow_left' | iconName"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'APPOINTMENT.BOOK' | translate }}</ion-title>
    <ion-buttons slot="end">
      <mea-icon-button (buttonClick)="closeModal()" icon="close" color="dark-grey"></mea-icon-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

@if (appointment) {
  <ion-content>
    <ion-card>
      <ion-item>
        <ion-icon [src]="'calendar' | iconName" slot="start"></ion-icon>
        <ion-label>{{ appointment.dateTime | berlinTime: 'dddd, DD.MM.YYYY' }}</ion-label>
      </ion-item>
      <ion-item>
        <ion-icon [src]="'time' | iconName" slot="start"></ion-icon>
        <ion-label
          >{{ appointment.dateTime | berlinTime: 'HH:mm' }} -
          {{ calculateEndTime(appointment) | berlinTime: 'HH:mm' }}</ion-label
        >
      </ion-item>
    </ion-card>
    <mea-input
      [(value)]="inputName"
      [disabled]="hadSuccess()"
      label="Name*"
      [placeholder]="
        isPharmacy ? 'Bitte geben Sie hier den Namen des Kunden/der Kundin ein' : 'Bitte geben Sie hier Ihren Namen ein'
      "
      autocomplete="name"
      name="enduser-name"
    ></mea-input>
    <mea-input
      [(ngModel)]="inputEmail"
      [email]="true"
      [disabled]="hadSuccess()"
      #email="ngModel"
      [label]="isPharmacy ? 'E-Mail-Adresse' : 'E-Mail-Adresse*'"
      [placeholder]="
        isPharmacy
          ? 'Bitte geben Sie hier die E-Mail-Adresse des Kunden/der Kundin ein'
          : 'Bitte geben Sie hier Ihre E-Mail-Adresse ein'
      "
      name="enduser-email"
      type="email"
    ></mea-input>
    <div class="internal-note">
      <div class="label">Interne Notiz</div>
      @if (isPharmacy) {
        <ion-textarea
          [(ngModel)]="inputNote"
          [disabled]="hadSuccess()"
          [counter]="true"
          class="internal-note-textarea"
          fill="outline"
          maxlength="117"
          placeholder="Hier können Sie eine interne Notiz zum Termin eingeben, die nur für Sie sichtbar ist."
        ></ion-textarea>
      }
    </div>
    <div class="tip">*Pflichtfeld</div>
    @if (!isPharmacy) {
      <div>
        @if (pharmacy$ | async; as pharmacy) {
          <div class="infobox">
            Die mit diesem Formular erhobenen Daten werden durch die {{ pharmacy.name }} zum Zwecke der Vorbereitung der
            Durchführung des Termines verarbeitet. Weitere Informationen finden Sie in der
            <mea-link [externalLink]="pharmacy?.privacyPolicyLink" text="Datenschutzerklärung der Apotheke"></mea-link>
            und der
            <mea-link
              [externalLink]="'https://www.meineapotheke.de/service/datenschutz/'"
              text="mea Datenschutzerklärung"
            ></mea-link
            >.
          </div>
        }
      </div>
    }
    @if (!hadSuccess()) {
      <mea-button
        (buttonClick)="bookAppointment()"
        [disabled]="!!email.invalid || (!inputEmail && !isPharmacy) || !inputName"
        [loading]="isLoading()"
      >
        Buchen
      </mea-button>
    } @else {
      <mea-success>
        Der Termin wurde erfolgreich gebucht.
        <br />
        {{ isPharmacy ? 'Der Kunde und Sie' : 'Sie' }} sollten in Kürze eine Bestätigungsmail bekommen.
      </mea-success>
      <mea-button (buttonClick)="closeModal()">OK</mea-button>
    }
    @if (hadError()) {
      <mea-error>
        Bei Ihrer Buchung ist ein Fehler aufgetreten.
        <br />
        Bitte versuchen Sie es erneut.
      </mea-error>
    }
  </ion-content>
}
